import { useDomainConfigContext } from './useDomainConfigContext'
import { useIsMayaSpeakingOrLoadingNewLines } from './useIsMayaSpeakingOrLoadingNewLines'

export const useShouldShowButtons = () => {
  const isMayaSpeaking = useIsMayaSpeakingOrLoadingNewLines()
  const {
    state: { hideButtonsWhenDHSpeaks, alwaysShowButtons }
  } = useDomainConfigContext()

  const shouldShowButtons =
    alwaysShowButtons ||
    (hideButtonsWhenDHSpeaks && !isMayaSpeaking) ||
    !hideButtonsWhenDHSpeaks

  return shouldShowButtons
}
