const styles = {
  lds: {
    display: 'inline-block',
    position: 'relative',
    width: '80px',
    height: '80px',

    '& div': {
      position: 'absolute',
      width: '7.5%',
      height: '7.5%',
      backgroundColor: 'primary',
      borderRadius: '50%',
      animation: 'lds-animation 1.2s linear infinite'
    },

    '& div:nth-child(1)': {
      animationDelay: '0s',
      top: '46.25%',
      left: '82.5%'
    },
    '& div:nth-child(2)': {
      animationDelay: '-0.1s',
      top: '27.5%',
      left: '77.5%'
    },
    '& div:nth-child(3)': {
      animationDelay: '-0.2s',
      top: '13.75%',
      left: '65%'
    },
    '& div:nth-child(4)': {
      animationDelay: '-0.3s',
      top: '8.75%',
      left: '46.25%'
    },
    '& div:nth-child(5)': {
      animationDelay: '-0.4s',
      top: '13.75%',
      left: '27.5%'
    },
    '& div:nth-child(6)': {
      animationDelay: '-0.5s',
      top: '27.5%',
      left: '13.75%'
    },
    '& div:nth-child(7)': {
      animationDelay: '-0.6s',
      top: '46.25%',
      left: '8.75%'
    },
    '& div:nth-child(8)': {
      animationDelay: '-0.7s',
      top: '65%',
      left: '13.75%'
    },
    '& div:nth-child(9)': {
      animationDelay: '-0.8s',
      top: '77.5%',
      left: '27.5%'
    },
    '& div:nth-child(10)': {
      animationDelay: '-0.9s',
      top: '82.5%',
      left: '46.25%'
    },
    '& div:nth-child(11)': {
      animationDelay: '-1s',
      top: '77.5%',
      left: '65%'
    },
    '& div:nth-child(12)': {
      animationDelay: '-1.1s',
      top: '65%',
      left: '77.5%'
    }
  }
}

export default styles
