import React, { useEffect } from 'react'
import { Button, Flex } from 'rebass'
import { useTranslation } from 'react-i18next'
import { trackHandler } from 'uneeq-react-core'
import { VscHome } from 'react-icons/vsc'
import ReactTooltip from 'react-tooltip'

interface MayaMenuProps {
  onClick: () => void
  customStyles?: any
}
const MayaHomeButton: React.FC<MayaMenuProps> = ({ onClick, customStyles }) => {
  const { t } = useTranslation()

  useEffect(() => {
    ReactTooltip.rebuild()
  }, [])

  return (
    <Button
      variant="secondary"
      id="leave-session"
      data-tip={t('Tooltip.exitSessionButton')}
      sx={{ display: 'flex', ...customStyles }}
      onClick={trackHandler(onClick, 'exit-session-btn')}
    >
      <VscHome />
    </Button>
  )
}

export default MayaHomeButton
