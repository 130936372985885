const styles = {
  dateIOS: {
    fontFamily: 'inherit',
    fontSize: ['14px', '14px', '16px', '16px', '16px', '16px'],
    lineHeight: '24px',
    color: 'text',
    border: 'none',
    letterSpacing: '-0.01em',
    width: '100%',
    ':focus': {
      outline: 'none'
    },
    '&::placeholder': {
      opacity: 0.7
    }
  },
  dateInputContainer: {
    fontFamily: 'inherit',
    fontSize: ['14px', '14px', '16px', '16px', '16px', '16px'],
    lineHeight: '24px',
    color: 'text',
    width: '100%',
    ':focus': {
      outline: 'none'
    },
    '&::placeholder': {
      opacity: 0.7
    },
    padding: 0
  },
  dateInput: {
    background: 'transparent',
    fontFamily: 'inherit',
    fontSize: ['14px', '14px', '16px', '16px', '16px', '16px'],
    lineHeight: '24px',
    color: 'inherit',
    padding: 8,
    backgroundColor: '#FCFCFC',
    letterSpacing: '-0.01em',
    width: '100%',
    outline: 'none',
    '&::placeholder': {
      opacity: 0.7
    }
  },
  datePickerForm: {
    width: '88%',
    margin: '0 auto',
    alignItems: 'center',
    justifyContent: 'flex-start',
    '& > *:not(:last-child)': {
      mr: 1
    },
    '& > div:first-of-type': {
      flex: 1.5
    },
    '& *:last-child': {
      flex: 1
    }
  },
  datePickerSelect: {
    borderRadius: '4px',
    borderColor: 'inputBorder',
    '&:focus': {
      border: 'focusOutline'
    }
  }
}

export default styles
