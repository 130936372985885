import React from 'react'
import { Box, Flex, Text } from 'rebass'
import { ReactComponent as ExclamationIcon } from '../../customPlugins/MayaErrors/exclamation.svg'
import { MayaOverlay } from '../../customPlugins/MayaOverlay'

const RotateDevicePrompt = () => {
  return (
    <MayaOverlay>
      <Box
        sx={{
          label: 'smallScreenContainer',
          width: ['95%', '95%', 560, 560, 560, 560],
          alignItems: 'center',
          justifyContent: 'center',
          flexDirection: 'column',
          backgroundColor: 'rgba(255,255,255,0.1)',
          padding: 10,
          borderRadius: 'card',
          backdropFilter: 'blur(100px)',
          color: 'white'
        }}
      >
        <Flex
          sx={{ justifyContent: 'center', alignItems: 'center', fontSize: 3 }}
        >
          <ExclamationIcon />
          <Text sx={{ ml: 4 }}>Rotate your device</Text>
        </Flex>
        <Text sx={{ mt: 5, textAlign: 'center' }}>
          Please rotate your device to use the app.
        </Text>
      </Box>
    </MayaOverlay>
  )
}

const DeviceOrientationChecker = ({ disableLandscapeMode, children }) => {
  // TODO: Set / take `disablePortraitMode`  from domain config
  const disablePortraitMode = !disableLandscapeMode

  const orientation =
    // eslint-disable-next-line no-restricted-globals
    (screen.orientation || {}).type ||
    // eslint-disable-next-line no-restricted-globals
    screen.mozOrientation ||
    // eslint-disable-next-line no-restricted-globals
    screen.msOrientation
  const isLandscape =
    orientation === 'landscape-primary' || orientation === 'landscape-secondary'
  const isPortrait =
    orientation === 'portrait-primary' || orientation === 'portrait-secondary'

  if (disableLandscapeMode && isLandscape) return <RotateDevicePrompt />
  if (disablePortraitMode && isPortrait) return <RotateDevicePrompt />
  return children
}

export default DeviceOrientationChecker
