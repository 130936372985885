import React, { useContext, useState, useMemo, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { stripSsml, UneeqContext, useUneeqState } from 'uneeq-react-core'
import { useSendSocketMessage } from '../../../app/hooks/useSendSocketMessage'
import { Button, Flex, Text, Box } from 'rebass'
import { Select, Input } from '@rebass/forms'
import styles from '../styles'
import DayPickerInput from 'react-day-picker/DayPickerInput'
import { DateUtils, LocaleUtils } from 'react-day-picker'
import { isIOS } from 'react-device-detect'
import 'react-day-picker/lib/style.css'

import dateFnsFormat from 'date-fns/format'
import dateFnsParse from 'date-fns/parse'
import { markdownQuestion } from '../Question'
import { useTvContext } from '../../../app/hooks/useTvContext'
import { useTheme } from 'emotion-theming'

const defaultMonths = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December'
]
const defaultWeekDaysShort = ['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa']
const defaultWeekDays = [
  'Sunday',
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday'
]

const parseDate = (str: string, format: string) => {
  const parsed = dateFnsParse(str, format, new Date())
  if (DateUtils.isDate(parsed)) {
    return parsed
  }
  return undefined
}

const formatDate = (date: Date, format: string) => {
  return dateFnsFormat(date, format)
}

const DatePickerOverlay = ({
  classNames,
  selectedDay,
  children,
  ...props
}: any) => {
  const { tvAppMode } = useTvContext()
  return (
    <Box className={classNames.overlayWrapper} {...props}>
      <Box
        sx={{
          bg: 'datePickerOverlayBg',
          color: 'text'
        }}
        className={classNames.overlay}
        style={!tvAppMode ? { bottom: 30 } : {}}
      >
        {children}
      </Box>
    </Box>
  )
}

const fromMonth = new Date(1910, 0)
const toMonth = new Date()

const DatePickerNavbar = ({ onPreviousClick, onNextClick }: any) => {
  return (
    <Box className="DayPicker-NavBar">
      <Box
        onClick={() => onPreviousClick()}
        as="span"
        sx={{
          left: '1rem',
          // 16px margin + aprox height of select year form
          top: '37px',
          right: 'unset',
          transform: 'translateY(-50%)'
        }}
        className="DayPicker-NavButton DayPicker-NavButton--prev"
      ></Box>
      <Box
        onClick={() => onNextClick()}
        sx={{
          right: '1rem',
          // 16px margin + aprox height of select year form
          top: '37px',
          left: 'unset',
          transform: 'translateY(-50%)'
        }}
        as="span"
        className="DayPicker-NavButton DayPicker-NavButton--next"
      ></Box>
    </Box>
  )
}

interface YearMonthFormProps {
  date: Date
  localeUtils: any
  onChange: Function
}
const YearMonthForm = ({ date, localeUtils, onChange }: YearMonthFormProps) => {
  const months = localeUtils.getMonths('es')

  const years = []
  for (let i = fromMonth.getFullYear(); i <= toMonth.getFullYear(); i += 1) {
    years.push(i)
  }

  const handleChange = (e: any) => {
    const { year, month } = e.target.form
    onChange(new Date(year.value, month.value))
  }

  return (
    <Box className="DayPicker-Caption">
      <form>
        <Flex sx={styles.datePickerForm}>
          <Select
            sx={styles.datePickerSelect}
            name="month"
            onChange={handleChange}
            value={date.getMonth()}
          >
            {months.map((month: string, i: number) => (
              <Text
                as="option"
                sx={{
                  bg: 'backgroundPrimary',
                  text: 'color',
                  fontFamily: 'inherit'
                }}
                key={month}
                value={i}
              >
                {month}
              </Text>
            ))}
          </Select>
          <Select
            sx={styles.datePickerSelect}
            name="year"
            onChange={handleChange}
            value={date.getFullYear()}
          >
            {years.map(year => (
              <Text
                as="option"
                sx={{
                  bg: 'backgroundPrimary',
                  text: 'color',
                  fontFamily: 'inherit'
                }}
                key={year}
                value={year}
              >
                {year}
              </Text>
            ))}
          </Select>
        </Flex>
      </form>
    </Box>
  )
}

const getFormatedDate = (date: Date | string) => {
  return isIOS
    ? getDateFromGenericFormat(date as string)
    : formatDate(date as Date, FORMAT)
}

const getDateFromGenericFormat = (date: string) => {
  // Generic format is like YYYY-MM-DD
  const [year, month, day] = date.split('-')

  return `${month}/${day}/${year}`
}
const FORMAT = 'MM/dd/yyyy'

const DateInput = ({
  date,
  submitOnEnter,
  setIsDateValid,
  t,
  theme,
  setDate
}: any) => {
  return (
    <DayPickerInput
      keepFocus={false}
      value={date}
      formatDate={formatDate}
      onKeyUp={submitOnEnter}
      format={FORMAT}
      onDayChange={date =>
        date ? setIsDateValid(true) : setIsDateValid(false)
      }
      parseDate={parseDate}
      placeholder={`${dateFnsFormat(new Date(), FORMAT)}`}
      style={styles.dateInputContainer}
      inputProps={{
        style: styles.dateInput,
        readOnly: true
      }}
      overlayComponent={DatePickerOverlay}
      dayPickerProps={{
        localeUtils: {
          ...LocaleUtils,
          getMonths: () =>
            t('Dates.months', {
              returnObjects: true,
              defaultValue: defaultMonths
            })
        },
        weekdaysShort: t('Dates.weekDaysShort', {
          returnObjects: true,
          defaultValue: defaultWeekDaysShort
        }),
        weekdaysLong: t('Dates.weekDays', {
          returnObjects: true,
          defaultValue: defaultWeekDays
        }),
        modifiers: {
          selectedDay: new Date(date)
        },
        modifiersStyles: {
          selectedDay: {
            backgroundColor: theme.colors.datePickerDayBg,
            color: theme.colors.datePickerDayColor,
            fontWeight: 'normal',
            borderRadius: '4px'
          }
        },
        onDayClick: setDate,
        fixedWeeks: true,
        month: date as Date,
        toMonth: new Date(),
        navbarElement: props => <DatePickerNavbar {...props} />,
        captionElement: ({ date, localeUtils }) => (
          <YearMonthForm
            date={date}
            localeUtils={localeUtils}
            onChange={setDate}
          />
        )
      }}
    />
  )
}

const DateQuestion = () => {
  const theme = useTheme<any>()
  const { dispatch } = useContext(UneeqContext)
  const { t } = useTranslation()
  const sendMessage = useSendSocketMessage()
  const { mayaQuestion, hideQuestionTitle } = useUneeqState()
  const defaultValue = isIOS
    ? dateFnsFormat(new Date(), 'yyyy-MM-dd')
    : new Date()
  const [date, setDate] = useState<string | Date>(defaultValue)
  const [isDateValid, setIsDateValid] = useState(true)
  const questionText = useMemo(
    () => markdownQuestion(stripSsml(mayaQuestion.question)),
    [mayaQuestion]
  )

  useEffect(() => {
    if (mayaQuestion.value) {
      const isValidDate = !isNaN(Date.parse(mayaQuestion.value))

      if (isValidDate) {
        const formatedValue = isIOS
          ? dateFnsFormat(new Date(mayaQuestion.value), 'yyyy-MM-dd')
          : new Date(mayaQuestion.value)

        setDate(formatedValue)
      }
    }
  }, [mayaQuestion])

  const submitDateInput = (date: Date | null | string) => {
    if (date) {
      const formatedDate = getFormatedDate(date)
      const info = {
        type: 'response',
        questionId: mayaQuestion.id,
        response: formatedDate,
        label: formatedDate ? formatedDate : t('Transcript.skippedQuestion')
      }
      dispatch({ type: 'mayaMessage', payload: info })
      sendMessage(info)
    }
  }

  const submitOnEnter = (e: any) => {
    if (e.key === 'Enter') {
      submitDateInput(date)
    }
  }

  const disabledSubmitButton = !date || !isDateValid

  return (
    <Flex variant="questionContainer">
      <Flex sx={styles.topContainer}>
        {!hideQuestionTitle && <Text sx={styles.question}>{questionText}</Text>}
        <Text sx={styles.instructions}>{mayaQuestion.instruction}</Text>
      </Flex>
      <Flex
        sx={{
          ...styles.inputContainer,
          ...(!isDateValid ? styles.textInputError : {})
        }}
      >
        <DateInput
          date={date}
          submitOnEnter={submitOnEnter}
          setIsDateValid={setIsDateValid}
          t={t}
          theme={theme}
          setDate={setDate}
        />
        {mayaQuestion.optional && (
          <Button
            type="submit"
            onClick={() => submitDateInput(null)}
            sx={{ ...styles.button, mr: 3, fontWeight: 'normal' }}
            variant="option"
          >
            {t('Question.skip')}
          </Button>
        )}
        <Button
          id="submit"
          type="submit"
          disabled={disabledSubmitButton}
          onClick={() => submitDateInput(date)}
          sx={styles.button}
        >
          {t('Question.submit')}
        </Button>
      </Flex>
    </Flex>
  )
}

export default DateQuestion
