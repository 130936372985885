import React, { useContext } from 'react'
import { Flex, Button, Text, Box } from 'rebass'
// @ts-ignore
import { useUneeqState, UneeqContext, stripSsml } from 'uneeq-react-core'
import { useSendSocketMessage } from '../../app/hooks/useSendSocketMessage'
import styles from './styles'
import Error from '../MayaIcons/Error'

const MayaStatement = () => {
  const { dispatch, restartSession } = useContext(UneeqContext)
  const { statement } = useUneeqState()
  const sendMessage = useSendSocketMessage()

  if (!statement) return null

  const restart = () => {
    dispatch({ type: 'mayaMessage', payload: { type: 'restartSession' } })
    restartSession()
    sendMessage({ type: 'begin' })
  }

  return (
    <Flex sx={styles.container}>
      <Flex sx={styles.warning}>
        <Box sx={{ pt: 8, pb: 12 }}>
          <Error />
        </Box>
        <Text variant="title" sx={styles.heading}>
          Error
        </Text>
        <Text sx={styles.message}>{stripSsml(statement.message)}</Text>
        {statement.showRestart && (
          <Flex sx={{ flexDirection: 'column' }}>
            <Flex sx={styles.divider} />
            <Button sx={styles.closeButton} variant="primary" onClick={restart}>
              Restart
            </Button>
          </Flex>
        )}
      </Flex>
    </Flex>
  )
}

export default MayaStatement
