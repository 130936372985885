import React, { useReducer } from 'react'
import { Steps } from '../App'

type DomainConfigState = {
  disclaimerImage: string
  showLogoOnFlow: boolean
  logo: string
  hideInfoActions: boolean
  appIncludedSteps: Steps[]
  isMayaRed: boolean
  showRepeatButton?: boolean
  hideButtonsWhenDHSpeaks?: boolean
  alwaysShowButtons?: boolean
  exitSessionOnLastScreen?: boolean
}

type SetInitialConfig = {
  type: 'SET_INITIAL_CONFIG'
  payload: Partial<DomainConfigState>
}

type SetDisclaimerImageAction = {
  type: 'SET_DISCLAIMER_IMAGE'
  payload: { image: string }
}

type SetIsMayaRedAction = {
  type: 'SET_IS_MAYA_RED'
  payload: boolean
}

type SetShowButtonsAction = {
  type: 'SET_SHOW_BUTTONS'
  payload: boolean
}

export type DomainConfigActions =
  | SetDisclaimerImageAction
  | SetInitialConfig
  | SetIsMayaRedAction
  | SetShowButtonsAction

export const DomainConfigContext = React.createContext<{
  state: DomainConfigState
  dispatch: React.Dispatch<DomainConfigActions>
}>({ state: {}, dispatch: () => {} } as any)

const reducer = (
  state: DomainConfigState,
  action: DomainConfigActions
): DomainConfigState => {
  switch (action.type) {
    case 'SET_INITIAL_CONFIG': {
      return {
        ...state,
        ...action.payload
      }
    }
    case 'SET_DISCLAIMER_IMAGE': {
      return {
        ...state,
        disclaimerImage: action.payload.image
      }
    }
    case 'SET_IS_MAYA_RED': {
      return {
        ...state,
        isMayaRed: true
      }
    }
    case 'SET_SHOW_BUTTONS': {
      return {
        ...state,
        alwaysShowButtons: action.payload
      }
    }
    default:
      return state
  }
}

export const DomainConfigProvider: React.FC = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, {
    disclaimerImage: '',
    showLogoOnFlow: false,
    logo: '',
    hideInfoActions: false,
    // TODO: Base this on the domain
    isMayaRed: false,
    appIncludedSteps: [
      'home',
      'digitalHuman',
      'login',
      'timed-out',
      'accountSetup',
      'editCompleted',
      'accountSetupEdit',
      'setupCompleted',
      'patientChooser'
    ]
  })

  return (
    <DomainConfigContext.Provider value={{ state, dispatch }}>
      {children}
    </DomainConfigContext.Provider>
  )
}
