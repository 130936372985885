import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { Flex, Text } from 'rebass'
import { styles } from './setupCompletedStyles'
import { ReactComponent as SuccessIcon } from './success.svg'

type SetupCompletedProps = {
  onSuccess: () => void
}

export const SetupCompleted = ({ onSuccess }: SetupCompletedProps) => {
  const { t } = useTranslation()

  useEffect(() => {
    const timer = setTimeout(() => {
      onSuccess()
    }, 3000)
    return () => clearTimeout(timer)
  }, [onSuccess])

  return (
    <Flex sx={styles.container}>
      <Flex sx={styles.success}>
        <SuccessIcon style={styles.successIcon} />
        <Text sx={styles.title}>{t('SetupCompleted.title')}</Text>
      </Flex>
    </Flex>
  )
}
