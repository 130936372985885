import React from 'react'
import { Box, Flex, Image, Text } from 'rebass'
import { useTranslation } from 'react-i18next'
import { LanguageOption } from '../LanguageSelector/LanguageOption'
import LanguageSelector from '../LanguageSelector/LanguageSelector'

interface AretaeioComingSoonProps {
  logo: string
  languageSelected: string
  supportedLanguages: LanguageOption[]
}

const AretaeioComingSoon = ({
  logo,
  languageSelected,
  supportedLanguages
}: AretaeioComingSoonProps) => {
  const { t } = useTranslation()
  return (
    <Flex
      sx={{
        label: 'AretaeioComingSoon-container',
        flex: 1,
        fontFamily: 'sans-serif',
        background: 'white',
        color: 'text',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100%',
        padding: '15px'
      }}
    >
      <LanguageSelector
        languageSelected={languageSelected}
        supportedLanguages={supportedLanguages}
      />

      <Flex
        sx={{
          flexDirection: 'column',
          alignItems: 'center',
          textAlign: 'center'
        }}
      >
        <Image sx={{ width: '370px' }} src={logo} alt="Logo"></Image>
        <Text
          sx={{
            fontSize: '64px',
            fontWeight: 600,
            lineHeight: '80px',
            mt: '32px'
          }}
        >
          {t('ComingSoon.title')}
        </Text>
        <Text sx={{ fontSize: '24px', opacity: 0.4, mt: '32px' }}>
          {t('ComingSoon.subtitle')}
        </Text>
      </Flex>
    </Flex>
  )
}

export default AretaeioComingSoon
