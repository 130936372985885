import { add, format } from 'date-fns'
import { dischargeCarePlanType } from '../../app/types/dischargeCarePlan'

const questionReducer = (
  dischargeCarePlan: dischargeCarePlanType,
  config?: any
) => (state: any, action: any) => {
  switch (action.type) {
    case 'mayaMessage':
      switch (action?.payload?.type) {
        case 'question':
          // replace quiz options
          const nextAppointment = dischargeCarePlan.quiz_data.nextAppointment

          const nextAppointmentDate =
            nextAppointment && new Date(nextAppointment?.date_of_appointment)

          const incorrectAppointment1String =
            nextAppointmentDate &&
            `${format(
              add(nextAppointmentDate, { days: 1 }),
              'EEEE, MMMM d'
            )} at 10:00 am`

          const incorrectAppointment2String =
            nextAppointmentDate &&
            `${format(
              add(nextAppointmentDate, { days: 3 }),
              'EEEE, MMMM d'
            )} at 8:00 am`

          const dosageCorrectMedication =
            dischargeCarePlan.quiz_data.randomCorrectMedication?.dosage.value

          // In case there aren't any more 'incorrect' medications, invent one departing from the correct one
          const dosageIncorrectMedication1 =
            dischargeCarePlan.quiz_data.randomIncorrectMedication1?.dosage
              .value || `${parseInt(dosageCorrectMedication) + 10} mg`
          const dosageIncorrectMedication2 =
            dischargeCarePlan.quiz_data.randomIncorrectMedication2?.dosage
              .value || `${parseInt(dosageCorrectMedication) + 20} mg`

          const correctContactNo =
            dischargeCarePlan?.emergency_contact_numbers[0].contact_number
          const incorrectContactNo1 = dischargeCarePlan?.emergency_contact_numbers[0].contact_number
            .slice(0, -4)
            .concat('5533')
          const incorrectContactNo2 = dischargeCarePlan?.emergency_contact_numbers[0].contact_number
            .slice(0, -5)
            .concat('1234')

          const optionsMap = {
            '61441': dosageIncorrectMedication1, // question#24267 (Medicines) Incorrect dosage 1
            '61442': dosageCorrectMedication, // question#24267 (Medicines) Correct %dosage_am%
            '61443': dosageIncorrectMedication2, // question#24267 (Medicines) Incorrect dosage 2
            '60978': incorrectAppointment1String, // question#24056 (Appointments) %Incorrect_Date_1%
            '60979': dischargeCarePlan.quiz_data.nextAppointmentDateString, // question#24056 (Appointments) %Correct_Date%
            '60980': incorrectAppointment2String, // question#24056 (Appointments) %Incorrect_Date_2%
            '60983': correctContactNo, //question#24059 (Appointments Try Again) %appointments: contact_no%
            '60984': incorrectContactNo1, //question#24059 (Appointments Try Again) %incorrect_number_1%
            '60985': incorrectContactNo2 //question#24059 (Appointments Try Again) %incorrect_number_2%
          }

          if (action.payload?.options?.length > 0) {
            action.payload.options = action.payload.options.map((opt: any) => {
              if (optionsMap[opt?.id]) {
                return { ...opt, label: optionsMap[opt?.id] }
              } else {
                return opt
              }
            })
          }

          return {
            ...state,
            mayaQuestion: action.payload,
            mayaInformation: null,
            questionLoading: false,
            autocompleteOptions: [],
            hideQuestionTitle:
              state.speak &&
              !state.sessionPaused &&
              (state.mayaConfig?.hideQuestion || action.payload?.hideQuestion)
          }
        case 'autocomplete':
          return { ...state, autocompleteOptions: action.payload.matches }
        case 'response':
          return {
            ...state,
            talkNextItem: false,
            talkPreviousItem: false,
            questionLoading: true,
            autocompleteOptions: []
          }
        default:
          return state
      }
    case 'clearQuestionLoading':
      return {
        ...state,
        questionLoading: false
      }
    case 'pauseSession':
      return {
        ...state,
        hideQuestionTitle: false
      }
    case 'resumeSession':
      return {
        ...state,
        hideQuestionTitle:
          state.speak &&
          (state.mayaConfig?.hideQuestion || state.mayaQuestion?.hideQuestion)
      }
    case 'linesToSpeakSet':
      console.log('LINESTOSPEAK QUESTION. Payload:', action.payload)
      return {
        ...state,
        linesToSpeak: action.payload.linesToSpeak,
        stringToSpeak: action.payload.stringToSpeak
      }
    default:
      return state
  }
}

export default questionReducer
