import { useUneeqState } from 'uneeq-react-core'

// Maybe better to just have it inside src/customPlugins/DischargeFlow ?
export const useHighlightedCard = (text1, text2) => {
  const { linesToSpeak } = useUneeqState()

  const currentLine = linesToSpeak?.find((line: any) => line?.currentlySpeaking)

  // String needs to be 'normalised' with form NFKC
  // Compatibility Decomposition, followed by Canonical Composition.
  const text1Norm = text1.normalize('NFKC')
  const text2Norm = text2.normalize('NFKC')
  const currentLineNorm = currentLine?.text.normalize('NFKC')

  const isHiglighted =
    typeof currentLine?.eachIndex === 'number'
      ? text1Norm.includes(currentLineNorm) ||
        currentLineNorm.includes(text2Norm.normalize('NFC'))
      : true

  return isHiglighted
}
