export const styles = {
  container: {
    label: 'AccountSetup-accountContainer',
    display: 'grid',
    gridTemplateColumns: 'repeat(12, 1fr)',
    gridTemplateRows: ' min-content auto',
    height: [
      'calc(100% - 76px)', // 100% minus header height
      'calc(100% - 76px)',
      'calc(100% - 76px)',
      'calc(100% - 76px)',
      '100%',
      '100%'
    ],
    gap: '0px 8%',
    overflowY: 'auto'
  },
  menu: {
    label: 'AccountSetup-menu',
    display: ['none', 'none', 'none', 'flex', 'flex'],
    gridArea: ' 2 / 1 / 3 / 4',
    ml: '13',
    flexDirection: 'column',
    '& > *:not(:last-child)': {
      mb: 3
    }
  },
  menuItem: {
    label: 'AccountSetup-menuItem',
    fontWeight: 'bold',
    fontSize: '14px',
    color: 'greyDark',
    alignItems: 'end',
    '& > *:first-child': {
      mr: 3
    }
  },
  menuItemIcons: {
    label: 'AccountSetup-menuItem',
    flexDirection: 'column',
    alignItems: 'center'
  },
  // TODO: create a divider component
  divider: {
    background: '#E6E8F0',
    width: '1px',
    height: '16px',
    mb: '1px'
  },
  account: {
    label: 'AccountSetup-account',
    flex: 1,
    gridArea: '2 / 4 / 3 / 10',
    alignItems: 'center',
    justifyContent: 'space-between',
    flexDirection: 'column',
    height: '100%',
    pb: 4
  },
  card: {
    label: 'AccountSetup-card',
    bg: 'backgroundPrimary',
    border: 'default',
    borderRadius: 'card',
    flexDirection: 'column'
  },
  title: {
    label: 'AccountSetup-title',
    fontSize: '32px',
    gridArea: '1 / 1 / 2 / 13',
    fontWeight: 600,
    textAlign: 'center',
    color: 'text',
    mt: [0, 0, 86, 64, 64],
    mb: 64
  },
  memberDataContainer: {
    label: 'AccountSetup-memberDataContainer',
    flexDirection: 'column',
    alignItems: 'center',
    flex: 1,
    overflow: ['hidden', 'hidden', 'hidden', 'hidden', 'auto', 'auto'],
    justifyContent: 'flex-start'
  },
  formContainer: {
    label: 'AccountSetup-formContainer',
    flexDirection: 'column',
    minWidth: 345,
    mb: 13
  },
  formDescription: {
    label: 'AccountSetup-formDescription',
    fontSize: 5,
    color: 'text',
    display: 'flex',
    justifyContent: 'space-between',
    mb: 6
  },
  inputsContainer: {
    label: 'AccountSetup-inputsContainer',
    flexDirection: 'row',
    padding: 10,
    justifyContent: 'space-between',
    flexWrap: 'wrap'
  },
  errorsContainer: {
    label: 'AccountSetup-errorsContainer',
    flexDirection: 'column',
    padding: 10,
    bg: 'backgroundError',
    borderTop: 'error',
    borderBottomLeftRadius: 8,
    borderBottomRightRadius: 8,
    '& > *:not(:last-child)': {
      mb: 6
    }
  },
  inputContainer: {
    label: 'AccountSetup-inputContainer',
    width: ['100%', '100%', '100%', '100%', '47.5%'],
    flexDirection: 'column',
    mb: 4
  },
  inputLabel: {
    label: 'AccountSetup-inputLabel',
    fontWeight: 500,
    mb: 6,
    '&.disabled': {
      opacity: 0.4
    }
  },
  errorLabel: {
    label: 'AccountSetup-errorLabel',
    fontWeight: 600,
    color: 'error',
    fontSize: 12,
    textTransform: 'uppercase',
    mb: 6,
    '& svg': {
      mr: 3
    }
  },
  error: {
    label: 'AccountSetup-error',
    color: 'error',
    fontSize: 14,
    fontWeight: 600
  },
  addNewFamilyMemberBtn: {
    label: 'AccountSetup-addNewFamilyMemberBtn',
    width: '100%',
    height: 40,
    mb: '16px'
  },
  confirmSetupBtn: {
    label: 'AccountSetup-confirmSetupBtn',
    width: '100%',
    height: '50px',
    mb: '16px'
  },
  textError: {
    label: 'AccountSetup-textError',
    fontSize: 2,
    textAlign: 'center',
    color: 'error',
    mb: 12
  },
  trashIcon: {
    label: 'AccountSetup-trashIcon',
    cursor: 'pointer',
    color: 'primary'
  }
}
