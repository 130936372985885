import { useTheme } from 'emotion-theming'
import React, { useEffect, useState } from 'react'
import { useUneeqState } from 'uneeq-react-core'
import { getCalendarHighlightColors } from '../../../theme'
import { Appointment } from './Appointment'

interface AppointmentsLoaderProps {
  appointments: any[]
}

const appointmentsSeparatedByDate = appointments =>
  appointments.reduce((prevArray, appt, index) => {
    const { date_raw } = appt

    const found = prevArray.find(
      separatedByDate => separatedByDate.date === date_raw
    )

    return found
      ? prevArray.map(prev =>
          prev.date === date_raw
            ? { ...prev, appointments: [...prev.appointments, appt] }
            : prev
        )
      : [...prevArray, { date: date_raw, appointments: [appt] }]
  }, [])

export const AppointmentsLoader = ({
  appointments
}: AppointmentsLoaderProps) => {
  const { linesToSpeak } = useUneeqState()
  const [apptToShow, setApptToShow] = useState<number>(0)

  const currentLine = linesToSpeak?.find((line: any) => line?.currentlySpeaking)

  const theme = useTheme()

  const highlightColors = theme?.colors ? getCalendarHighlightColors(theme) : []

  const colorIndex = appointmentsSeparatedByDate(appointments).findIndex(
    ({ date }, index) => date === appointments[apptToShow].date_raw
  )

  useEffect(() => {
    if (currentLine) {
      setApptToShow(currentLine.eachIndex || 0)
    }
  }, [appointments, currentLine])

  if (appointments.length === 0) return null

  return (
    <Appointment
      appointment={appointments[apptToShow]}
      index={apptToShow}
      apptColour={highlightColors[colorIndex]}
    />
  )
}
