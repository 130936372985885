const getBaseItem = (item: any, subItemName?: string) => {
  let returnItem = {
    _id: item._id,
    dh_status: null,
    repeated_times: 0
  }
  if (subItemName && item[subItemName]) {
    returnItem[subItemName] = { _id: item[subItemName]._id }
  }
  return returnItem
}

export const getInitialDischargeQuestionData = (
  dischargeCarePlan: any,
  responsePayload: any
) => {
  const { id, options, question } = responsePayload
  const {
    completed_lab_tests,
    follow_ups,
    educations,
    upcoming_lab_tests,
    medical_equipments,
    diet_education,
    exercise_education,
    medical_conditions,
    medical_conditions_other,
    medications
  } = dischargeCarePlan
  const initialData = {
    _id: dischargeCarePlan._id,
    medications: medications.map((medication: any) => getBaseItem(medication)),
    completed_lab_tests: completed_lab_tests.map((labTest: any) =>
      getBaseItem(labTest)
    ),
    follow_ups: follow_ups.map((followUp: any) => getBaseItem(followUp)),
    educations: educations.map((education: any) => getBaseItem(education)),
    medical_conditions: medical_conditions.map((medical_condition: any) =>
      getBaseItem(medical_condition, 'medical_condition')
    ),
    medical_conditions_other: medical_conditions_other.map(
      (medical_condition: any) =>
        getBaseItem(medical_condition, 'medical_condition')
    ),
    upcoming_lab_tests: upcoming_lab_tests.map((upcomingLabTest: any) =>
      getBaseItem(upcomingLabTest, '')
    ),
    medical_equipments: medical_equipments.map((medicalEquipment: any) =>
      getBaseItem(medicalEquipment)
    ),
    exercise_education: exercise_education.map((exerciseEducation: any) =>
      getBaseItem(exerciseEducation)
    ),
    diet_education: diet_education.map((dietEducation: any) =>
      getBaseItem(dietEducation)
    ),
    questionsAnswers: [
      {
        questionId: id,
        questionText: question,
        repeatedTimes: 0,
        responseId: options[0].id,
        responseText: options[0].label,
        isQuiz: false
      }
    ]
  }
  return initialData
}

// Medications and appointments quiz
export const quizQuestions = {
  'question#24267': {
    '61442': 'Correct',
    '61441': 'Incorrect',
    '61443': 'Incorrect',
    '61448': 'Incorrect'
  },
  'question#24056': {
    '60979': 'Correct',
    '60978': 'Incorrect',
    '60980': 'Incorrect'
  }
}

export const responsesWithNurseActionRequired = [
  '61452', // Caregiver not available to listen
  '61108', // I don't take one of the medicines
  '61109', // One of my doses is incorrect
  '61110', // I noticed there is a medicine missing
  '61111', // Something else,
  '61449', // Incorrect answer - Medicines
  '60303', // I don't know why I have one of my appointments
  '60304', // An appointment is with the wrong doctor
  '60305', // I won't be able to go to one of my appointments
  '60306', // I need to be sure I can get a ride
  '60307', // Something else
  '60981', // Incorrect answer - Appointments
  '61191', // Yes, I have something I'd like to ask
  '61270', // Yes, please refer me to a nutritionist or dietician
  '61276', // Yes, I'd like to be referred to a physical therapist
  '61282', // Yes, that would be helpful - Would you like to talk with a nurse about your upcoming tests?
  '61348', // Yes - Would you like to talk with the nurse about your pending tests?
  '61628' // Yes - Would you like to tal…ut the medical equipment you'll be using at home?
]

export const questionIdsToSections = {
  'question#24111': {
    label: 'medications',
    '61091': 'Completed',
    '61092': 'Skipped'
  },
  'question#24117': {
    label: 'medications',
    '61104': 'Skipped'
  }, // for new_medications
  'question#24118': {
    label: 'medications',
    '61105': 'Completed'
  }, // for unchanged_medications
  'question#24352': {
    label: 'medical_equipments',
    '61626': 'Completed',
    '61627': 'Skipped'
  },
  'question#24351': {
    label: 'medical_equipments', // When repeats A
    '61625': 'Completed'
  },
  'question#23727': {
    label: 'follow_ups',
    '60289': 'Completed',
    '60290': 'Skipped'
  },
  'question#24053': {
    label: 'follow_ups', // When repeats A
    '60974': 'Complete'
  },
  'question#24188': {
    label: 'diet_education',
    '61267': 'Completed',
    '61268': 'Skipped'
  },
  'question#24189': {
    label: 'diet_education', // When repeats A
    '61269': 'Completed'
  },
  'question#24192': {
    label: 'exercise_education',
    '61273': 'Completed',
    '61274': 'Skipped'
  },
  'question#24193': {
    label: 'exercise_education', // When repeats A
    '61275': 'Completed'
  },
  // In one Q we recognise if the section has been skipped or completed
  // but on the A is where we should check if that section is repeated
  // So if the Q or the A is repeated, for now it will count equally
  'question#24196': {
    label: 'upcoming_lab_tests', // When repeats Q
    '61279': 'Completed',
    '61280': 'Skipped'
  },
  'question#24197': {
    label: 'upcoming_lab_tests', // When repeats A
    '61281': 'Completed'
  },
  'question#24200': {
    label: 'completed_lab_tests',
    '61285': 'Completed',
    '61286': 'Skipped'
  }, // pending lab tests
  'question#24227': {
    label: 'completed_lab_tests',
    '61347': 'Completed'
  },
  'question#24043': {
    label: 'medical_conditions',
    '60953': 'Skipped',
    '60952': 'Completed'
  },
  'question#24044': {
    label: 'medical_conditions', // When repeats A
    '60954': 'Completed'
  },
  'question#24263': { label: 'medical_conditions_other', '61435': 'Completed' },
  'question#?????': { label: 'educations' } // TODO: not in use for now?
}
