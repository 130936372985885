import React from 'react'
import { SxStyleProp, Flex } from 'rebass'
import useWidgetContext from '../../app/hooks/useWidgetContext'

interface MayaOverlayProps {
  transparent?: boolean
  children: React.ReactNode
  sx?: SxStyleProp
}
const MayaOverlay = ({
  children,
  sx,
  transparent = false,
  ...props
}: MayaOverlayProps) => {
  const { widgetMode, fullscreen } = useWidgetContext()
  return (
    <Flex
      {...props}
      sx={{
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        zIndex: 15,
        backgroundColor: !transparent ? 'overlayBackground' : undefined,
        alignItems: 'center',
        justifyContent: 'center',
        overflow: 'hidden',
        ...(widgetMode
          ? {
              position: 'fixed',
              width: ['100%', '100%', '100%', '100%', 568, 568],
              right: 0,
              left: fullscreen ? '50%' : 'auto',
              ml: fullscreen
                ? ['-50%', '-50%', '-50%', '-50%', -284, -284]
                : 'auto',
              zIndex: 999999,
              backdropFilter: 'blur(5px)',
              webkitBackdropFilter: 'blur(5px)'
            }
          : {}),
        ...sx
      }}
      variant="mayaOverlay"
    >
      {children}
    </Flex>
  )
}

export default MayaOverlay
