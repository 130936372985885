import { format } from 'date-fns'
import { StorageKeys } from '../types/StorageKeys'
import { quizGenerator } from './quizGenerator'

export const registerUserDataSession = userData => {
  const now = new Date()

  const apiUserDataSession = {
    value: userData,
    expiry: now.getTime() + 3600000 // Expiry session after 1 hour
  }

  localStorage.setItem(
    StorageKeys.API_USER_DATA,
    JSON.stringify(apiUserDataSession)
  )
}

export const normalizeDischargeCarePlanData = dischargeCarePlanData => {
  const {
    medications,
    follow_ups,
    personal_caregiver_name,
    medical_conditions,
    medical_conditions_other,
    upcoming_lab_tests,
    completed_lab_tests,
    medical_equipments
  } = dischargeCarePlanData

  const newOrChangedMedications = medications.filter(
    med => med.is_change_in_dosage_or_frequency || med.is_new_medicine
  )

  const unchangedOrStoppedMedications = medications.filter(
    med =>
      (!med.is_change_in_dosage_or_frequency && !med.is_new_medicine) ||
      med.is_stopped
  )

  const unchangedMedications = medications.filter(
    med =>
      !med.is_change_in_dosage_or_frequency &&
      !med.is_new_medicine &&
      !med.is_stopped
  )

  // Medications quiz needs to be done over newOrChangedMedications or unchangedMedications since these ones clearly have a dosage assigned
  const quizMedications = newOrChangedMedications.length
    ? newOrChangedMedications
    : unchangedMedications

  const followUps = follow_ups.map(followUp =>
    followUp.date_of_appointment
      ? {
          ...followUp,
          date_raw: followUp.date_of_appointment, //for calendar formatting
          date_of_appointment: format(
            new Date(
              new Date(followUp.date_of_appointment).toISOString().slice(0, -1)
            ),
            'EEEE, MMMM d'
          )
        }
      : followUp
  )

  // if the appointment doesn't contain the date_of_appointment
  // means that is an unscheduled appointment
  const appointments = followUps.filter(
    followUp => followUp.date_of_appointment
  )
  const unscheduledAppointments = followUps.filter(
    followUp => !followUp.date_of_appointment
  )

  const symptomsList = medical_conditions[0]?.medical_condition?.education_content?.find(
    item => item.shortDescription === 'Symptom List'
  )

  const quizData = quizGenerator(quizMedications, appointments)

  // To refactor: give another name to this object to signify that provides more information
  // than about questions that needs to be skipped
  const skippedData = {
    'question#24040': {
      // Caregiver
      response: '60947',
      label: `Okay, I'll ask them to listen`,
      shouldSkip: !personal_caregiver_name
    },
    'question#24263': {
      // Co-morbidities list
      response: '61435',
      label: `Okay`,
      shouldSkip: medical_conditions_other?.length === 0
    },
    'question#24111': {
      // New or changed medications (1st screen)
      response: '61092',
      label: `Let's skip to another section, I understand my medicines`,
      shouldSkip: medications?.length === 0
    },
    'question#24266': {
      // Medications quiz intro
      response: '61440',
      label: `No, skip to the next section`,
      shouldSkip: medications?.length === 0
    },
    'question#24116': {
      // New or changed medications (2nd screen)
      response: '61102',
      label: `Okay, I'm ready`,
      shouldSkip: newOrChangedMedications?.length === 0
    },
    'question#24117': {
      // New or changed medications (3rd screen)
      response: unchangedOrStoppedMedications?.length === 0 ? '61104' : '61103',
      label:
        unchangedOrStoppedMedications?.length === 0
          ? `No, let's move on`
          : `Yes, I'd like to hear about my existing medicines that have not changed`,
      specialButtons: true,
      shouldSkip: newOrChangedMedications?.length === 0
    },
    'question#24118': {
      // Unchanged medications
      response: '61105',
      label: `Okay`,
      specialButtons: true,
      shouldSkip: unchangedOrStoppedMedications?.length === 0
    },
    'question#23727': {
      // Scheduled appointments (1st question)
      response: '60290',
      label: `No, let's skip to another topic`,
      shouldSkip: follow_ups?.length === 0
    },
    'question#24053': {
      // Scheduled appointments
      response: '60974',
      label: `Okay`,
      shouldSkip: appointments?.length === 0
    },
    'question#24054': {
      // Unscheduled appointments
      response: '60975',
      label: `Okay`,
      shouldSkip: unscheduledAppointments?.length === 0
    },
    'question#24055': {
      // Scheduled appointments quiz intro
      response: '60977',
      label: `Okay`,
      shouldSkip: appointments?.length === 0
    },
    'question#23734': {
      // Scheduled appointments issue?
      response: '60302',
      label: `No, everything looks fine`,
      shouldSkip: appointments?.length === 0
    },
    'question#24196': {
      // Upcoming tests
      response: '61280',
      label: `No, let's move on`,
      shouldSkip: upcoming_lab_tests?.length === 0
    },
    'question#24200': {
      // Pending tests
      response: '61286',
      label: `No, I understand my pending tests`,
      shouldSkip: completed_lab_tests?.length === 0
    },
    'question#24352': {
      // Medical equipment
      response: '61627',
      label: `No, let's finish`,
      shouldSkip: medical_equipments?.length === 0
    },
    'question#24150': {
      // Symptoms list
      response: '61186',
      label: `Okay`,
      shouldSkip: !symptomsList
    }
  }

  return {
    ...dischargeCarePlanData,
    follow_ups: followUps,
    symptoms_list: symptomsList,
    medications: medications,
    firstInstanceMedications: newOrChangedMedications,
    secondInstanceMedications: unchangedOrStoppedMedications,
    skippedData: skippedData,
    quiz_data: quizData
  }
}

export const registerDischargeCarePlanSession = (
  dischargeCarePlanData,
  patientId
) => {
  const now = new Date()

  const apiDischargeCarePlanSession = {
    value: dischargeCarePlanData,
    expiry: now.getTime() + 3600000 // Expiry session after 1 hour
  }

  localStorage.setItem(
    StorageKeys.API_DISCHARGE_CARE_PLAN_DATA,
    JSON.stringify({ ...apiDischargeCarePlanSession, patientId })
  )
}
