import { format, getMonth } from 'date-fns'

import { useTheme } from 'emotion-theming'
import React from 'react'
import { Card } from 'rebass'
import { useSessionContext } from '../../app/hooks/useSessionContext'
import { getCalendarHighlightColors } from '../../theme'
import Calendar, {
  CalendarAppointment,
  CalendarDay,
  getCalendarDates
} from './RedUI/Calendar'

const DischargeCalendar = ({ dischargeDate }: { dischargeDate: Date }) => {
  const calendarDates = getCalendarDates(dischargeDate)

  const {
    state: { dischargeCarePlan }
  } = useSessionContext()
  const { follow_ups } = dischargeCarePlan

  const appointments = follow_ups.filter(
    (followUp: any) => followUp.date_of_appointment
  )

  const dateFormat = 'yyyy-MM-dd'

  const theme = useTheme()

  const highlightColors = theme?.colors ? getCalendarHighlightColors(theme) : []

  const appointmentsSeparatedByDate = appointments.reduce(
    (prevArray, appt, index) => {
      const { date_raw } = appt
      const ISODate = format(
        new Date(new Date(date_raw).toISOString().slice(0, -1)),
        dateFormat
      )

      const found = prevArray.find(
        separatedByDate => separatedByDate.date === ISODate
      )

      return found
        ? prevArray.map(prev =>
            prev.date === ISODate
              ? { ...prev, appointments: [...prev.appointments, appt] }
              : prev
          )
        : [...prevArray, { date: ISODate, appointments: [appt] }]
    },
    []
  )

  return Object.values(calendarDates).map((month, monthIndex) => {
    return (
      <Card
        sx={{
          p: [0, 0, 0, 0, 0, 0],
          flexShrink: 0,
          overflow: 'hidden',
          backgroundColor: 'greyLight'
        }}
      >
        <Calendar>
          {month.map(date => (
            <CalendarDay
              date={date}
              monthIndex={monthIndex}
              dischargeDate={dischargeDate}
            >
              {appointmentsSeparatedByDate.map(
                ({ date: dateWithAppts, appointments }, index) =>
                  format(date, dateFormat) === dateWithAppts &&
                  // Highlight the appt just in the corresponding month its being rendered
                  // not in the subsequent month
                  // i.e. 31st of a month will can be represented twice depending the range of days - we just want appt to be shown in its corresponding month
                  getMonth(date) == Object.keys(calendarDates)[monthIndex]
                    ? appointments.map(({ doctor, time_of_appointment }) => (
                        <CalendarAppointment
                          highlight={highlightColors[index]}
                          information={
                            doctor
                              ? `Dr. ${
                                  doctor?.Lname ? doctor?.Lname : doctor?.Fname
                                } at ${time_of_appointment}`
                              : ''
                          }
                        />
                      ))
                    : null
              )}
            </CalendarDay>
          ))}
        </Calendar>
      </Card>
    )
  })
}

export default DischargeCalendar
