import { Input } from '@rebass/forms'
import { E164Number } from 'libphonenumber-js/types'
import React, { useCallback, useEffect, useRef } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { Button, Flex, Image, Text } from 'rebass'
import { userDataFromSnakeCase, verifyCode } from '../../api/authentication'
import { getDischargeCarePlan } from '../../api/getDischargeCarePlan'
import { useSessionContext } from '../../hooks/useSessionContext'
import { AuthContent } from '../UI/AuthLayout'
import {
  registerDischargeCarePlanSession,
  registerUserDataSession
} from '../registerSession'

type CodeStepProps = {
  back: () => void
  onRegisteredUserLogin: () => void
  onUnregisteredUserLogin: () => void
  setUserData: any
  setUserPhone: () => void
  id?: string
  authLogo?: string
  email?: string
  phone?: E164Number
}

export const transformUserData = ({ dependents, patient }: any) => {
  return [
    userDataFromSnakeCase(patient),
    ...dependents.map((dep: any) => userDataFromSnakeCase(dep))
  ]
}

// The code step can be done through the email or by phone
// Depends on the case of the application
export const CodeStep = ({
  phone,
  authLogo,
  email,
  id,
  back,
  onRegisteredUserLogin,
  onUnregisteredUserLogin,
  setUserData,
  setUserPhone
}: CodeStepProps) => {
  const {
    register,
    handleSubmit,
    setError,
    getValues,
    setValue,
    clearErrors,
    trigger,
    formState: { errors, isValid, isSubmitting }
  } = useForm({ mode: 'onChange' })
  const { t } = useTranslation()
  const passcode = Object.values(getValues()).join('')
  const submitButtonRef = useRef<HTMLButtonElement>(null)
  const { dispatch: sessionDispatcher } = useSessionContext()

  const sendCode = useCallback(async () => {
    if (!passcode) {
      setError('error', {
        type: 'manual',
        message: t(
          phone ? 'Authentication.enterCode' : 'Authentication.enterEmailCode'
        )
      })
    }

    try {
      if (email) {
        // Email case
        const result = await getDischargeCarePlan(id, passcode)

        registerDischargeCarePlanSession(result, id)

        sessionDispatcher({
          type: 'SET_DISCHARGE_CARE_PLAN',
          payload: {
            dischargeCarePlan: result
          }
        })
        onRegisteredUserLogin()
      } else {
        // Phone case
        const response = await verifyCode(phone.toString(), passcode)

        setUserPhone(phone.toString())

        if (response.patientExist === false) {
          // if user doesn't exist, redirect to sign up
          console.log('going to UNregistered user')
          onUnregisteredUserLogin()
        } else {
          const userData = transformUserData(response)
          // Store userData into localStorage to check active session
          registerUserDataSession(userData)

          setUserData(userData)
          // if user exists, redirect to choose a patient for symptom checker
          console.log('going to registered user')
          onRegisteredUserLogin()
        }
      }
    } catch (e) {
      console.log('going to UNregistered user CATCH', e)
      // onUnregisteredUserLogin()
      // setUserPhone(phone.toString())
      if (e instanceof Error) {
        setError('error', {
          type: 'manual',
          message: e.message || t('Errors.invalidCode')
        })
      }
    }
  }, [
    email,
    id,
    onRegisteredUserLogin,
    onUnregisteredUserLogin,
    passcode,
    phone,
    sessionDispatcher,
    setError,
    setUserData,
    setUserPhone,
    t
  ])

  useEffect(() => {
    if (isValid && submitButtonRef.current) {
      submitButtonRef.current.click()
    }
  }, [isValid])

  const fillAllFieldsOnPaste = event => {
    if (event.target.type === 'number') {
      clearErrors()

      event.preventDefault()

      // split clipboard text into single characters
      const data = event.clipboardData.getData('Text').split('')

        // find all other text inputs
      ;[].forEach.call(
        document.querySelectorAll('input[type=number]'),
        (_, index) => {
          // And set input value to the relative character
          setValue(`field${index}`, data[index])
          trigger(`field${index}`)
        }
      )
    }
  }

  return (
    <AuthContent back={phone && back}>
      <Flex
        as="form"
        sx={{
          width: '100%',
          flexDirection: 'column',
          gap: 11,
          alignItems: 'center'
        }}
        onSubmit={handleSubmit(sendCode)}
      >
        {authLogo && (
          <Image
            variant="authLogo"
            src={authLogo}
            alt="Authentication page logo"
          />
        )}
        {!phone && (
          <Text
            variant="contentMedium"
            sx={{ color: 'textLight', textAlign: 'center' }}
          >
            {t('Authentication.enterEmailCode')}

            <Text
              variant="contentMedium"
              sx={{
                fontWeight: 'bold',
                textAlign: 'center',
                overflow: 'hidden',
                whiteSpace: 'no-wrap',
                textOverflow: 'ellipsis'
              }}
            >
              {email}
            </Text>
          </Text>
        )}

        <Flex sx={{ justifyContent: 'center' }}>
          <Flex sx={{ gap: 6 }}>
            {[...Array(6)].map((_, index) => (
              <Input
                autoFocus={index === 0}
                key={index}
                type="number"
                onPaste={fillAllFieldsOnPaste}
                min={0}
                max={9}
                sx={{
                  px: '18px',
                  textAlign: 'center'
                }}
                className={errors.error && 'errorInput'}
                onFocus={({ target }) => {
                  target.select()
                  target.value = ''
                }}
                {...register(`field${index}`, {
                  required: true,
                  onChange: ({
                    target
                  }: React.KeyboardEvent<HTMLInputElement>) => {
                    clearErrors()

                    // TODO: handle on delete, moving focus to previous element
                    if (target.nextElementSibling && target.value.length > 1) {
                      target.nextElementSibling.focus()
                    }
                  },
                  min: 0,
                  max: 9
                })}
              />
            ))}
          </Flex>
        </Flex>

        {errors.error && (
          <Text variant="errorText" sx={{ textAlign: 'center' }}>
            {errors.error.message}
          </Text>
        )}
        {phone && (
          <Text
            variant="contentMedium"
            sx={{ color: 'textLight', textAlign: 'center' }}
          >
            {t('Authentication.enterCode')}
          </Text>
        )}
        {/* we have to keep the button hidden in order to be able to trigger it when the last digit is completed */}
        <Button
          sx={{ display: 'none' }} // if doesn't work, test visibility hidden
          ref={submitButtonRef}
          disabled={!isValid || isSubmitting}
          type="submit"
        >
          {t('Authentication.next')}
        </Button>
      </Flex>
    </AuthContent>
  )
}
