import { useEffect } from 'react'
import { LanguageInformation } from '../../customPlugins/LanguageSelector/LanguageInformation'
import { ws } from '../../socket'

export const useChangeLanguage = (
  onNewLanguage: (lang: LanguageInformation) => void,
  setLoading?: (loading: boolean) => void
) => {
  useEffect(() => {
    const handler = (event?: any) => {
      const payload = JSON.parse(event.data)

      if (payload?.type === 'i18n') {
        const { key, personaId, translation } = payload
        onNewLanguage({ key, personaId, translation })
      }
    }

    ws.addEventListener('message', handler)

    return () => ws.removeEventListener('message', handler)
  }, [onNewLanguage])
}
