import React, { useContext } from 'react'
import { Box, Button, Flex, Text, Heading } from 'rebass'
import { ReactComponent as ExclamationIcon } from '../../app/assets/icons/error.svg'
import { UneeqContext } from 'uneeq-react-core'
import { MayaOverlay } from '../MayaOverlay'
import { useTranslation } from 'react-i18next'

interface ErrorProps {
  message?: string
}
const Error: React.FC<ErrorProps> = ({ message }) => {
  const { dispatch } = useContext(UneeqContext)
  const { t } = useTranslation()
  const clearError = () => dispatch({ type: 'clearError' })
  return (
    <MayaOverlay>
      <Flex variant="modal" sx={{ label: 'ErrorModal' }}>
        <Box variant="modalIconWrapper">
          <ExclamationIcon />
        </Box>
        <Box>
          <Heading variant="modalHeader">Error</Heading>
          <Text variant="modalText">{message || t('Error.serverError')}</Text>
        </Box>

        <Flex variant="modalButtons">
          <Button variant="primary" onClick={clearError}>
            {t('Error.close')}
          </Button>
        </Flex>
      </Flex>
    </MayaOverlay>
  )
}

export default Error
