import { keyframes } from '@emotion/core'
import * as _ from 'lodash'
import { aretaeioColors, aretaeioTheme } from './domains/aretaeio'
import {
  desertSpringsColors,
  desertSpringsTheme
} from './domains/desertSprings'
import {
  segurosBolivarColors,
  segurosBolivarTheme
} from './domains/segurosBolivar'

export enum THEMES {
  DEFAULT = 'DEFAULT',
  DARK = 'DARK',
  SEGUROS_BOLIVAR = 'SEGUROS_BOLIVAR',
  ARETAEIO = 'ARETAEIO',
  DESERT_SPRINGS = 'DESERT_SPRINGS'
}

const colors = {
  text: '#000000',
  textLight: '#393939',
  textAlternate: '#FFFFFF',
  textTranscript: '#000000',
  textTitle: '#000000',

  primary: '#275DAF',
  primaryHover: '#4287f5',
  primaryText: '#FFFFFF',

  secondary: '#22b5a1',
  secondaryHover: '#4bd6c4',
  secondaryText: '#FFFFFF',

  greyDark: '#50575D',
  grey: '#BDC0C7',
  greyLight: '#F6F6F6',

  informationDark: 'rgba(51,51,51,0.95)',
  informationLight: 'rgba(255, 255, 255, 0.2)',

  background: 'rgba(255, 255, 255, 0.95)',
  backgroundPrimary: 'rgba(255, 255, 255, 0.95)',
  backgroundPrimarySolid: 'rgba(255, 255, 255)',
  backgroundSecondary: 'rgba(51, 51, 51, 0.1)',
  backgroundSecondaryHover: 'rgba(51, 51, 51, 0.3)',
  backgroundOverlay: 'rgb(51, 51, 51, 0.5)',

  inputBackground: 'rgba(51, 51, 51, 0.1)',
  inputBackgroundHover: 'rgba(51, 51, 51, 0.1)',
  inputBorder: 'rgba(51, 51, 51, 0.1)',

  datePickerDayBg: 'rgba(51, 51, 51, 0.1)',
  datePickerDayColor: '#FFF',
  datePickerOverlayBg: 'rgba(255, 255, 255, 0.95)',

  optionSelectedBorder: 'rgba(0,0,0,0.4)',
  optionSelectedBorderHover: 'rgba(0,0,0,1)',
  optionSelectedBg: 'transparent',
  optionBorder: 'rgba(51, 51, 51, 0.1)',

  checkboxChecked: '#275DAF',

  link: 'currentColor',

  error: '#BF2828',
  warning: '#FBBD1D',

  // TODO create our own progress bar with rebass so we can use variant="timoutBar"
  timeoutColor: '#4287f5',
  timeoutTrailColor: '#BDC0C7',

  overlayBackground: 'rgba(0,0,0,0.7)',

  svgIconColor1: '#4287f5',
  svgIconColor2: '#ffffff',

  calendarHighlight1: '#FBDAFF',
  calendarHighlight2: '#E1FFDC',
  calendarHighlight3: '#DEFFF9',
  calendarHighlight4: '#BAC9FF',
  calendarHighlight5: '#ABDAFF',
  calendarHighlight6: '#A1FFDC',
  calendarHighlight7: '#FFF8B6',
  calendarHighlight8: '#CAC9FF',
  calendarHighlight9: '#e6d5ea',
  calendarHighlight10: '#afe7c6',
  calendarHighlight11: '#d4edd3',
  calendarHighlight12: '#e6cebf',
  calendarHighlight13: '#f9d0dc',
  calendarHighlight14: '#d5efab',
  calendarHighlight15: '#f4e8cc',
  calendarHighlight16: '#d899fb',
  calendarHighlight17: '#C3FBE7',
  calendarHighlight18: '#bbc2fe',
  calendarHighlight19: '#fac4c8',
  calendarHighlight20: '#f39de6',
  calendarHighlight21: '#B1DAFF',
  calendarHighlight22: '#fdffcb',
  calendarHighlight23: '#c1ee7f',
  calendarHighlight24: '#fbdba7',
  calendarHighlight25: '#fbff96',
  calendarHighlight26: '#e8a3c9',
  calendarHighlight27: '#ccfad0',
  calendarHighlight28: '#d1e2ff',
  calendarHighlight29: '#d9e5ae',
  calendarHighlight30: '#f8e3ee'
}

const darkColors = {
  text: '#FFFFFF',
  textLight: '#393939',
  textAlternate: '#FFFFFF',
  textTranscript: '#FFFFFF',
  textTitle: '#FFFFFF',

  link: 'currentColor',

  primary: '#275DAF',
  primaryHover: '#4287f5',
  primaryText: '#FFFFFF',

  secondary: '#22b5a1',
  secondaryHover: '#4bd6c4',
  secondaryText: '#FFFFFF',

  greyDark: '#50575D',
  grey: '#BDC0C7',
  greyLight: '#F6F6F6',

  informationDark: 'rgba(51,51,51,0.95)',
  informationLight: 'rgba(255, 255, 255, 0.2)',

  background: 'rgba(51,51,51,0.95)',
  backgroundPrimary: 'rgba(51,51,51,0.95)',
  backgroundPrimarySolid: 'rgba(51,51,51)',
  backgroundSecondary: 'rgba(255, 255, 255, 0.1)',
  backgroundOverlay: 'rgb(255, 255, 255, 0.3)',

  backgroundSecondaryHover: 'rgba(255, 255, 255, 0.3)',

  inputBackground: 'rgba(255, 255, 255, 0.1)',
  inputBackgroundHover: 'rgba(255, 255, 255, 0.1)',
  inputBorder: 'rgba(255, 255, 255, 0.1)',

  optionSelectedBorder: 'rgba(255,255,255,0.4)',
  optionSelectedBorderHover: 'rgba(255,255,255,1)',
  optionSelectedBg: 'transparent',
  optionBorder: 'rgba(255, 255, 255, 0.1)',

  datePickerDayBg: 'rgba(255, 255, 255, 0.1)',
  datePickerDayColor: '#FFF',
  datePickerOverlayBg: 'rgba(51,51,51,0.95)',

  checkboxChecked: '#275DAF',

  error: '#BF2828',
  warning: '#FBBD1D',

  // TODO create our own progress bar with rebass so we can use variant="timoutBar"
  timeoutColor: '#4287f5',
  timeoutTrailColor: '#BDC0C7',

  overlayBackground: 'rgba(0,0,0,0.7)',
  activeContentBg: 'rgba(52,93,150,0.2)',
  activeContentBorder: 'rgba(52, 93, 150, 0.4)',

  svgIconColor1: '#4287f5',
  svgIconColor2: '#ffffff'
}

export const getCalendarHighlightColors = (theme: any) => [
  theme.colors.calendarHighlight1,
  theme.colors.calendarHighlight2,
  theme.colors.calendarHighlight3,
  theme.colors.calendarHighlight4,
  theme.colors.calendarHighlight5,
  theme.colors.calendarHighlight6,
  theme.colors.calendarHighlight7,
  theme.colors.calendarHighlight8,
  theme.colors.calendarHighlight9,
  theme.colors.calendarHighlight10,
  theme.colors.calendarHighlight11,
  theme.colors.calendarHighlight12,
  theme.colors.calendarHighlight13,
  theme.colors.calendarHighlight14,
  theme.colors.calendarHighlight15,
  theme.colors.calendarHighlight16,
  theme.colors.calendarHighlight17,
  theme.colors.calendarHighlight18,
  theme.colors.calendarHighlight19,
  theme.colors.calendarHighlight20,
  theme.colors.calendarHighlight21,
  theme.colors.calendarHighlight22,
  theme.colors.calendarHighlight23,
  theme.colors.calendarHighlight24,
  theme.colors.calendarHighlight25,
  theme.colors.calendarHighlight26,
  theme.colors.calendarHighlight27,
  theme.colors.calendarHighlight28,
  theme.colors.calendarHighlight29,
  theme.colors.calendarHighlight30
]

const hex2rgba = (hex: string, alpha = 1) => {
  const [r, g, b] = hex.match(/\w\w/g)!.map(x => parseInt(x, 16))
  return `rgba(${r},${g},${b},${alpha})`
}

const themesColors: Record<THEMES, Record<string, string>> = {
  [THEMES.DARK]: darkColors,
  [THEMES.DEFAULT]: colors,
  [THEMES.SEGUROS_BOLIVAR]: segurosBolivarColors,
  [THEMES.ARETAEIO]: aretaeioColors,
  [THEMES.DESERT_SPRINGS]: desertSpringsColors
}

const baseTheme = (theme: THEMES, embeddedMode: boolean) => ({
  colors: {
    // ...(theme === THEMES.DARK ? darkColors : colors),
    ...themesColors[theme],

    // PTT animation
    pttMobileGlow: hex2rgba(colors.primaryHover, 0.2), // Mobile - glow around button while recording
    // PTT desktop colors & gradients
    pttColRecording: 'white',
    pttImgRecording: `radial-gradient(circle, ${colors.primaryHover} 0%, white 100%)`,
    pttColSending: 'primaryHover',
    pttImgSending: `radial-gradient(circle, white 0%,${colors.primaryHover} 100%)`,
    pttColWaiting: 'primaryHover',
    pttImgWaiting: 'primary'
  },
  fonts: {
    body: 'Inter, system-ui, sans-serif',
    heading: 'inherit'
  },
  fontSizes: [
    '14px',
    '15px',
    '16px',
    '20px',
    '22px',
    '24px',
    '30px',
    '32px',
    '40px'
  ],
  fontWeights: {
    body: 400,
    heading: 400,
    bold: 700
  },
  lineHeights: {
    body: 1.5,
    heading: '31px'
  },
  space: [
    0,
    4,
    8,
    10,
    12,
    14,
    16,
    18,
    20,
    22,
    24,
    28,
    32,
    40,
    48,
    56,
    64,
    80,
    100,
    128
  ],
  borders: {
    focusOutline: 'none'
  },
  radii: {
    button: 2,
    buttonBig: 4,
    input: 1,
    card: 8,
    cardSmall: 4
  },
  shadows: {
    card: '0 4px 4px rgba(0, 0, 0, 0.25)'
  },
  text: {
    heading: {
      label: 'BTheading',
      fontSize: [5, 5, 5, 5, 7, 8],
      fontFamily: 'heading',
      lineHeight: 'heading',
      fontWeight: 'heading',
      pb: 2,
      mt: 3,
      mx: 4
    },
    headingMedium: {
      variant: 'text.heading',
      fontSize: [5, 5, 5, 5, 7, 7]
    },
    headingLarge: {
      variant: 'text.heading',
      fontSize: [5, 5, 5, 5, 8, 7]
    },
    title: {
      label: 'BTtitle',
      fontFamily: 'body',
      color: 'textTitle'
    },
    modalHeader: {
      label: 'BTmodalHeader',
      fontSize: 5,
      fontWeight: 'bold',
      mb: 2,
      textAlign: 'center'
    },
    modalText: {
      label: 'BTmodalHeader',
      fontSize: 1,
      color: 'text',
      textAlign: 'center'
    },
    pageTitle: {
      label: 'BTpagetitle',
      color: 'white',
      fontWeight: 'bold',
      fontSize: ['18px', '18px', '18px', '18px', 5, 7],
      textShadow: 'pageTitle',
      textAlign: 'center',
      letterSpacing: '-0.03em',
      m: 0,
      p: 0
    },
    mayaCaption: {
      label: 'BTmayaCaption',
      variant: 'text.heading',
      alignSelf: 'flex-start'
    },

    label: {
      label: 'BTlabel',
      opacity: 0.7,
      color: 'text',
      fontWeight: 'bold',
      fontSize: [0, 0, 0, 0, 2, '18px'],
      lineHeight: [
        'labelSmall',
        'labelSmall',
        'labelSmall',
        'labelSmall',
        'labelMedium',
        'labelLarge'
      ],
      textTransform: 'uppercase',
      letterSpacing: '0.02em',
      mb: 2
    },
    content: {
      label: 'BTcontent',
      color: 'text',
      fontWeight: 500,
      fontSize: ['18px', '18px', '18px', '18px', 5, 7]
    },
    contentMedium: {
      variant: 'text.content',
      fontSize: ['18px', '18px', '18px', '18px', 5, 5]
    },
    contentParagraph: {
      variant: 'text.contentMedium'
    },
    contentSmall: {
      label: 'BTcontentSmall',
      color: 'text',
      fontWeight: 500,
      fontSize: '18px',
      lineHeight: '24px'
    },
    errorText: {
      label: 'BTerrorText',
      variant: 'text.contentSmall',
      color: 'error'
    }
  },

  forms: {
    input: {
      label: 'BTinput',
      bg: 'inputBackground',
      boxSizing: 'border-box',
      fontSize: ['16px', '16px', '16px', '16px', '18px', '18px'],
      px: '6px',
      py: 4,
      borderRadius: 'input',
      borderColor: 'inputBorder',
      borderWidth: '1px',
      borderStyle: 'solid',
      display: 'flex',
      cursor: 'text',
      '&.errorInput': {
        borderColor: 'error'
      },
      '&.form-input': {
        '&:focus, :focus-visible': {
          outline: 'unset',
          borderColor: '#000000'
        }
      },
      '& > div': {
        width: '100%'
      },
      '&:focus, :focus-visible': {
        outline: 'unset'
      },
      '& ::-webkit-outer-spin-button, ::-webkit-inner-spin-button': {
        appearance: 'none',
        margin: 0
      },
      '&:disabled': {
        opacity: 0.4,
        cursor: 'not-allowed'
      }
    },
    select: {
      borderColor: 'inputBorder',
      '&.errorInput': {
        borderColor: 'error'
      },
      '&:focus, :focus-visible': {
        outline: 'unset',
        borderColor: '#000000'
      }
    }
  },
  appStyles: {
    appBackground: (bgImage: string, bgMobileImage?: string) => ({
      backgroundImage: [
        `url(${bgMobileImage || bgImage})`,
        `url(${bgMobileImage || bgImage})`,
        `url(${bgMobileImage || bgImage})`,
        `url(${bgMobileImage || bgImage})`,
        `url(${bgImage})`
      ],
      backgroundPosition: [
        'center',
        'center',
        'center',
        'center',
        'center',
        'top'
      ]
    }),
    chatBackground: (bgChatImage: string) => ({
      backgroundImage: `url(${bgChatImage})`,
      backgroundSize: ['cover', 'cover', 'cover', 'contain', 'cover']
    })
  },
  variants: {
    link: {
      color: 'primary'
    },
    activeSpokenContent: {
      label: 'BTactiveSpokenContent',
      borderRadius: 'card',
      backgroundColor: 'activeContentBg',
      height: 'min-content',
      m: -1,
      p: 1,
      position: 'relative',
      // SAFARI Issue with outline https://medium.com/@jeandesravines/use-border-radius-and-outline-simultaneously-on-safari-14ce92889e1f
      '&::before': {
        content: "''",
        position: 'absolute',
        top: '-4px',
        right: '-4px',
        bottom: '-4px',
        left: '-4px',
        borderStyle: 'solid',
        borderColor: 'activeContentBorder',
        borderWidth: 4,
        borderRadius: 12 // should be calc(card + 4px)
      }
    },
    activeSpokenContentSmall: {
      variant: 'variants.activeSpokenContent',
      borderRadius: 'cardSmall',
      m: -2,
      p: 2,
      '&::before': {
        content: "''",
        position: 'absolute',
        top: '-2px',
        right: '-2px',
        bottom: '-2px',
        left: '-2px',
        borderStyle: 'solid',
        borderColor: 'activeContentBorder',
        borderWidth: 2,
        borderRadius: 8 // should be calc(cardSmall + 2px)
      }
    },
    mainAppContainer: {
      label: 'BTwrapper',
      width: '100%',
      backgroundPosition: 'right bottom',
      backgroundColor: '#004C9C',
      overflow: 'hidden',
      position: 'absolute',
      top: 0,
      left: 0,
      backgroundSize: 'cover'
    },
    contentList: {
      '& ul': {
        pl: '26px'
      },
      '& li': {
        label: 'BTlistContent',
        variant: 'text.contentMedium',
        pl: '4px',
        mb: '1em',
        '&::marker': {
          variant: 'text.content'
        }
      }
    },
    interactionContainer: {
      label: 'BTinteractionContainer',
      height: ['unset', 'unset', 'unset', 'unset', '100%', '100%'],
      flexDirection: 'row',
      justifyContent: 'flex-start',
      position: ['static', 'static', 'static', 'static', 'fixed', 'fixed'],
      bottom: ['auto', 'auto', 'auto', 'auto', 0, 0],
      top: ['auto', 'auto', 'auto', 'auto', 0, 0],
      p: [0, 0, 0, 0, 5, 5],
      transition: 'all .4s ease-in-out',
      zIndex: 5,
      maxWidth: '100%'
    },
    informationLayout: {
      label: 'BTinformationLayout',
      p: [0, 0, 0, 0, 5, 5],
      width: '100%'
    },
    questionsArea: {
      label: 'BTquestionsArea',
      height: ['auto', 'auto', 'auto', 'auto', '100%', '100%'],
      maxWidth: ['100%', '100%', '100%', '100%', '600px', '600px'],
      position: ['fixed', 'fixed', 'fixed', 'fixed', 'static', 'static'],
      bottom: [0, 0, 0, 0, 'auto', 'auto'],
      alignItems: 'flex-end',
      flex: 1
    },
    questionsWrapper: {
      label: 'BTquestionsWrapper',
      flexDirection: 'column',
      flex: 1,
      gap: 6,
      p: 12,
      pb: 0
    },
    questionContainer: {
      label: 'BTquestionContainer',
      flex: 1,
      justifyContent: 'center',
      flexDirection: 'column',
      position: 'relative',
      boxShadow: '0px 1px 3px rgba(0, 0, 0, 0.15)'
    },
    questionsNavWrapper: {
      label: 'BTquestionsNavWrapper',
      overflowX: ['auto', 'auto', 'auto', 'auto', 'visible', 'visible'],
      gap: 6,
      alignItems: 'flex-start'
    },
    questionsList: {
      label: 'BTquestionsList',
      gap: 2,
      flexDirection: 'row',
      flexWrap: 'wrap',
      overflowY: ['visible', 'visible', 'visible', 'visible', 'auto', 'auto'],
      maxHeight: ['unset', 'unset', 'unset', 'unset', '33vh', '33vh'],
      alignItems: 'flex-start',
      minHeight: '86px', // Hack to hold list in place when empty 54px button + 32px padding
      pb: 12,
      pr: 6, // Makes padding room for the scroll bar
      mr: -6
    },
    simpleGrid: {
      label: 'BTsimpleGrid',
      display: 'grid',
      gridGap: 10,
      gridTemplateColumns: 'repeat(2,minmax(0,1fr))',
      '& > div': {
        flex: 1
      }
    },
    questionsLoader: {
      label: 'BTquestionsLoader',
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      alignItems: 'center',
      justifyContent: 'center',
      bg: 'backgroundOverlay',
      zIndex: 5,
      borderRadius: ['unset', 'unset', 'unset', 'unset', 'card', 'card'],
      backdropFilter: 'blur(1px)'
    },
    redContentLayout: {
      label: 'BTredContentLayout',
      position: 'relative',
      flexDirection: 'column',
      height: '100%',
      width: '100%',
      minWidth: ['100%', '100%', '100%', '688px', '688px', '832px'],
      maxWidth: ['100%', '100%', '100%', '688px', '688px', '832px'],
      overflow: 'hidden'
    },
    redContent: {
      label: 'BTredContent',
      variant: 'card',
      position: 'relative',
      borderRadius: '8px 8px 0px 0px',
      flex: 1,
      p: [6, 6, 6, 6, 12, 12],
      flexDirection: 'column',
      backgroundColor: 'white',
      ml: [0, 0, 0, 0, 6, 6]
    },
    redContentSecondary: {
      label: 'BTredContentSecondary',
      position: 'relative',
      variant: 'redContent',
      p: [0, 0, 0, 0, 0, 0],
      backgroundColor: 'greyLight',
      ml: [0, 0, 0, 0, 6, 6]
    },
    redContentUnwrapped: {
      label: 'redContentUnwrapped',
      flexDirection: 'column',
      position: 'relative',
      gap: 2,
      px: 6,
      flex: 1,
      paddingBottom: 6,
      overflow: 'auto',
      '::-webkit-scrollbar': {
        bg: 'transparent',
        borderRadius: '100px',
        width: '8px'
      },
      '::-webkit-scrollbar-thumb': {
        bg: 'backgroundOverlay',
        borderRadius: '100px'
      }
    },
    pillNew: {
      label: 'BTpillNew',
      backgroundColor: 'primary',
      borderRadius: '100px',
      textTransform: 'uppercase',
      color: 'white',
      width: 'fit-content',
      padding: '2px 8px',
      fontSize: '16px',
      fontWeight: 'bold'
    },
    pillChanged: {
      label: 'BTpillChanged',
      variant: 'variants.pillNew',
      backgroundColor: '#1495de'
    },
    pillStopped: {
      label: 'BTpillStopped',
      variant: 'variants.pillNew',
      backgroundColor: 'grey'
    },
    medicationsTable: {
      label: 'BTmedicationsTable',
      overflowX: 'auto',
      gap: 2,
      p: 2,
      flexDirection: 'column',
      width: '100%'
    },
    medicationsTableHead: {
      flexDirection: 'row',
      px: 6,
      py: 2,
      gap: 6
    },
    medicationsTableRow: {
      label: 'BTmedicationsTableRow',
      variant: 'variants.card',
      p: [6, 6, 6, 6, 6, 6],
      gap: [6, 6, 6, 6, 1, 1],
      flexDirection: 'row',
      boxShadow: 'none'
    },
    medicationsTableCell: {
      label: 'BTmedicationsTableCell',
      alignItems: 'center',
      flex: 1,
      '& > div': {
        label: 'BTmedicationsTableCellInner',
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
        justifyContent: 'center'
      },
      ' > div > div': {
        label: 'BTmedicationsTableCellContent',
        justifyContent: 'center',
        textAlign: 'center',
        alignItems: 'center',
        wordBreak: 'break-word',
        m: 0
      },
      '&:first-child > div > div': {
        textAlign: 'left'
      }
    },
    medicationTime: {
      label: 'BTmedicationTime',
      alignItems: 'center',
      justifyContent: 'center',
      gap: 1,
      '& > svg': {
        width: '24px',
        height: '24px'
      }
    },
    homeContainer: {
      label: 'BThomeContainer',
      flexDirection: 'column',
      alignSelf: 'flex-end',
      m: [0, 0, 0, 0, 6, 6],
      ml: [0, 0, 0, 0, 16, 16],
      backgroundColor: 'backgroundPrimary',
      borderRadius: ['unset', 'unset', 'unset', 'unset', '8px', '8px'],
      zIndex: 5,
      position: ['fixed', 'fixed', 'fixed', 'fixed', 'static', 'static'],
      bottom: [0, 0, 0, 0, 'unset', 'unset'],
      color: 'text',
      width: ['100%', '100%', '100%', '100%', 560, 560],
      p: [6, 6, 10, 10, 10, 10],
      backdropFilter: 'blur(50px)'
    },
    card: {
      label: 'BTcard',
      bg: 'white',
      color: 'textLight',
      borderRadius: 'card',
      p: [6, 6, 12, 12, 12, 12],
      boxShadow: 'card'
    },
    modal: {
      variant: 'variants.card',
      label: 'BTmodal',
      width: ['100%', '100%', '100%', '100%', 560, 560],
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      gap: 6,
      p: [6, 6, 10, 10, 10, 10],
      m: [6, 6, 6, 6, 0, 0],
      textAlign: 'center'
    },
    modalIconWrapper: {
      label: 'BTmodalIconWrapper',
      p: 6
    },

    modalButtons: {
      flexDirection: 'row',
      alignItems: 'center',
      label: 'BTmodalButtons',
      width: '100%',
      justifyContent: 'center',
      gap: 5,
      maxWidth: ['100%', '100%', '100%', '100%', '512px', '512px'],
      mt: 6,
      '& > button': {
        flex: 1
      }
    },
    transcript: {
      label: 'BTtranscript',
      borderRadius: '3px',
      overflow: 'hidden',
      maxHeight: '99%',
      color: 'text',
      '& .transcript-header': {},
      '& .transcript-content': {
        fontSize: ['14px', '14px', '18px', '18px', '18px', '18px'],
        px: 5,
        pb: 5,
        height: ['100%', '100%', '100%', '100%', 'auto', 'auto']
      },
      '& .transcript-footer': {
        height: 45,
        position: ['fixed', 'fixed', 'fixed', 'fixed', 'static'],
        minHeight: '45px',
        bottom: [0, 0, 0, 0, 'auto', 'auto'],
        backgroundColor: 'white',
        width: '100%',
        p: 2
      }
    },
    inputSelect: {
      outline: 'none',
      border: 'none',
      width: 'max-content',
      '& option': {
        color: 'text',
        bg: 'background'
      },
      color: 'text',
      fontSize: 0,
      lineHeight: 'body',
      appearance: 'none',
      '-webkit-appearance': 'none',
      '-moz-appearance': 'none',
      bg: 'background',
      fontFamily: 'inherit',
      ':focus': {
        outline: 'none'
      },
      pr: 10,
      py: 1,
      pl: 2
    },
    fakeInputWrapper: {
      label: 'BSfakeInputWrapper',
      variant: 'forms.input',
      '&:focus-within': {
        border: 'focused'
      }
    },
    checkConfirm: {
      label: 'BTcheckConfirm',
      gap: 2
    },
    homeLogo: {
      label: 'BThomeLogo',
      backgroundColor: [
        'rgba(0,0,0,0.3)',
        'rgba(0,0,0,0.3)',
        'rgba(0,0,0,0.3)',
        'rgba(0,0,0,0.3)',
        'transparent',
        'transparent'
      ],
      maxWidth: ['unset', 'unset', 'unset', 'unset', '30vw', '30vw'],
      width: ['100%', '100%', '100%', '100%', 'auto', 'auto'],
      zIndex: '4',
      position: 'absolute',
      top: 0,
      left: [0, 0, 0, 0, '16px', '16px'],
      display: 'flex',
      justifyContent: [
        'center',
        'center',
        'center',
        'center',
        'flex-start',
        'flex-start'
      ],
      alignItems: [
        'center',
        'center',
        'center',
        'center',
        'flex-start',
        'flex-start'
      ],
      '& img': {
        mt: ['16px', '16px', '16px', '16px', '32px', '32px'],
        display: 'block',
        width: ['70%', '70%', '70%', '70%', 'auto', 'auto'],
        p: 2
      }
    },
    authLogo: {
      label: 'BSauthLogo',
      height: '160px'
    },
    informationButtonsContainer: {
      label: 'BTinformationButtonsContainer',
      maxHeight: ['20vh', '20vh', '20vh', '20vh', '80%', '80%'],
      overflowY: 'auto',
      display: 'flex',
      flexWrap: 'wrap',
      flexDirection: 'row',
      flex: 1,
      alignItems: 'center',
      mb: 2,
      gap: 2,
      '::-webkit-scrollbar': {
        bg: 'backgroundPrimary',
        height: '8px'
      },
      '::-webkit-scrollbar-thumb': {
        bg: 'text',
        borderRadius: '100px'
      },
      '::-webkit-scrollbar-track': {
        bg: 'backgroundPrimary'
      }
    },
    privacyPolicyLink: {
      label: 'BTprivacyPolicyLink',
      textDecoration: 'underline',
      color: 'link',
      '&:focus': {
        border: 'focusOutline'
      }
    },
    languageSelectorSelectContainer: {
      label: 'BTlanguageSelectorSelectContainer',
      zIndex: 99,
      position: 'absolute',
      top: '2rem',
      right: '51px',
      bg: 'backgroundPrimary',
      color: 'text',
      backdropFilter: 'blur(50px)',
      display: 'flex',
      borderRadius: 'buttonBig',
      alignItems: 'center'
    },
    loadingMessage: {
      label: 'BTloadimgMessage',
      mt: [3, 3, 20, 20, 30, 30],
      color: 'white',
      textAlign: 'center',
      fontSize: [2, 2, 6, 6, 6, 6]
    },
    loadingBarContainer: {
      mt: [40, 40, 40, 50, 40, 40],
      mb: [50, 50, 50, 60, 147, 147],
      justifyContent: 'flex-start',
      border: '1px solid transparent',
      borderColor: 'primary',
      borderRadius: '4px'
    },
    loadingBarInnerContainer: {
      borderRadius: '3px',
      backgroundColor: 'rgba(0,0,0,0.7)',
      p: '2px'
    },
    loadingBar: {
      backgroundImage:
        'linear-gradient(45deg,hsla(0,0%,100%,.15) 25%,transparent 0,transparent 50%,hsla(0,0%,100%,.15) 0,hsla(0,0%,100%,.15) 75%,transparent 0,transparent)',
      backgroundColor: 'primary',
      height: [5, 5, 14, 14, 7, 7],
      animation: `${progressBarStripes} 1s linear infinite`,
      transition: 'width .6s ease',
      backgroundSize: '1rem 1rem',
      borderRadius: '4px'
    },
    questionsLoadingOverlay: {
      width: '100%',
      height: '100vh',
      backgroundSize: 'cover',
      backgroundPosition: 'right bottom',
      backgroundColor: '#CCC',
      overflow: 'hidden',
      position: 'absolute',
      display: 'grid',
      placeContent: 'center',
      top: 0,
      left: 0
    }
  },
  buttons: {
    base: {
      fontSize: ['11px', '11px', '14px', '14px', '14px', '14px'],
      lineHeight: ['13px', '13px', '17px', '17px', '17px', '17px'],
      cursor: 'pointer',
      fontFamily: 'inherit',
      borderRadius: '4px',
      fontWeight: '600',
      p: 2,
      gap: 2,
      transition: 'all 0.4s',
      whiteSpace: 'nowrap',
      border: '1px solid transparent',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      '& svg, & img': {
        minWidth: '24px',
        height: '24px',
        my: '3px',
        mx: '-1px',
        fill: 'currentColor'
      },
      '&:disabled': {
        opacity: 0.2,
        cursor: 'not-allowed',
        boxShadow: 'none'
      },
      '&:focus': {
        outline: '1px solid white'
      }
    },
    // TODO
    unstyled: {
      label: 'BTbuttonUnstyled',
      background: 'transparent',
      color: 'secondary',
      fontFamily: 'inherit',
      fontSize: 0
    },
    primary: {
      label: 'BTbuttonPrimary',
      variant: 'buttons.base',
      backgroundColor: 'primary',
      borderColor: 'primary',
      color: 'primaryText',
      '@media (hover: hover)': {
        '&:hover': {
          backgroundColor: 'primaryHover'
        }
      }
    },
    secondary: {
      label: 'BTbuttonSecondary',
      variant: 'buttons.base',
      backgroundColor: 'secondary',
      borderColor: 'secondary',
      color: 'secondaryText',
      '@media (hover: hover)': {
        '&:hover': {
          backgroundColor: 'secondaryHover'
        }
      }
    },
    primaryInverted: {
      label: 'BTbuttonPrimaryInverted',
      variant: 'buttons.base',
      bg: 'black',
      borderColor: 'primary',
      '@media (hover: hover)': {
        '&:hover': {
          backgroundColor: 'rgba(1, 99, 172, 0.6)'
        }
      },
      '&:focus': {
        outline: 'none',
        '--box-shadow-color': 'primary',
        boxShadow: '0 0 3px var(--box-shadow-color)'
      },
      '&:disabled': {
        borderColor: 'grey',
        color: '#E3E5E8'
      }
    },
    outline: {
      label: 'BTbuttonSecondaryInverted',
      color: 'textAlternate',
      fontWeight: '300',
      cursor: 'pointer',
      border: '1px solid #FFFFFF',
      transition: 'all 1s',
      backgroundColor: 'transparent',
      borderRadius: 50,
      '@media (hover: hover)': {
        '&:hover': {
          backgroundColor: 'rgba(255,255,255,0.1)'
        }
      },
      '&:focus': {
        outline: 'none',
        border: '1px solid transparent',
        borderColor: 'white',
        '--box-shadow-color': 'transparent',
        boxShadow: 'none'
      },
      '&:disabled': {
        backgroundColor: 'thirdGray',
        color: 'fourthGray'
      }
    },
    option: {
      label: 'BTbuttonOption',
      variant: 'buttons.base',
      whiteSpace: 'normal',
      '& svg, & img': {
        fill: 'currentColor'
      },
      color: 'text',
      '@media (hover: hover)': {
        '&:hover': {
          bg: 'backgroundSecondaryHover'
        }
      },
      ':focus': {
        border: '1px solid white'
      }
    },

    multiSelectSubmitButton: {
      label: 'BTbuttonMultiSelectSubmitButton',
      fontSize: ['14px', '14px', '16px', '16px', '16px', '16px'],
      lineHeight: ['17px', '17px', '19px', '19px', '19px', '19px'],
      fontWeight: 700,
      minWidth: 'unset',
      width: 'fit-content',
      py: 2,
      px: 7,
      ml: 0,
      mt: 12,
      display: 'flex',
      alignSelf: 'flex-end',
      mb: '2px',
      mr: '2px'
    }
  },
  breakpoints: embeddedMode
    ? ['1px', '2px', '3px', '4px', '5px', '6px']
    : ['320px', '600px', '768px', '1025px', '1280px', '1440px'],
  zIndices: {
    transcript: 5,
    themeSelector: 4
  }
})

const tvTheme = (type: THEMES, embeddedMode: boolean) => ({
  borders: {
    focusOutline: '1px solid white'
  },
  outlines: {
    focusOutline: '1px solid white'
  },
  buttons: {
    multiSelectSubmitButton: {
      fontSize: ['14px', '14px', '16px', '16px', '16px', '16px'],
      lineHeight: ['17px', '17px', '19px', '19px', '19px', '19px'],
      fontWeight: 700,
      minWidth: 'unset',
      width: 'fit-content',
      py: 2,
      px: 7,
      ml: 0,
      mt: 12,
      display: 'flex',
      alignSelf: 'flex-end',
      mb: '2px',
      mr: '2px',
      '&:focus': {
        border: '1px solid white'
      }
    }
  },
  variants: {
    privacyPolicyLink: {
      textDecoration: 'underline',
      color: 'link',
      '&:focus': {
        border: 'focusOutline',
        padding: '3px 0',
        borderRadius: 'button'
      }
    }
  }
})

const progressBarStripes = keyframes`
    0% {
      background-position: 1rem 0;
    }
    100% {
      background-position: 0 0;
    }
`

const mergeThemes = (
  type: THEMES,
  embeddedMode: boolean,
  theme: (type: THEMES, embeddedMode: boolean) => { [x: string]: any }
) => {
  return _.merge(baseTheme(type, embeddedMode), theme(type, embeddedMode))
}

const theme = (type: THEMES, embeddedMode: boolean) => {
  if (type === THEMES.SEGUROS_BOLIVAR) {
    return mergeThemes(type, embeddedMode, segurosBolivarTheme)
  }

  if (type === THEMES.DESERT_SPRINGS) {
    // T-3332 improve this function for wider use
    return mergeThemes(type, embeddedMode, desertSpringsTheme)
  }

  if (type === THEMES.ARETAEIO) {
    return mergeThemes(type, embeddedMode, aretaeioTheme)
  }

  if (process.env.REACT_APP_BUILD_MODE === 'tv') {
    return mergeThemes(type, embeddedMode, tvTheme)
  }

  return baseTheme(type, embeddedMode)
}

export default theme
