const linesToSpeakReducer = (config?: any) => (state: any, action: any) => {
  switch (action.type) {
    case 'linesToSpeakSet':
      console.log('LINESTOSPEAK 1. Payload:', action.payload)
      return {
        ...state,
        linesToSpeak: action.payload.linesToSpeak,
        stringToSpeak: action.payload.stringToSpeak
      }
    default:
      return state
  }
}

export default linesToSpeakReducer
