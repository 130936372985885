import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import ReactTooltip from 'react-tooltip'
import { useUneeqState } from 'uneeq-react-core'
import { useRepeatLinesToSpeak } from '../../app/hooks/useRepeatLinesToSpeak'
import { useUneeqContext } from '../../app/hooks/useUneeqContext'

import { Button } from 'rebass'
import { ReactComponent as RepeatIcon } from '../../app/assets/icons/repeat.svg'

interface MayaRepeatButtonProps {
  disabled?: boolean
}

const MayaRepeatButton = ({ disabled }: MayaRepeatButtonProps) => {
  const { t } = useTranslation()

  const { mayaQuestion } = useUneeqState()

  const { dispatch } = useUneeqContext()

  const repeatLinesToSpeak = useRepeatLinesToSpeak()

  useEffect(() => {
    ReactTooltip.rebuild()
  }, [])

  const repeatCurrentLines = () => {
    repeatLinesToSpeak()
    dispatch &&
      setTimeout(
        () =>
          dispatch({
            type: 'updateQuestionRepeatCount',
            payload: mayaQuestion
          }),
        100
      )
  }

  return (
    <Button
      variant="secondary"
      data-tip={t('Tooltip.repeatButton')}
      sx={{ flexShrink: 0 }}
      onClick={() => repeatCurrentLines()}
      disabled={disabled}
    >
      <RepeatIcon />
    </Button>
  )
}

export default MayaRepeatButton
