export const b64toBlob = (
  b64Data: string,
  contentType = '',
  sliceSize = 512
) => {
  console.log('b64Data', b64Data)
  const byteCharacters = atob(b64Data)
  const byteArrays = []

  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    const slice = byteCharacters.slice(offset, offset + sliceSize)

    const byteNumbers = new Array(slice.length)
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i)
    }

    const byteArray = new Uint8Array(byteNumbers)
    byteArrays.push(byteArray)
  }

  const blob = new Blob(byteArrays, { type: contentType })
  return blob
}

export const downloadBase64 = (b64Data: string, contentType: string) => {
  const blob = b64toBlob(b64Data, contentType)
  const blobUrl = URL.createObjectURL(blob)

  window.open(blobUrl, '_blank')
}

export const fnOnEnter = (fn: Function) => (
  e: React.KeyboardEvent<HTMLElement>
) => {
  if (e.code === 'Enter') {
    fn(e)
  }
}

export const removeHTMLTags = (str: string) =>
  String(str).replace(/(<([^>]+)>)/gi, '')

export const decodeHTMLEntities = (html: string) => {
  var txt = document.createElement('textarea')
  txt.innerHTML = html
  return txt.value
}

export const formatArrayNameIntoOneSentence = (array: []) => {
  return array.reduce((formattedList, object, index) => {
    const finalString =
      array.length === 1
        ? object.name
        : array.length - 2 === index
        ? `${formattedList} ${object.name}`
        : array.length - 1 === index
        ? `${formattedList} and ${object.name}`
        : `${formattedList} ${object.name},`

    return finalString.trim()
  }, '')
}
