import { format } from 'date-fns'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { Box, Button, Flex, Heading, Image, Text } from 'rebass'
import { useUneeqState } from 'uneeq-react-core'
import { useSessionContext } from '../../../app/hooks/useSessionContext'
import { Divider } from '../RedUI'
import { ComposePhoneNumbers } from './ComposePhoneNumbers'
import { useUneeqContext } from '../../../app/hooks/useUneeqContext'
import * as encode from 'jwt-encode'

type DataSection = {
  text: string
  label: string
  children: React.ReactNode
}

type DesertSpringsProps = {
  title: string
  children: React.ReactNode
}

const DataSection = ({ text, label, children }: DataSection) => {
  const { linesToSpeak } = useUneeqState()
  const currentLine = linesToSpeak?.find((line: any) => line?.currentlySpeaking)

  const isHiglighted = currentLine?.text.includes(text)

  return (
    <Box flex={1} variant={isHiglighted && 'activeSpokenContent'}>
      <Text variant="label">{label}</Text>
      <Flex justifyContent="space-between">
        <Text variant="content" flex={1}>
          {text}
        </Text>
        {children}
      </Flex>
    </Box>
  )
}

export const AfterHospitalCarePlan = () => {
  const {
    state: { dischargeCarePlan }
  } = useSessionContext()
  const { t } = useTranslation()

  const {
    patient,
    discharge_cc,
    emergency_contact_numbers,
    medical_conditions,
    discharge_date,
    provider,
    other_emergency_contact_numbers,
    personal_caregiver_name,
    home_care_agency,
    clinic_specific_text
  } = dischargeCarePlan

  const dischargeDate = format(
    new Date(new Date(discharge_date).toISOString().slice(0, -1)),
    'LLLL do, yyyy'
  )

  const { uneeq, sessionId } = useUneeqContext()

  console.log('UNEEQ SESSION ID FROM CONTEXT', sessionId)

  const fetchOptions = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      answer: '<uneeq:posture_smallshifts /> test',
      answerAvatar: '{}',
      sessionIdJwt: encode(
        { sessionId: sessionId },
        '1C32690F-D07E-32C3-C2BC-CC0196D49D1D'
      )
    })
  }

  console.log('fetchOptions', fetchOptions)

  const dontMove = async () => {
    const result = await fetch(
      `https://api.us.uneeq.io/api/v1/avatar/${sessionId}/speak`,
      fetchOptions as any
    )
    console.log('result', result)
    // uneeq?.current?.sendTranscript('<uneeq:posture_smallshifts />')
  }

  return (
    <>
      <Flex variant="ahcpCard" bg="white">
        <Flex sx={{ justifyContent: 'space-between' }}>
          <Box>
            <Text variant="label">{t('AHCP.subtitles.patient')}</Text>
            <Heading variant="headingMedium">
              {patient.first_name} {patient.last_name}
            </Heading>
          </Box>

          <Box sx={{ alignItems: 'center' }}>
            <Image
              variant="inlineLogo"
              src={discharge_cc.organization.organizationLogo}
              alt="Logo"
            />
          </Box>
        </Flex>
        <Divider />
        <Flex flexDirection="row" sx={{ gap: [6, 6, 6, 6, 6, 6, 6, 8] }}>
          {personal_caregiver_name && (
            <Flex
              flex={1}
              flexDirection="column"
              sx={{ gap: [6, 6, 6, 6, 6, 6, 6, 8] }}
            >
              <DataSection
                label={t('AHCP.subtitles.dischargeDate')}
                text={dischargeDate}
              />
              <DataSection
                label={t('AHCP.subtitles.atHomeCaregiver')}
                text={dischargeCarePlan.personal_caregiver_name}
              />
            </Flex>
          )}

          {medical_conditions.map(({ medical_condition }) => (
            <DataSection
              label={t('AHCP.subtitles.primaryDiagnosis')}
              text={medical_condition.name}
            />
          ))}
        </Flex>
      </Flex>

      <Flex variant="ahcpCard" bg="white">
        <Heading variant="headingMedium">Problems and questions</Heading>
        <Divider />

        {emergency_contact_numbers.length > 0 && emergency_contact_numbers && (
          <DataSection
            label={t('AHCP.subtitles.callForReason', {
              reason: t('AHCP.subtitles.heartProblems')
            })}
            text={provider}
          >
            <ComposePhoneNumbers numbers={emergency_contact_numbers} />
          </DataSection>
        )}
        <Flex flexDirection="column" sx={{ gap: 6 }}>
          {other_emergency_contact_numbers.length > 0 &&
            other_emergency_contact_numbers.map(
              ({
                provider,
                speciality,
                day_contact_number,
                night_contact_number
              }) => {
                // This parsing is horrible but the information is not coming in the best way to be displayed
                const dayNumber = day_contact_number
                  ? {
                      type: 'day',
                      contact_number: day_contact_number
                    }
                  : {}
                const nightNumber = night_contact_number
                  ? {
                      type: 'night',
                      contact_number: night_contact_number
                    }
                  : {}

                const composeEmergencyNumbers = [dayNumber, nightNumber]

                return (
                  <DataSection
                    label={t('AHCP.subtitles.callForReason', {
                      reason: speciality
                    })}
                    text={provider}
                  >
                    <ComposePhoneNumbers numbers={composeEmergencyNumbers} />
                  </DataSection>
                )
              }
            )}
          {home_care_agency && (
            <DataSection
              label={t('AHCP.subtitles.callForReason', {
                reason: t('AHCP.subtitles.homeHealth')
              })}
              text={home_care_agency.name}
            >
              <ComposePhoneNumbers
                numbers={[{ contact_number: home_care_agency.contact }]}
              />
            </DataSection>
          )}
        </Flex>
        <Divider />
        <Heading variant="headingMedium">
          {t('AHCP.subtitles.emergency')}
        </Heading>
        {clinic_specific_text && (
          <Text mb="0" variant="labelMedium" sx={{ textTransform: 'none' }}>
            {clinic_specific_text}
          </Text>
        )}
        <Button onClick={() => uneeq?.current?.pauseSession()}>PAUSE</Button>
        <Button onClick={() => uneeq?.current?.resumeSession()}>RESUME</Button>
        <Button onClick={dontMove}>don't move</Button>
      </Flex>
    </>
  )
}

const DesertSprings = ({ title = '', children }: DesertSpringsProps) => {
  return (
    <Flex>
      <Text variant="title">{title}</Text>
      <Flex variant="card">{children}</Flex>
    </Flex>
  )
}

export { DesertSprings }
