import React, { forwardRef } from 'react'
import { Box } from 'reflexbox'

const SVG = ({ size = 24, ...props }) => (
  <Box
    as="svg"
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    viewBox="0 0 24 24"
    fill="currentcolor"
    {...props}
  />
)

const CheckboxChecked = props => (
  <SVG {...props}>
    <path
      fill-rule="evenodd"
      d="M18.64 6.732a1 1 0 0 0-1.408.128l-6.8 8.159-3.725-3.726a1 1 0 0 0-1.414 1.414l4.5 4.5a1 1 0 0 0 1.475-.067l7.5-9a1 1 0 0 0-.128-1.408Z"
      clip-rule="evenodd"
    />
  </SVG>
)

const CheckboxIcon = props => (
  <>
    <CheckboxChecked
      {...props}
      __css={{
        display: 'none',
        'input:checked ~ &': {
          display: 'block'
        }
      }}
    />
  </>
)

export const CustomCheckbox = forwardRef(
  ({ className, sx, variant = 'checkbox', ...props }, ref) => (
    <Box sx={{ position: 'relative' }}>
      <Box
        ref={ref}
        as="input"
        type="checkbox"
        {...props}
        sx={{
          label: 'CustomCheckbox',
          position: 'absolute',
          opacity: 0,
          zIndex: -1,
          width: 1,
          height: 1,
          overflow: 'hidden'
        }}
      />
      <Box
        aria-hidden="true"
        variant={variant}
        className={className}
        __css={{
          label: 'CustomCheckboxBG',
          backgroundColor: 'white',
          border: 'solid 1px transparent',
          borderColor: 'greyLight',
          borderRadius: '16px',
          color: 'gray',
          width: '32px',
          height: '32px',
          mr: 2,
          'input:focus ~ &': {
            outline: '4px solid transparent',
            outlineColor: 'highlight'
          }
        }}
      />
      <Box
        as={CheckboxIcon}
        aria-hidden="true"
        tx="forms"
        sx={sx}
        __css={{
          label: 'CustomCheckboxIcon',
          position: 'absolute',
          color: 'gray',
          top: '4px',
          left: '4px'
        }}
      />
    </Box>
  )
)
