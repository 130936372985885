import React, { useEffect, useRef } from 'react'
declare const grecaptcha: any

type RecaptchaProps = {
  onSuccess: (token: string) => void
}
export const Recaptcha = ({ onSuccess }: RecaptchaProps) => {
  const recaptchaContainer = useRef(null)

  useEffect(() => {
    if (grecaptcha && recaptchaContainer.current) {
      grecaptcha.render(recaptchaContainer.current, {
        sitekey: '6LfkL4gfAAAAAHihTa5_UlYGxAf6eSKdnia-gFtg',
        callback: onSuccess
      })
    }
  }, [onSuccess])

  return <div ref={recaptchaContainer}></div>
}
