import { THEMES } from '../theme'

export const desertSpringsColors = {
  // Text Swatches
  text: '#111111',
  textLight: '#393939',
  textAlternate: '#FFFFFF',
  textTranscript: '#FFF',
  textTitle: '#111111',

  // Button Swatches
  primary: '#345D96',
  primaryHover: '#183D71',
  primaryText: '#fff',

  secondary: '#DBDBDB',
  secondaryHover: '#C0C0C0',
  secondaryText: '#111111',

  // ??
  greyDark: '#50575D',
  grey: '#9A9A9A',
  greyLight: '#E8E8E8',
  informationDark: 'rgba(51,51,51,0.95)',
  informationLight: 'rgba(255, 255, 255, 0.2)',

  // UI ??
  background: 'rgba(255, 255, 255, 0.95)',
  backgroundPrimary: '#efefef',
  backgroundPrimarySolid: 'rgba(255, 255, 255)',
  backgroundSecondary: '#95222F',
  backgroundSecondaryHover: 'rgba(51, 51, 51, 0.3)',
  backgroundOverlay: 'rgb(51, 51, 51, 0.5)',
  backgroundError: '#FDE9E6',
  error: '#BA1701',
  warning: '#FBBD1D',
  success: '#56AF60',
  overlayBackground: 'rgba(0,0,0,0.7)',
  activeContentBg: 'rgba(52,93,150,0.2)',
  activeContentBorder: 'rgba(52, 93, 150, 0.4)',

  // Form Swatches
  inputBackground: '#FCFCFC',
  inputBackgroundHover: '#FFFFFF',
  inputBorder: 'rgba(17, 17, 17, 0.2)',

  optionSelectedBorder: '#111111',
  optionBorder: '#CCCCCC',
  optionText: '#999999',
  optionSelectedBg: '#EBEBEB',
  optionSelectedText: '#333333',

  datePickerDayBg: '#F0EBEB',
  datePickerDayColor: '#000',
  datePickerOverlayBg: '#FFF',
  //??
  checkboxChecked: '#95222F',
  link: '#95222F',

  // create our own progress bar with rebass so we can use variant="timoutBar"
  timeoutColor: '#95222F',
  timeoutTrailColor: '#FBFBFB',

  calendarHighlight1: '#FBDAFF',
  calendarHighlight2: '#E1FFDC',
  calendarHighlight3: '#DEFFF9',
  calendarHighlight4: '#BAC9FF',
  calendarHighlight5: '#ABDAFF',
  calendarHighlight6: '#A1FFDC',
  calendarHighlight7: '#FFF8B6',
  calendarHighlight8: '#CAC9FF',
  calendarHighlight9: '#e6d5ea',
  calendarHighlight10: '#afe7c6',
  calendarHighlight11: '#d4edd3',
  calendarHighlight12: '#e6cebf',
  calendarHighlight13: '#f9d0dc',
  calendarHighlight14: '#d5efab',
  calendarHighlight15: '#f4e8cc',
  calendarHighlight16: '#d899fb',
  calendarHighlight17: '#C3FBE7',
  calendarHighlight18: '#bbc2fe',
  calendarHighlight19: '#fac4c8',
  calendarHighlight20: '#f39de6',
  calendarHighlight21: '#B1DAFF',
  calendarHighlight22: '#fdffcb',
  calendarHighlight23: '#c1ee7f',
  calendarHighlight24: '#fbdba7',
  calendarHighlight25: '#fbff96',
  calendarHighlight26: '#e8a3c9',
  calendarHighlight27: '#ccfad0',
  calendarHighlight28: '#d1e2ff',
  calendarHighlight29: '#d9e5ae',
  calendarHighlight30: '#f8e3ee'
}

export const desertSpringsTheme = (type: THEMES, embeddedMode: boolean) => ({
  fonts: {
    body: 'Inter, sans-serif',
    heading: 'inherit'
  },
  lineHeights: {
    headerLarge: '44px',
    headerMedium: '36px',
    headerSmall: '30px',
    headerMicro: '22px',
    textLarge: '44px',
    textMedium: '30px',
    textMediumLong: '36px',
    textSmall: '24px',
    textMicro: '20px',
    labelLarge: '24px',
    labelMedium: '20px',
    labelSmall: '20px',
    labelMicro: '16px'
  },
  borders: {
    card: '1px solid #efefef',
    default: '1px solid #D4D8DC',
    focused: '1px solid #1375b8',
    error: '1px solid #EF1919'
  },
  shadows: {
    pageTitle: '0px 3px 1px rgba(17, 17, 17, 0.7)',
    button: '0px 4px 4px rgba(17, 17, 17, 0.25)'
  },
  radii: {
    button: 2,
    buttonBig: 4,
    input: 4,
    card: 8
  },
  buttons: {
    base: {
      borderRadius: 'buttonBig',
      fontWeight: 'bold',
      fontSize: ['18px', '18px', '18px', '18px', 5, 5],
      lineHeight: [
        '24px',
        '24px',
        '24px',
        '24px',
        'headerSmall',
        'headerSmall'
      ],
      cursor: 'pointer',
      px: '15px',
      py: '11px',
      gap: 4,

      '&:disabled': {
        opacity: 0.4,
        cursor: 'not-allowed',
        boxShadow: 'none'
      }
    },
    primary: {
      label: 'DSbuttonPrimary',
      variant: 'buttons.base',
      color: 'primaryText',
      borderColor: 'primary',
      bg: 'primary',
      '&:hover': {
        bg: 'primaryHover',
        color: 'primaryText',
        borderColor: 'primaryHover'
      },
      '&:active': {
        bg: 'primaryHover',
        color: 'primaryText',
        borderColor: 'primaryHover'
      },
      '&:focus': {
        bg: 'primaryHover',
        color: 'primaryText',
        borderColor: 'primaryHover'
      }
    },
    secondary: {
      label: 'DSbuttonSecondary',
      variant: 'buttons.base',
      color: 'secondaryText',
      borderColor: 'secondary',
      bg: 'secondary',
      '& svg': {
        fill: 'secondaryText'
      },
      '&:hover': {
        bg: 'secondaryHover',
        color: 'secondaryText',
        borderColor: 'secondaryHover'
      },
      '&:active': {
        bg: 'secondaryHover',
        color: 'secondaryText',
        borderColor: 'secondaryHover'
      },
      '&:focus': {
        bg: 'secondaryHover',
        color: 'secondaryText',
        borderColor: 'secondaryHover'
      }
    },
    unstyled: {
      label: 'DSbuttonUnstyled',
      variant: 'buttons.base',
      background: 'transparent',
      borderColor: 'transparent',
      color: 'secondaryText'
    },
    option: {
      variant: 'buttons.base',
      label: 'DSButtonOption',
      bg: '#fff',
      border: '4px solid',
      px: '12px',
      py: '8px', // Extra border width subtracted
      color: 'text',
      borderColor: '#fff',
      whiteSpace: 'normal',
      textAlign: 'left',
      boxShadow: 'button',
      '&:hover': {
        variant: 'buttons.optionSelected'
      },
      '&:focus': {
        variant: 'buttons.optionSelected'
      }
    },
    optionSelected: {
      variant: 'buttons.base',
      label: 'DSButtonOptionSelected',
      color: 'primary',
      px: '12px',
      py: '8px', // Extra border width subtracted
      borderColor: '#D6DFEA',
      bg: '#D6DFEA',
      border: '4px solid',
      whiteSpace: 'normal',
      textAlign: 'left'
    }
  },
  forms: {
    input: {
      label: 'DSinput',
      bg: 'inputBackground',
      boxSizing: 'border-box',
      fontSize: ['16px', '16px', '16px', '16px', '18px', '18px'],
      lineHeight: [
        'textMicro',
        'textMicro',
        'textMicro',
        'textMicro',
        'textSmall',
        'textSmall'
      ],
      px: '6px',
      py: 4,
      borderRadius: 'input',
      borderColor: 'inputBorder',
      display: 'flex',
      cursor: 'text',
      '&.errorInput': {
        borderColor: 'error'
      },
      '&.form-input': {
        '&:focus, :focus-visible': {
          outline: 'unset',
          border: '1px solid #000000'
        }
      },
      '& > div': {
        width: '100%'
      },
      '&:focus, :focus-visible': {
        outline: 'unset'
      },
      '& ::-webkit-outer-spin-button, ::-webkit-inner-spin-button': {
        appearance: 'none',
        margin: 0
      },
      '&:disabled': {
        opacity: 0.4,
        cursor: 'not-allowed'
      }
    }
  },
  appStyles: {
    appBackground: (bgImage: string, bgMobileImage?: string) => ({
      backgroundImage: [
        `url(${bgMobileImage || bgImage})`,
        `url(${bgMobileImage || bgImage})`,
        `url(${bgMobileImage || bgImage})`,
        `url(${bgImage})`,
        `url(${bgImage})`
      ],
      backgroundPosition: [
        'center',
        'center',
        'center',
        'center',
        'center',
        'center'
      ]
    }),
    chatBackground: (bgChatImage: string) => ({
      backgroundImage: `url(${bgChatImage})`,
      backgroundSize: 'cover'
    })
  },
  variants: {
    card: {
      label: 'DScard',
      bg: 'white',
      color: 'textLight',
      borderRadius: 'card',
      flexDirection: 'column',
      p: [6, 6, 6, 6, 10, 10],
      boxShadow: 'card',
      gap: 10
    },
    fadedCard: {
      label: 'BTfadedCard',
      variant: 'variants.card',
      backgroundColor: 'greyLight',
      '& > div': {
        opacity: 0.5
      }
    },
    mainAppContainer: {
      label: 'DSMainAppContainer',
      background: 'linear-gradient(94.98deg, #275DAF 4.43%, #000F34 96.73%)',
      backgroundRepeat: 'no-repeat',
      backgroundSize: ['cover', 'cover', 'cover', 'cover', 'cover'],
      height: '100%!important'
    },
    interactionContainer: {
      label: 'DSinteractionContainer',
      p: 0,
      width: '100%'
    },
    questionsLoader: {
      label: 'DSquestionsLoader',
      position: 'absolute',
      top: '50%',
      left: '50%',
      ml: '-27px',
      mt: '-43px', // Half height + base padding
      width: '48px',
      height: '48px',
      alignItems: 'center',
      justifyContent: 'center',
      bg: 'none',
      zIndex: 5,
      backdropFilter: 'none'
    },
    questionsSpinner: {
      label: 'DSquestionsSpinner',
      width: '32px!important',
      height: '32px!important',
      '& div': {
        backgroundColor: '##111111!important'
      }
    },
    questionsWrapper: {
      label: 'DSquestionsWrapper',
      p: 12
    },
    questionContainer: {
      label: 'DSQuestionContainer',
      background: 'none',
      backdropFilter: 'none',
      flexDirection: 'row-reverse',
      justifyContent: 'flex-end',
      boxShadow: 'none'
    },
    mayaCaptionWrapper: {
      label: 'DSmayaCaptionWrapper',
      backgroundColor: 'rgba(17, 17, 17, 0.8)',
      p: 4,
      borderRadius: 'cardSmall',
      position: 'relative',
      pr: '54px', // Hardcoded to button size
      minHeight: '54px',
      border: 'solid 1px transparent',
      borderColor: 'informationLight',
      boxShadow: 'button',
      '&.captions-off': {
        alignSelf: 'flex-end',
        padding: 0,
        pr: '54px'
      },
      '& button': {
        color: 'grey',
        position: 'absolute',
        bottom: 0,
        right: 0
      },
      '& button:focus': {
        outline: 'none'
      },
      '&.captions-on button': {
        color: 'white'
      }
    },
    redContent: {
      label: 'DSredContent',
      variant: 'card',
      borderRadius: '8px 8px 0px 0px',
      flex: 1,
      p: [6, 6, 6, 6, 12, 12],
      flexDirection: 'column',
      backgroundColor: 'white',
      gap: 10
    },
    redContentSecondary: {
      label: 'DSredContentSecondary',
      variant: 'card',
      borderRadius: '8px 8px 0px 0px',
      flex: 1,
      p: [0, 0, 0, 0, 0, 0],
      backgroundColor: 'greyLight',
      gap: 10
    },

    customPositionedInteractionContainer: {
      label: 'DSCustomPositionedInteractionContainer',
      left: ['unset', 'unset', 'unset', 'unset', '23%', '23%']
    },
    homeTerms: {
      label: 'DSHomeTerms',
      display: ['none', 'none', 'none', 'none', 'none']
    },
    transcriptContainer: {
      label: 'DStranscriptContainer',
      boxShadow: '0px 1px 3px rgba(0, 0, 0, 0.15)'
    },
    inputSelect: {
      label: 'DSinputSelect',
      border: '1px solid #CCCCCC',
      pl: 6,
      pr: 10,
      py: 3,
      borderRadius: '4px'
    },

    fakeInputWrapper: {
      label: 'DSfakeInputWrapper',
      variant: 'forms.input',
      '&:focus-within': {
        border: 'focused'
      }
    },
    homeLogo: {
      label: 'DShomeLogo',
      backgroundColor: [
        'transparent',
        'transparent',
        'transparent',
        'transparent',
        'transparent',
        'transparent'
      ],
      maxWidth: ['unset', 'unset', 'unset', 'unset', '20vw', '20vw'],
      width: ['60%', '60%', '60%', '60%', 'auto', 'auto'],
      zIndex: '4',
      position: ['initial', 'initial', 'absolute'],
      top: 0,
      left: ['16px', '16px', '16px', '16px', '24px', '24px'],
      display: 'flex',
      justifyContent: [
        'flex-start',
        'flex-start',
        'flex-start',
        'flex-start',
        'flex-start',
        'flex-start'
      ],
      alignItems: [
        'flex-start',
        'flex-start',
        'flex-start',
        'flex-start',
        'flex-start',
        'flex-start'
      ],
      '& img': {
        mt: ['0px', '0px', '0px', '0px', '0px', '0px'],
        display: 'block',
        width: ['70%', '70%', '50%', '50%', 'auto', 'auto'],
        p: 2
      }
    },
    authLogo: {
      label: 'DSauthLogo',
      height: ['auto', 'auto', 'auto', 'auto', '120px', '120px'],
      width: ['100%', '100%', '100%', '100%', 'auto', 'auto'],
      maxWidth: '360px'
    },
    inlineLogo: {
      label: 'DSinlineLogo',
      height: ['64px', '64px', '64px', '64px', '64px', '64px', '64px', '76px'],
      display: 'block' // removes inline margins
    },
    mayaOverlay: {
      label: 'DSmayaOverlay',
      backdropFilter: 'blur(30px)'
    },
    progressContainer: {
      label: 'DSprogressContainer',
      '& div': { height: '14px' },
      '& > div': {
        border: '1px solid #CCCCCC',
        borderRadius: '100px',
        overflow: 'hidden'
      }
    },
    footerDisclaimer: {
      label: 'DSfooterDisclaimer',
      height: '48px',
      position: 'relative',
      display: ['block', 'block', 'block', 'block', 'none', 'none'],
      '& > img': {
        position: 'absolute',
        mixBlendMode: 'difference',
        transform: 'rotate(90deg) translate(50%, -50%)',
        transformOrigin: 'top',
        top: '24px',
        left: '50%',
        height: '200px',
        opacity: '0.5'
      }
    },
    informationButtonsContainer: {
      label: 'DSinformationButtonsContainer',
      justifyContent: 'center'
    },
    homeContainer: {
      label: 'DShomeContainer',
      variant: 'card',
      m: 6,
      gap: 6,
      width: '49%',
      left: 0
    },
    languageSelectorSelectContainer: {
      label: 'DSlanguageSelectorSelectContainer',
      zIndex: 99,
      position: 'absolute',
      top: '20px',
      right: '18px',
      bg: 'backgroundPrimary',
      color: 'text',
      backdropFilter: 'blur(50px)',
      display: 'flex',
      borderRadius: 'buttonBig',
      alignItems: 'center'
    },
    appointmentCard: {
      label: 'DSappointmentCard',
      flexDirection: 'column',
      borderRadius: 'card',
      color: 'text',
      width: '100%',
      p: [7, 7, 7, 7, 7, 7, 7, 10],
      gap: [5, 5, 5, 5, 5, 5, 5, 10],
      flex: 1
    },
    ahcpCard: {
      label: 'DSahcpCard',
      flexDirection: 'column',
      borderRadius: 'card',
      color: 'text',
      width: '100%',
      px: [7, 7, 7, 7, 7, 7, 7, 10],
      py: 7,
      gap: [5, 5, 5, 5, 5, 5, 5, 7]
    },
    loadingBarContainer: {
      borderColor: 'primary',
      borderRadius: '8px'
    },
    loadingBarInnerContainer: {
      borderRadius: '8px'
    },
    loadingBar: {
      backgroundImage: 'none',
      backgroundColor: 'primary',
      borderRadius: '8px'
    },
    questionsLoadingOverlay: {
      backgroundColor: '#CCC'
    }
  },
  breakpoints: [
    '320px',
    '600px',
    '768px',
    '1025px',
    '1280px',
    '1440px',
    '1512px'
  ],
  text: {
    pageTitle: {
      label: 'DSpagetitle',
      color: 'white',
      fontWeight: 'bold',
      fontSize: ['18px', '18px', '18px', '18px', 5, 5, 5, 7],
      lineHeight: [
        'headerMicro',
        'headerMicro',
        'headerMicro',
        'headerMicro',
        'headerSmall',
        'headerMedium'
      ],
      textShadow: 'pageTitle',
      textAlign: 'center',
      letterSpacing: '-0.03em',
      m: 0,
      p: 0
    },
    heading: {
      label: 'DSheading',
      fontWeight: 'bold',
      fontSize: [5, 5, 5, 5, 7, 8],
      lineHeight: [
        'headerSmall',
        'headerSmall',
        'headerSmall',
        'headerSmall',
        'headerMedium',
        'headerLarge'
      ],
      color: 'text',
      m: 0,
      p: 0
    },
    headingSmall: {
      variant: 'text.heading',
      fontSize: [18, 18, 18, 18, 5, 5],
      lineHeight: [
        'headerMicro',
        'headerMicro',
        'headerMicro',
        'headerMicro',
        'headerSmall',
        'headerSmall'
      ]
    },
    headingMedium: {
      variant: 'text.heading',
      fontSize: [5, 5, 5, 5, 5, 5, 5, 7],
      lineHeight: [
        'headerSmall',
        'headerSmall',
        'headerSmall',
        'headerSmall',
        'headerSmall',
        'headerMedium'
      ]
    },
    headingLarge: {
      variant: 'text.heading',
      fontSize: [5, 5, 5, 5, 7, 7, 7, 8],
      lineHeight: [
        'headerSmall',
        'headerSmall',
        'headerSmall',
        'headerSmall',
        'headerLarge',
        'headerLarge'
      ]
    },
    label: {
      label: 'DSlabel',
      opacity: 0.7,
      color: 'text',
      fontWeight: 'bold',
      fontSize: [0, 0, 0, 0, 2, 2, 2, '18px'],
      lineHeight: [
        'labelSmall',
        'labelSmall',
        'labelSmall',
        'labelSmall',
        'labelMedium',
        'labelLarge'
      ],
      textTransform: 'uppercase',
      letterSpacing: '0.02em',
      mb: [1, 1, 1, 1, 1, 1, 1, 2]
    },
    labelMedium: {
      variant: 'text.label',
      fontSize: [0, 0, 0, 0, 0, 2],
      lineHeight: [
        'labelSmall',
        'labelSmall',
        'labelSmall',
        'labelSmall',
        'labelSmall',
        'labelMedium'
      ],
      textTransform: 'uppercase',
      letterSpacing: '0.02em',
      mb: 2
    },
    labelSmall: {
      variant: 'text.label',
      fontSize: [
        '12px',
        '12px',
        '12px',
        '12px',
        '12px',
        '12px',
        '12px',
        '13px'
      ],
      fontWeight: '600',
      mb: 0,
      lineHeight: [
        'labelMicro',
        'labelMicro',
        'labelMicro',
        'labelMicro',
        'labelMicro',
        'labelMicro'
      ],
      textTransform: 'uppercase',
      letterSpacing: '0.01em'
    },
    content: {
      label: 'DScontent',
      color: 'text',
      fontWeight: 500,
      fontSize: ['18px', '18px', '18px', '18px', 4, 4, 4, 7],
      lineHeight: [
        'textSmall',
        'textSmall',
        'textSmall',
        'textSmall',
        'textMedium',
        'textMedium',
        'textMedium',
        'textLarge'
      ]
    },
    contentMedium: {
      variant: 'text.content',
      fontSize: [18, 18, 18, 18, 4, 4, 4, 4],
      lineHeight: [
        'textSmall',
        'textSmall',
        'textSmall',
        'textSmall',
        'textMedium',
        'textMedium'
      ]
    },
    contentParagraph: {
      variant: 'text.contentMedium',
      fontSize: ['18px', '18px', '18px', '18px', 5, 5],
      mb: [2, 2, 2, 2, 12, 12],
      lineHeight: [
        'textSmall',
        'textSmall',
        'textSmall',
        'textSmall',
        'textMediumLong',
        'textLarge'
      ]
    },
    contentSmall: {
      label: 'DScontentSmall',
      color: 'text',
      fontWeight: 500,
      fontSize: '18px',
      lineHeight: '24px'
    },
    contentMicro: {
      label: 'DScontenMicro',
      color: 'text',
      fontWeight: 500,
      fontSize: '16px',
      lineHeight: 'textMicro'
    },
    errorText: {
      label: 'DSerrorText',
      variant: 'text.contentSmall',
      color: 'error'
    },
    mayaCaption: {
      label: 'DSmayaCaption',
      variant: 'text.contentMedium',
      color: '#fff'
    }
  }
})
