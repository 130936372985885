import React, { useContext, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Box, Button, Flex, Text } from 'rebass'
import { UneeqContext, useUneeqState } from 'uneeq-react-core'
import Error from '../MayaIcons/Error'
import { MayaOverlay } from '../MayaOverlay'

type MayaBandwidthProps = {
  digitalHumanOnly?: boolean
}

const MayaBandwidth = ({ digitalHumanOnly = false }: MayaBandwidthProps) => {
  const { t } = useTranslation()
  const { isLowBandwidth, finishLoadingAt } = useUneeqState()
  const { dispatch } = useContext(UneeqContext)
  const [showAlert, setShowAlert] = useState(true)

  const pauseDigitalHuman = () => {
    dispatch({ type: 'pauseSession' })
    setShowAlert(false)
  }

  const exitDigitalHuman = () => {
    dispatch({
      type: 'mayaMessage',
      payload: {
        type: 'exit'
      }
    })
    setShowAlert(false)
  }

  const dismiss = () => {
    setShowAlert(false)
  }

  if (!showAlert) {
    return null
  }

  if (!finishLoadingAt) {
    return null
  }

  const Alert = () => (
    <MayaOverlay>
      <Flex variant="modal">
        <Box variant="modalIconWrapper">
          <Error />
        </Box>
        <Box>
          <Text variant="modalText">
            {digitalHumanOnly ? t('Bandwidth.altTitle') : t('Bandwidth.title')}
          </Text>
        </Box>

        <Flex variant="modalButtons">
          {!digitalHumanOnly && (
            <Button variant="primary" onClick={pauseDigitalHuman}>
              {t('Bandwidth.confirm')}
            </Button>
          )}
          {digitalHumanOnly && (
            <Button variant="primary" onClick={exitDigitalHuman}>
              {t('Bandwidth.exit')}
            </Button>
          )}
          <Button variant="secondary" onClick={dismiss}>
            {t('Bandwidth.dismiss')}
          </Button>
        </Flex>
      </Flex>
    </MayaOverlay>
  )

  return isLowBandwidth ? <Alert /> : null
}

export default MayaBandwidth
