import { normalizeDischargeCarePlanData } from '../Authentication/registerSession'

type getDischargeCarePlanProps = {
  id: string
  code: string
}

export const getDischargeCarePlan = async (
  id,
  code
): getDischargeCarePlanProps => {
  try {
    if (!id) {
      throw new Error('Patient ID can`t be null')
    }

    // @todo hardcoded for now
    const body = {
      id,
      code
    }

    const res = await fetch(
      `${process.env.REACT_APP_BACKEND_URL}/verifyCarePlanCode`,
      {
        method: 'POST',
        body: JSON.stringify(body),
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${process.env.REACT_APP_BACKEND_MAYARED_TOKEN}`
        }
      }
    )

    const bodyRes = await res.json()

    if (bodyRes.error) {
      throw new Error(bodyRes.error.response.error.message || res.statusText)
    }

    return normalizeDischargeCarePlanData(bodyRes)
  } catch (e) {
    throw e
  }
}
