import React, { useCallback, useContext, useState } from 'react'
import UneeqContext from '../provider/UneeqContext'
import { Box } from 'rebass'
import { useUneeqState, useDimensions, useResizeObserver } from '../hooks'
import { isMobileSafari } from 'react-device-detect'

const measureVideoWidth = (
  width: number,
  height: number,
  avatarAspect: number,
  avatarPosition: number
) => {
  let videoWidth = 0
  let shift = 0

  videoWidth = height * avatarAspect // full width of the video at this height
  const avatarLeft = videoWidth * avatarPosition // where the avatar is within video
  const center = width / 2 // the center of the frame - where we want the avatar to be
  shift = Math.min(0, center - avatarLeft) // How far to shift the video over to center
  videoWidth += Math.max(0, width - videoWidth - shift) // If remains a black space in the right we zoom in to fill it with the video.

  return [videoWidth, shift]
}

const UneeqAvatar = React.memo(
  ({ sx, shouldUseDynamicWidth, ...props }: any) => {
    const { config } = useContext(UneeqContext)
    const { hiddenUI, typeModeFromBackend } = useUneeqState()
    const { setAvatarVideo } = useContext(UneeqContext)
    const [{ videoWidth, shift }, setVideoSize] = useState({
      videoWidth: 0,
      shift: 0
    })
    const updateVideoDimensions = useCallback(
      (width, height) => {
        if (width && height && shouldUseDynamicWidth) {
          const [calculatedVideoWidth, calculatedShift] = measureVideoWidth(
            width,
            height,
            config.avatarAspect,
            config.avatarPosition
          )

          // Each time we update the size of the DH, the resize observer will trigger an event
          // We should do this check to avoid infinite loops
          if (
            videoWidth !== calculatedVideoWidth &&
            shift !== calculatedShift
          ) {
            setVideoSize({
              videoWidth: calculatedVideoWidth,
              shift: calculatedShift
            })
          }
        }
      },
      [
        config.avatarAspect,
        config.avatarPosition,
        shift,
        shouldUseDynamicWidth,
        videoWidth
      ]
    )
    const [heightRef, { width }] = useDimensions()

    const [resizeRef] = useResizeObserver((entries: any) => {
      const entry = entries[0]
      if (entry) {
        const { width, height } = entry.contentRect
        updateVideoDimensions(width, height)
      }
    })

    const setRefs = (ref: React.ReactElement) => {
      setAvatarVideo(ref)
      heightRef(ref)
      resizeRef(ref)
    }

    return (
      <Box
        {...props}
        id="avatar"
        sx={{
          label: 'avatar',
          height: '100%',
          width: '118%',
          '& video': {
            minWidth: videoWidth,
            marginLeft: shift,
            mt: hiddenUI
              ? isMobileSafari
                ? typeModeFromBackend
                  ? 0
                  : width < 768
                  ? ['50%', '50%', '50%', '50%', 0, 0]
                  : ['30%', '30%', '30%', '30%', 0, 0]
                : 0
              : {}
          },
          ...sx
        }}
        ref={setRefs}
      />
    )
  }
)

export default UneeqAvatar
