import React from 'react'
import { Box, Flex, Heading, Text } from 'rebass'
import { useHighlightedCard } from '../../app/hooks/useHighlightedCard'
import { Divider } from './RedUI'

interface LabResultProps {
  labResult: CompletedLabResultType
}

interface CompletedLabResultType {
  date_of_test: string
  lab_test: {
    _id: string
    title: string
    description: string
    description_html: string
    shortDescription: string
    contentType: string
    tag: string
  }
}

const CompletedLabResult = ({ labResult }: LabResultProps) => {
  const isHiglighted = useHighlightedCard(
    labResult.lab_test.description,
    labResult.lab_test.title
  )

  return (
    <Flex variant={isHiglighted ? 'card' : 'fadedCard'}>
      <Box>
        <Heading>{labResult.lab_test.title}</Heading>
      </Box>
      <Divider />
      <Text
        variant="contentSmall"
        sx={{ pb: [6, 6, 6, 6, 12, 12] }}
        dangerouslySetInnerHTML={{
          __html: labResult.lab_test.description_html
        }}
      />
    </Flex>
  )
}

export default CompletedLabResult
