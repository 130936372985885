export const styles = {
  container: {
    label: 'SetupCompleted-container',
    height: '100%',
    alignItems: 'center'
  },
  success: {
    padding: 12,
    label: 'SetupCompleted-success',
    bg: 'background',
    width: '40%',
    minWidth: '345px',
    height: 'min-content',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    mx: 'auto',
    border: 'default'
  },
  successIcon: {
    width: '56px',
    height: '56px',
    marginBottom: 34
  },
  title: {
    label: 'SetupCompleted-title',
    fontSize: '32px',
    fontWeight: 600,
    textAlign: 'center',
    color: 'text'
  },
  startButton: {
    label: 'SetupCompleted-startButton',
    height: 50,
    width: '100%'
  }
}
