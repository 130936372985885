import { Input, Label, Select } from '@rebass/forms'
import { differenceInYears } from 'date-fns'
import React from 'react'
import { Controller } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { Flex } from 'rebass'
import { ReactComponent as ExclamationIcon } from '../assets/icons/exclamation.svg'
import { styles } from './accountSetupStyles'
import { Datepicker } from './Datepicker'

export const FamilyMemberForm = ({
  index,
  value,
  register,
  control,
  errors,
  trigger,
  isEditingPatient
}) => {
  const { t } = useTranslation()
  const localErrors = errors.family?.[index] || null

  const errorStyle = {
    border: 'error',
    borderBottomLeftRadius: 8,
    borderBottomRightRadius: 8
  }

  return (
    <Flex
      sx={{
        ...styles.card,
        ...(localErrors && errorStyle)
      }}
    >
      <Flex sx={styles.inputsContainer}>
        <Flex sx={styles.inputContainer}>
          <Input
            {...register(`family.${index}._id`)}
            id={`family[${index}]_id`}
            sx={{ display: 'none' }}
          />
          <Label htmlFor={`family[${index}]firstName`} sx={styles.inputLabel}>
            {t('AccountSetup.firstName')}
          </Label>
          <Input
            id={`family[${index}]firstName`}
            className={`form-input ${
              localErrors?.firstName ? 'errorInput' : ''
            }`}
            {...register(`family.${index}.firstName`, {
              required: t('AccountSetup.validation.firstName')
            })}
          ></Input>
        </Flex>
        <Flex htmlFor={`family[${index}]lastName`} sx={styles.inputContainer}>
          <Label sx={styles.inputLabel}>{t('AccountSetup.lastName')}</Label>
          <Input
            id={`family[${index}]lastName`}
            className={`form-input ${
              localErrors?.lastName ? 'errorInput' : ''
            }`}
            {...register(`family.${index}.lastName`, {
              required: t('AccountSetup.validation.lastName')
            })}
          ></Input>
        </Flex>
        <Flex sx={styles.inputContainer}>
          <Label htmlFor={`family[${index}]dateOfBirth`} sx={styles.inputLabel}>
            {t('AccountSetup.dateOfBirth')}
          </Label>
          {/* TODO validate just owner age  */}
          <Controller
            name={`family.${index}.dateOfBirth`}
            control={control}
            rules={{
              required: t('AccountSetup.validation.dateOfBirth.required'),
              ...(index <= 0 && {
                validate: {
                  olderThan18: value =>
                    differenceInYears(new Date(), new Date(value)) > 18 ||
                    t('AccountSetup.validation.dateOfBirth.older')
                }
              })
            }}
            render={({ field: { onChange, onBlur, value } }) => (
              <Datepicker
                onChange={value => {
                  onBlur()
                  onChange(value)
                }}
                date={value ? new Date(value) : ''}
                error={!localErrors?.dateOfBirth}
              />
            )}
          />
        </Flex>
        <Flex sx={styles.inputContainer}>
          <Label htmlFor={`family[${index}]sex`} sx={styles.inputLabel}>
            {t('AccountSetup.sex')}
          </Label>
          <Select
            id={`family[${index}]sex`}
            className={`form-input ${localErrors?.sex ? 'errorInput' : ''}`}
            {...register(`family.${index}.sex`, {
              required: t('AccountSetup.validation.sex')
            })}
          >
            <Flex as="option" value="female" key="female">
              {t('AccountSetup.sexOptions.female')}
            </Flex>
            <Flex as="option" value="male" key="male">
              {t('AccountSetup.sexOptions.male')}
            </Flex>
            <Flex as="option" value="other" key="other">
              {t('AccountSetup.sexOptions.other')}
            </Flex>
          </Select>
        </Flex>
        <Flex sx={styles.inputContainer}>
          <Label htmlFor={`family[${index}]email`} sx={styles.inputLabel}>
            {t('AccountSetup.email')}
          </Label>
          <Input
            id={`family[${index}]email`}
            className={`form-input ${isEditingPatient &&
              index <= 0 &&
              'disabled'} ${localErrors?.email ? 'errorInput' : ''}`}
            disabled={isEditingPatient && index <= 0} // For other family members, just disable the input for the owner of the account
            type="email"
            {...register(`family.${index}.email`, {
              required:
                index <= 0
                  ? t('AccountSetup.validation.email.required')
                  : false,
              pattern: {
                value: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                message: t('AccountSetup.validation.email.invalid')
              }
            })}
          ></Input>
        </Flex>
        <Flex sx={styles.inputContainer}>
          <Label
            htmlFor={`family[${index}]phoneNumber`}
            sx={styles.inputLabel}
            className={index <= 0 && 'disabled'}
          >
            {t('AccountSetup.phoneNumber')}
          </Label>
          {/* TODO: is it necessary to change it to be a controlled input of
          react-phone-number-input? */}
          <Input
            id={`family[${index}]phoneNumber`}
            className={`form-input ${
              localErrors?.phoneNumber ? 'errorInput' : ''
            }`}
            disabled={index <= 0} // For other family members, just disable the input for the owner of the account
            {...register(`family.${index}.phoneNumber`, {
              required:
                index <= 0
                  ? t('AccountSetup.validation.phoneNumber.required')
                  : false,
              value: value.phoneNumber,
              pattern: {
                value: /^[0-9\+]+$/,
                message: t('AccountSetup.validation.phoneNumber.invalid')
              }
            })}
          ></Input>
        </Flex>
      </Flex>
      {localErrors && (
        <Flex sx={styles.errorsContainer}>
          <Label sx={styles.errorLabel}>
            <ExclamationIcon />
            {t('AccountSetup.error', {
              count: Object.keys(localErrors).length
            })}
          </Label>
          {Object.values(localErrors).map((error, index) => (
            <Label sx={styles.error} key={index}>
              {error.message}
            </Label>
          ))}
        </Flex>
      )}
    </Flex>
  )
}
