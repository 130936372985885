import React, { useEffect } from 'react'
import { Box, Flex, Heading, Text } from 'rebass'
import { useHighlightedSection } from '../../../app/hooks/useHighlightedSection'
import { AppointmentType } from '../../../app/types/dischargeCarePlan'
import { Divider } from '../RedUI'

interface AppointmentProps {
  appointment: AppointmentType
  index: number
}

export const Appointment = ({
  appointment,
  apptColour,
  index
}: AppointmentProps) => {
  const {
    doctor,
    doctor_other,
    date_of_appointment,
    speciality,
    time_of_appointment
  } = appointment
  const { cellToHighlight, row } = useHighlightedSection(index)

  // If the name of speciality is empty, we need to check over speciality_other
  // if it is there
  const appointmentReason = speciality
    ? speciality.name
    : appointment.speciality_other || null

  useEffect(() => {
    if (cellToHighlight === `${row}_column_1`) {
      const apptElement = document.getElementById('appointment-card')
      apptElement?.scrollIntoView({
        behavior: 'smooth',
        block: 'end',
        inline: 'nearest'
      })
    }
  }, [cellToHighlight, row])

  return (
    <Flex
      id="appointment-card"
      variant="appointmentCard"
      sx={{
        background: apptColour
      }}
    >
      <Heading variant="headingLarge">
        <Box
          variant={
            cellToHighlight === `${row}_column_3` ? 'activeSpokenContent' : ''
          }
        >
          {date_of_appointment} · {time_of_appointment}
        </Box>
      </Heading>

      <Divider />
      <Box variant="simpleGrid">
        <Box
          variant={
            cellToHighlight === `${row}_column_2` ? 'activeSpokenContent' : ''
          }
        >
          <Text variant="label">Doctor:</Text>
          <Text variant="content">
            {doctor
              ? `${doctor.Fname ?? ''} ${doctor.Lname ?? ''}`
              : doctor_other}
          </Text>
        </Box>
        <Box
          variant={
            cellToHighlight === `${row}_column_1` ? 'activeSpokenContent' : ''
          }
        >
          <Text variant="label">Reason:</Text>
          <Text variant="content">{appointmentReason}</Text>
        </Box>
        <Box
          variant={
            cellToHighlight === `${row}_column_4` ? 'activeSpokenContent' : ''
          }
        >
          <Text variant="label">Where:</Text>
          <Text variant="content">{appointment.address}</Text>
        </Box>
        {!!appointment.contact_number && (
          <Box
            variant={
              // TODO: there's a bug on the script - row_1_column_5
              cellToHighlight === `${row}_column_5` ? 'activeSpokenContent' : ''
            }
          >
            <Text variant="label">Contact the office:</Text>
            <Text variant="content">Call {appointment.contact_number}</Text>
          </Box>
        )}
      </Box>
    </Flex>
  )
}
