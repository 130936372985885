import { motion } from 'framer-motion'
import React, {
  Dispatch,
  SetStateAction,
  createElement,
  useContext,
  useState
} from 'react'
import { isMobileOnly } from 'react-device-detect'
import { Box, Button, Flex, Text } from 'rebass'
import { UneeqContext, useUneeqState } from 'uneeq-react-core'
import { useSessionContext } from '../../app/hooks/useSessionContext'
import useWidgetContext from '../../app/hooks/useWidgetContext'
import LoadingSpinner from '../LoadingSpinner/LoadingSpinner'
import MayaBackButton from '../MayaBackButton/MayaBackButton'
import MayaFullscreenButton from '../MayaFullScreenButton/MayaFullscreenButton'
import MayaHomeButton from '../MayaHomeButton/MayaHomeButton'
import { MayaNextButton } from '../MayaNextButton'
import TranscriptButton from '../MayaTranscript/TranscriptButton'
import RestartSession from '../RestartSession/RestartSession'
import AutocompleteQuestion from './questionTypes/AutocompleteQuestion'
import CountryQuestion from './questionTypes/CountryQuestion'
import DateQuestion from './questionTypes/DateQuestion'
import MultiSelectQuestion from './questionTypes/MultiSelectQuestion'
import SelectQuestion from './questionTypes/SelectQuestion'
import TextQuestion from './questionTypes/TextQuestion'
import TextareaQuestion from './questionTypes/TextareaQuestion'
import styles from './styles'
// @ts-ignore
import marksy from 'marksy'
import { ReactComponent as CCOffIcon } from '../../app/assets/icons/cc-outline.svg'
import { ReactComponent as CCOnIcon } from '../../app/assets/icons/cc-solid.svg'
import { useDomainConfigContext } from '../../app/hooks/useDomainConfigContext'
import { useShouldShowButtons } from '../../app/hooks/useShouldShowButtons'
import MayaRepeatButton from '../MayaRepeatButton/MayaRepeatButton'

const compile = marksy({ createElement })

export const markdownQuestion = (text: any) => compile(text).tree

interface QuestionsProps {
  mayaQuestionFormat:
    | 'text'
    | 'number'
    | 'textarea'
    | 'date'
    | 'select'
    | 'multiSelect'
    | 'country'
    | 'autocomplete'
    | 'email'
    | 'phone'
    | 'table'
}

const LoadingIndicator = React.memo(() => {
  const MotionContainer = motion.custom(Flex)

  return (
    <MotionContainer
      variants={{
        initial: {
          opacity: 0,
          transition: {
            delay: 3
          }
        },
        animate: {
          opacity: 1
        },
        exit: {
          opacity: 0,
          transition: {
            delay: 3
          }
        }
      }}
      initial="initial"
      animate="animate"
      exit="exit"
      variant="questionsLoader"
    >
      <LoadingSpinner variant="questionsSpinner" />
    </MotionContainer>
  )
})

export interface AnimatedBoxProps {
  scrollable?: boolean
  children: React.ReactNode
}

const isMayaRed = true // TODO: config

export const AnimatedBox = ({
  children,
  scrollable = true
}: AnimatedBoxProps) => {
  const MotionContainer = motion.custom(Box)
  const animation = {
    initial: {
      opacity: 0,
      transition: {
        delay: 3
      }
    },
    animate: {
      opacity: 1
    },
    exit: {
      opacity: 0,
      transition: {
        delay: 3
      }
    }
  }

  return (
    <MotionContainer
      variants={animation}
      initial="initial"
      animate="animate"
      exit="exit"
      type="text"
      sx={{
        label: 'AnimatedBox',
        overflowY: scrollable ? 'auto' : 'initial',
        overflowX: 'hidden',
        ...(isMayaRed ? { display: 'flex', alignItems: 'center' } : {})
      }}
    >
      {children}
    </MotionContainer>
  )
}

const Questions = React.memo(({ mayaQuestionFormat }: QuestionsProps) => {
  console.log('mayaQuestionFormat', mayaQuestionFormat)
  switch (mayaQuestionFormat) {
    case 'text':
      return (
        <AnimatedBox>
          <TextQuestion type="text" />
        </AnimatedBox>
      )
    case 'number':
      return (
        <AnimatedBox>
          <TextQuestion type="number" />
        </AnimatedBox>
      )
    case 'email':
      return (
        <AnimatedBox>
          <TextQuestion type="email" />
        </AnimatedBox>
      )
    case 'phone':
      return (
        <AnimatedBox>
          <TextQuestion type="tel" />
        </AnimatedBox>
      )
    case 'textarea':
      return (
        <AnimatedBox>
          <TextareaQuestion />
        </AnimatedBox>
      )
    case 'date':
      return (
        <AnimatedBox scrollable={false}>
          <DateQuestion />
        </AnimatedBox>
      )
    case 'select':
      return (
        <AnimatedBox>
          <SelectQuestion />
        </AnimatedBox>
      )
    case 'multiSelect':
      return (
        <AnimatedBox>
          <MultiSelectQuestion />
        </AnimatedBox>
      )
    case 'country':
      return (
        <AnimatedBox>
          <CountryQuestion />
        </AnimatedBox>
      )
    case 'autocomplete':
      return <AutocompleteQuestion />
    default:
      return null
  }
})

interface CCButtonProps {
  show: boolean
  toggle: Dispatch<SetStateAction<boolean>>
}

const ToggleCloseCaptionsButton = ({ show, toggle }: CCButtonProps) => {
  return (
    <Button variant="unstyled" onClick={() => toggle(!show)}>
      {show ? <CCOnIcon /> : <CCOffIcon />}
    </Button>
  )
}

interface QuestionProps {
  speak?: boolean
}

const Question = ({ speak }: QuestionProps) => {
  const {
    mayaQuestion,
    sessionPaused,
    questionLoading,
    showMap,
    hideQuestion,
    linesToSpeak
  } = useUneeqState()
  const { dispatch } = useContext(UneeqContext)
  const { widgetMode } = useWidgetContext()
  const {
    state: { showRepeatButton }
  } = useDomainConfigContext()
  const {
    state: { dischargeCarePlan }
  } = useSessionContext()
  const { skippedData } = dischargeCarePlan

  const shouldShowButtons = useShouldShowButtons()

  const [showClosedCaption, setShowClosedCaption] = useState(true)

  const currentLine = linesToSpeak?.find((line: any) => line?.currentlySpeaking)

  if (!mayaQuestion || hideQuestion) return null
  const questionsContainerStyle =
    mayaQuestion.format === 'autocomplete'
      ? styles.questionAutocompleteContainer
      : styles.questionsContainer

  const toggleDigitalHuman = () => {
    sessionPaused ? resumeDigitalHuman() : pauseDigitalHuman()
  }

  const pauseDigitalHuman = () => {
    dispatch({ type: 'pauseSession' })
  }

  const resumeDigitalHuman = () => {
    dispatch({ type: 'resumeSession' })
  }

  const restart = () => {
    dispatch({ type: 'mayaOpenConfirmRestart' })
  }

  const showSpecialButtons =
    skippedData[mayaQuestion.id] && skippedData[mayaQuestion.id].specialButtons

  if (showMap) return null

  if (skippedData[mayaQuestion.id] && skippedData[mayaQuestion.id].shouldSkip)
    return null

  return (
    <>
      {isMayaRed ? (
        <Flex variant="questionsWrapper">
          <Flex variant="questionsNavWrapper" sx={{ alignItems: 'flex-end' }}>
            {!mayaQuestion.hideBack && (
              <MayaBackButton iterateBack={showSpecialButtons} />
            )}

            {currentLine?.speak && showClosedCaption && (
              <Flex variant="mayaCaptionWrapper" className="captions-on">
                <Text variant="mayaCaption">
                  {currentLine?.speak}
                  <ToggleCloseCaptionsButton
                    show={showClosedCaption}
                    toggle={setShowClosedCaption}
                  />
                </Text>
              </Flex>
            )}
            {!showClosedCaption && (
              <>
                <Flex sx={{ flex: 1 }} />
                <Flex variant="mayaCaptionWrapper" className="captions-off">
                  <ToggleCloseCaptionsButton
                    show={showClosedCaption}
                    toggle={setShowClosedCaption}
                  />
                </Flex>
              </>
            )}
            {currentLine?.speak && showSpecialButtons && <MayaNextButton />}
          </Flex>

          <Flex variant="questionContainer">
            {questionLoading && <LoadingIndicator />}

            <Flex
              variant="questionsNavWrapper"
              sx={{ opacity: questionLoading ? '0.9' : '1' }}
            >
              {shouldShowButtons && showRepeatButton && (
                <MayaRepeatButton disabled={!!currentLine?.speak} />
              )}

              <Questions mayaQuestionFormat={mayaQuestion.format} />
            </Flex>
          </Flex>
        </Flex>
      ) : (
        <Flex variant="questionContainer" sx={questionsContainerStyle}>
          {questionLoading && <LoadingIndicator />}
          <Questions mayaQuestionFormat={mayaQuestion.format} />

          <Flex sx={styles.questionsFooter}>
            {!mayaQuestion.hideBack ? <MayaBackButton /> : <div>&nbsp;</div>}
            <Flex sx={styles.questionsFooterActions}>
              {widgetMode && !isMobileOnly && <MayaFullscreenButton />}
              <TranscriptButton />
              {speak && (
                <Button
                  id="dh-toggle"
                  onClick={toggleDigitalHuman}
                  variant="option"
                >
                  <span
                    style={{
                      marginRight: '8px'
                    }}
                  >
                    &bull;
                  </span>{' '}
                  {sessionPaused ? 'Maya' : 'Chat'}
                </Button>
              )}
              {mayaQuestion.showRestart && <RestartSession onClick={restart} />}
              <MayaHomeButton
                onClick={() => dispatch({ type: 'mayaOpenConfirmLeave' })}
              />
            </Flex>
          </Flex>
        </Flex>
      )}
    </>
  )
}

export default React.memo(Question)
