import { dischargeCarePlanType } from '../../app/types/dischargeCarePlan'
import {
  getInitialDischargeQuestionData,
  questionIdsToSections,
  quizQuestions,
  responsesWithNurseActionRequired
} from './questionResponseReducerHelper'

// NOTE: this is how they're taking it from the API - will be better to have consistency
// questionsAnswers -> expects camelCase
// rest dischargeQuestionData -> expects snake_case
const updateQuestionRepeatedTimes = (responseInfo: any, currentState: any) => {
  const { questionId, id } = responseInfo
  const idToUse = questionId || id // It changes where the id is retrieved, if its a "response" or "question"

  // First update the questionsAnswers array with increasing the question repeated times
  const questionsAnswersUpdated = currentState?.dischargeQuestionData?.questionsAnswers?.map(
    qa =>
      qa.questionId === idToUse
        ? {
            ...qa,
            repeatedTimes: qa.repeatedTimes + 1
          }
        : qa
  )

  let newQuestionResponse = {
    ...currentState,
    dischargeQuestionData: {
      ...currentState.dischargeQuestionData,
      // update question answers
      questionsAnswers: questionsAnswersUpdated
    }
  }

  const questionSection = questionIdsToSections[idToUse]

  if (questionSection) {
    newQuestionResponse.dischargeQuestionData[
      questionSection.label
    ] = currentState?.dischargeQuestionData[questionSection.label].map(
      (item: any) => ({
        ...item,
        repeated_times: item.repeated_times + 1
      })
    )
  }

  return newQuestionResponse
}

const updateQuestionResponse = (responseInfo: any, currentState: any) => {
  const { questionId, response, label } = responseInfo

  const questionsAnswersUpdated = currentState?.dischargeQuestionData?.questionsAnswers?.map(
    qa =>
      qa.questionId === questionId
        ? {
            ...qa,
            responseId: response,
            responseText: label,
            questionText: currentState.stringToSpeak,
            // We have to let know the nurse know when the patient has answered a wrong question
            // Or particular questions that requires nurse action
            nurseActionRequired: quizQuestions[questionId]
              ? quizQuestions[questionId][response] === 'Incorrect'
              : responsesWithNurseActionRequired.includes(response),
            // Quiz variables
            answeredCorrectly: quizQuestions[questionId]
              ? quizQuestions[questionId][response] === 'Correct'
              : null,
            correctAnswer: label,
            correctAnswerId:
              quizQuestions[questionId] &&
              Object.keys(quizQuestions[questionId]).find(
                (key, index) => quizQuestions[questionId][key] === 'Correct'
              )
          }
        : qa
  )

  let newQuestionResponse = {
    ...currentState,
    // Reset the lines to speak until they are again generated for this upcoming question
    linesToSpeak: [],
    dischargeQuestionData: {
      ...currentState.dischargeQuestionData,
      // update question answers
      questionsAnswers: questionsAnswersUpdated
    }
  }

  const questionSection = questionIdsToSections[questionId]

  if (questionSection) {
    newQuestionResponse.dischargeQuestionData[
      questionSection.label
    ] = currentState?.dischargeQuestionData[questionSection.label].map(
      (item: any) => ({
        ...item,
        dh_status: questionSection[response]
      })
    )
  }

  return newQuestionResponse
}

const addQuestionResponse = (responseInfo: any, currentState: any) => {
  const { id: questionId, question } = responseInfo

  // First we check if the question hasn't been added before
  // If that's the case, we don't want to add it again and we want to increase the repeated times
  // This scenario is reached when we hit "back" button
  const findQuestionAdded = currentState?.dischargeQuestionData?.questionsAnswers?.find(
    qa => qa.questionId === questionId
  )

  if (findQuestionAdded)
    return updateQuestionRepeatedTimes(responseInfo, currentState)

  const newQuestionAnswer = {
    questionId,
    questionText: question,
    repeatedTimes: 0,
    responseId: null,
    responseText: null,
    quizStatus: quizQuestions[questionId] ? 'In Progress' : null,
    isQuiz: quizQuestions[questionId] ? true : false
    // TODO: if quiz, and incorrect answer, add correct answer (we should send all
    // that from 'response' message probably and get it from responseInfo (the action payload))
  }

  let newQuestionResponse = {
    ...currentState,
    // Reset the lines to speak until they are again generated for this upcoming question
    linesToSpeak: [],
    dischargeQuestionData: {
      ...currentState.dischargeQuestionData,
      // add new question answer
      questionsAnswers: [
        ...currentState?.dischargeQuestionData?.questionsAnswers,
        newQuestionAnswer
      ]
    }
  }

  const questionSection = questionIdsToSections[questionId]

  if (questionSection) {
    newQuestionResponse.dischargeQuestionData[
      questionSection.label
    ] = currentState?.dischargeQuestionData[questionSection.label].map(
      (item: any) => ({
        ...item,
        dh_status: 'In Progress'
      })
    )
  }

  return newQuestionResponse
}

// STATES:
// 1. Question is reached: we need to add it to the data, with the 'question' case, without response status. Needed for when the user hits "repeat" and we haven't saved a response yet. Initial status "In progress"
// 2. Question is repeated with "repeat" button: 'updateQuestionRepeatCount'
// 3. Question is repeated with "back" button : 'question' case
// 2. Question is responded: 'response' case. Can take two states "Completed" or "Skipped"

const questionResponseReducer = (
  dischargeCarePlan: dischargeCarePlanType,
  config?: any
) => (state: any, action: any) => {
  switch (action.type) {
    case 'updateQuestionRepeatCount':
      return updateQuestionRepeatedTimes(action?.payload, state)
    case 'mayaMessage':
      switch (action?.payload?.type) {
        case 'question':
          const { dischargeQuestionData } = state
          // If the first question has already been added, don't initialize it again the data
          // This step is needed for when we hit "back" button for the first question
          const isQuestionAdded = dischargeQuestionData?.questionsAnswers?.find(
            qa => qa.questionId === 'question#24036'
          )

          // Just on the first question get initial data
          if (!isQuestionAdded) {
            // on the initial question, we populate the QuestionData with all the sections
            return {
              ...state,
              dischargeQuestionData: getInitialDischargeQuestionData(
                dischargeCarePlan,
                action?.payload
              )
            }
          }

          return addQuestionResponse(action?.payload, state)
        case 'response':
          return updateQuestionResponse(action?.payload, state)
        default:
          return state
      }
    default:
      return state
  }
}

export default questionResponseReducer
