import { Label } from '@rebass/forms'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Box, Button, Image, Text } from 'rebass'
import { useSessionContext } from '../../hooks/useSessionContext'

import { Divider } from '../../../customPlugins/DischargeFlow/RedUI'
import { AuthContent } from '../UI/AuthLayout'
import { CustomCheckbox } from '../UI/CustomCheckbox'

type PatientStartProps = {
  onContinue: () => void
  authLogo?: string
}

export const PatientStart = ({ onContinue, authLogo }: PatientStartProps) => {
  const {
    state: { dischargeCarePlan }
  } = useSessionContext()
  const { patient } = dischargeCarePlan
  const { t } = useTranslation()
  const [canContinue, setCanContinue] = useState(false)

  return (
    <AuthContent>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between'
        }}
      >
        <Box>
          <Text variant="label">{t('PatientStart.patientNameTitle')}</Text>
          <Text variant="heading">
            {patient.first_name} {patient.last_name}
          </Text>
        </Box>
        {authLogo && (
          <Image
            variant="inlineLogo"
            src={authLogo}
            alt="Authentication page logo"
          />
        )}
      </Box>

      <Divider />
      <Box>
        <Text variant="label">{t('PatientStart.requiredTitle')}</Text>
        <Label sx={{ gap: 2, alignItems: 'center' }}>
          <CustomCheckbox onChange={() => setCanContinue(!canContinue)} />
          <Text variant="contentMedium">{t('PatientStart.checkboxLabel')}</Text>
        </Label>
      </Box>
      <Box>
        <Button
          onClick={onContinue}
          disabled={!canContinue}
          mb={3}
          sx={{ width: '100%' }}
        >
          {t('PatientStart.continue')}
        </Button>
        <Text variant="contentSmall" textAlign="center">
          {t('PatientStart.disclaimerText', { patient })}
        </Text>
      </Box>
    </AuthContent>
  )
}
