export const styles = {
  loaderVideo: {
    position: 'absolute',
    width: '100vw',
    height: '100vh',
    objectFit: 'cover',
    zIndex: 10
  },
  loaderVideoWidget: {
    position: 'static',
    width: '100%',
    height: '100%',
    objectFit: 'cover',
    zIndex: 10,
    borderRadius: ['unset', 'unset', 'unset', 'unset', 'card', 'card'],
    overflow: 'hidden'
  },
  loading: {
    container: {
      height: '100%',
      width: '100%',
      alignItems: 'flex-end',
      justifyContent: 'center',
      position: 'absolute',
      top: 0,
      left: 0
    },
    videoContainer: {
      alignItems: 'center',
      flexDirection: 'column',
      justifyContent: 'center',
      color: 'white',
      '& video': {
        width: ['100%', '100%', '60%', '60%', '60%', '60%'],
        height: ['100%', '100%', '60%', '60%', '60%', '60%']
      }
    }
  },
  avatarUnavailable: {
    icon: {
      mb: 7,
      display: 'block',
      color: 'warning'
    },
    heading: {
      display: 'inline-flex',
      width: 'fit-content',
      fontSize: 5
    },
    message: {
      my: 8,
      fontSize: 2
    }
  }
}
export default styles
