import React from 'react'
import { Box } from 'rebass'

type LayoutProps = {
  data: Array<string>
}

const ContentList = ({ data }: LayoutProps) => {
  return (
    <Box variant="contentList">
      <Box as="ul">
        {data.map(item => (
          <Box as="li">{item}</Box>
        ))}
      </Box>
    </Box>
  )
}

export default ContentList
