import React from 'react'
import { Button, Flex, Image } from 'rebass'
//TODO: replace with something passed for theme?

type LayoutProps = {
  back?: React.MouseEventHandler<HTMLButtonElement>
  authLogo?: string
  children?: React.ReactNode
}

const AuthLayout = ({ children, authLogo }: LayoutProps) => {
  return (
    <Flex
      sx={{
        width: '100%',
        height: '100%',
        alignContent: 'center',
        alignItems: 'center',
        justifyContent: 'center',
        label: 'AuthLayout'
      }}
    >
      <Flex
        sx={{
          label: 'CenteredContent',
          maxWidth: ['100%', '100%', '100%', '100%', '700px', '700px'],
          alignContent: 'center',
          flexDirection: 'column',
          width: '100%'
        }}
      >
        <Flex
          sx={{
            flex: [1, 1, 1, 1, 0, 0],
            alignItems: 'center',
            justifyContent: 'center',
            px: [6, 6, 6, 6, 6, 6]
          }}
        >
          {authLogo && (
            <Image
              sx={{
                pt: [6, 6, 6, 6, 6, 12]
              }}
              variant="authLogo"
              src={authLogo}
              alt="Authentication page logo"
            />
          )}
        </Flex>

        {children}
      </Flex>
    </Flex>
  )
}

type ContentProps = {
  back?: React.MouseEventHandler<HTMLButtonElement>
  children?: React.ReactNode
}

const AuthContent = ({ back, children }: ContentProps) => {
  return (
    <Flex
      variant="card"
      p={11}
      bg="greyLight"
      sx={{
        width: '100%',
        gap: 11,
        flexDirection: 'column'
      }}
    >
      {back && <Button>back..</Button>}
      {children}
    </Flex>
  )
}

export { AuthContent }
export default AuthLayout
