import { useEffect, useState } from 'react'
import { generateOTPEmailCode } from '../api/generateOTPEmailCode'
import { StorageKeys } from '../types/StorageKeys'

const updateSiteMetadata = (setAuthLogo, metadata) => {
  const { organizationLogo, organizationName } = metadata
  const link = document.querySelector<HTMLLinkElement>('link[rel="icon"]')
  if (link) {
    link.href = organizationLogo
    document.title = organizationName
  }
  setAuthLogo(organizationLogo)
}

export const useAuthentication = (
  id,
  domainConfigLoading,
  onRegisteredUserLogin,
  setAuthLogo
) => {
  const [email, setEmail] = useState<string | undefined>(undefined)
  const [error, setError] = useState<string>(undefined)

  const sendOTPEmailWithCode = async () => {
    try {
      const result = await generateOTPEmailCode(id)
      setEmail(result.email)
      updateSiteMetadata(setAuthLogo, result)
    } catch (e) {
      console.error('User id does not exist', e.message)
      setError(e.message)
    }
  }

  useEffect(() => {
    // If the ID is set, we need to validate the account with the email
    if (id && !domainConfigLoading) {
      const now = new Date()

      // We have the information saved on our localStorage
      // The backend doesn't provide us a way to save a session -- maybe we can request it?
      const dischargeCarePlanFromLS = localStorage.getItem(
        StorageKeys.API_DISCHARGE_CARE_PLAN_DATA
      )

      // If the data is not retrieved, return null and generate code
      if (!dischargeCarePlanFromLS) {
        return sendOTPEmailWithCode()
      }

      const dischargeCarePlanFromLSParsed = JSON.parse(dischargeCarePlanFromLS)

      // If the data exists, but we're getting a new patient, delete old one and generate new
      if (dischargeCarePlanFromLSParsed?.patientId !== id) {
        localStorage.removeItem(StorageKeys.API_DISCHARGE_CARE_PLAN_DATA)
        return sendOTPEmailWithCode()
      }

      // Compare the expiry time of the item with the current time
      if (now.getTime() > dischargeCarePlanFromLSParsed.expiry) {
        // If the item is expired, delete the data from storage
        // return null and generate code
        localStorage.removeItem(StorageKeys.API_DISCHARGE_CARE_PLAN_DATA)
        return sendOTPEmailWithCode()
      } else {
        // Retrieve organisation logo and name from login
        updateSiteMetadata(setAuthLogo, {
          organizationLogo:
            dischargeCarePlanFromLSParsed.value.discharge_cc.organization
              .organizationLogo,
          organizationName:
            dischargeCarePlanFromLSParsed.value.discharge_cc.organization
              .organization_name
        })
        // If the data is active, send the user to the home page
        onRegisteredUserLogin()
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, domainConfigLoading, onRegisteredUserLogin])

  return { email, error }
}
