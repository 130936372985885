import React from 'react'
import { Box, Flex, Heading, Text } from 'rebass'
import { Divider } from './RedUI'

interface ExerciseAndDietProps {
  item: ExerciseAndDietType
}

interface ExerciseAndDietType {
  title: string
  shortDescription: string
  description: string
  tag: string
}

const ExerciseAndDiet = ({ item }: ExerciseAndDietProps) => {
  const { title, description_html } = item

  return (
    <Flex variant="card">
      <Box>
        <Heading>{title}</Heading>
      </Box>
      <Divider />
      <Text
        variant="contentSmall"
        sx={{ pb: [6, 6, 6, 6, 12, 12] }}
        dangerouslySetInnerHTML={{ __html: description_html }}
      />
    </Flex>
  )
}

export default ExerciseAndDiet
