import React from 'react'
import { ContentList } from './RedUI'

const recommedationData = [
  'Take medicines and keep appointments.',
  'Involve family and/or caregivers.',
  'Ask questions!'
]

const ReccomendationList = () => {
  return <ContentList data={recommedationData} />
}

export default ReccomendationList
