import React from 'react'
import { useTranslation } from 'react-i18next'
import { Flex, Text } from 'rebass'
import { useHighlightedSection } from '../../../app/hooks/useHighlightedSection'
import { formatArrayNameIntoOneSentence } from '../../../utils'
import { MedCell } from './MedicationsTable'

import { ReactComponent as EveningIcon } from '../../../app/assets/icons/evening.svg'
import { ReactComponent as MorningIcon } from '../../../app/assets/icons/morning.svg'
import { ReactComponent as NightIcon } from '../../../app/assets/icons/night.svg'
import { ReactComponent as NoonIcon } from '../../../app/assets/icons/noon.svg'
interface MedicationTimeIconProps {
  medicineTime: string
}

const MedicationTimeIcon = ({ medicineTime }: MedicationTimeIconProps) => {
  switch (medicineTime) {
    case 'morning':
      return <MorningIcon />
    case 'noon':
      return <NoonIcon />
    case 'evening':
      return <EveningIcon />
    case 'night':
      return <NightIcon />
    default:
      return <div />
  }
}

interface MedicationTimesProps {
  medicineSchedule: Array<string>
}

const MedicationTimes = ({ medicineSchedule }: MedicationTimesProps) => {
  const { t } = useTranslation()

  return (
    <Flex sx={{ flexDirection: 'column', gap: 1 }}>
      {medicineSchedule.map(medicineTime => (
        <Flex variant="medicationTime">
          <MedicationTimeIcon medicineTime={medicineTime} />
          <Text>{t(`Medication.MedicationTimes.${medicineTime}`)}</Text>
        </Flex>
      ))}
    </Flex>
  )
}

export interface MedicationType {
  medicineName: string
  genericName: string
  reason: string
  dosage: { value: string }
  timesPerDay: number
  oldDosage: { value: string }
  status: string // TODO: change to boolean
  medicationRoute: string
  is_stopped: boolean
}

interface MedicationProps {
  medication: MedicationType
  index: number
}

export const MedicationRow = ({ medication, index }: MedicationProps) => {
  const { t } = useTranslation()
  const { isHighlightedSection, cellToHighlight, row } = useHighlightedSection(
    index
  )

  const reasonsToTakeMed = medication.reason_to_take_medicine.length
    ? formatArrayNameIntoOneSentence(medication.reason_to_take_medicine)
    : `-`

  return (
    <Flex
      variant="medicationsTableRow"
      id={row}
      style={{
        opacity: isHighlightedSection ? 1 : 0.4
      }}
    >
      <MedCell
        sx={{ flexBasis: '15%' }}
        activeSpokenContent={cellToHighlight === `${row}_column_1`}
      >
        <Text variant="contentSmall" sx={{ fontWeight: 'bold' }}>
          {medication.genericName && `${medication.genericName} / `}
          {medication.medicineName} {medication.dosage?.value}
        </Text>{' '}
        {medication.is_new_medicine && <Flex variant="pillNew">New</Flex>}
        {medication.is_change_in_dosage_or_frequency && (
          <Flex variant="pillChanged">Changed</Flex>
        )}
        {medication.is_stopped && (
          <Flex variant="pillStopped">{t('AHCP.medications.stopped')}</Flex>
        )}
      </MedCell>
      <MedCell activeSpokenContent={cellToHighlight === `${row}_column_2`}>
        <Text variant="contentSmall">{reasonsToTakeMed}</Text>
      </MedCell>
      <MedCell activeSpokenContent={cellToHighlight === `${row}_column_3`}>
        <Text variant="contentSmall">{medication.how_to_take ?? `-`}</Text>
      </MedCell>
      <MedCell activeSpokenContent={cellToHighlight === `${row}_column_4`}>
        <Text variant="contentSmall">
          {medication.medicineSchedule?.length > 0 ? (
            <MedicationTimes medicineSchedule={medication.medicineSchedule} />
          ) : (
            `-`
          )}
        </Text>
      </MedCell>

      <MedCell activeSpokenContent={cellToHighlight === `${row}_column_5`}>
        <Text variant="contentSmall">
          {medication.duration || medication.duration_unit
            ? `${medication.duration ?? ''} ${medication.duration_unit ?? ''}`
            : `-`}
        </Text>
      </MedCell>
    </Flex>
  )
}
