import { E164Number } from 'libphonenumber-js/types'
import React, {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useRef,
  useState
} from 'react'
import { useTranslation } from 'react-i18next'
import PhoneInput, { isPossiblePhoneNumber } from 'react-phone-number-input'
import 'react-phone-number-input/style.css'
import { Box, Button, Flex, Text } from 'rebass'
import { Recaptcha } from '../../../customPlugins/Recaptcha'
import { generateCode } from '../../api/authentication'
import { AuthContent } from '../UI/AuthLayout'
import { styles } from './stepsStyles'

type PhoneStepProps = {
  phone: E164Number | undefined
  setPhone: Dispatch<SetStateAction<E164Number | undefined>>
  onSuccess: () => void
  phoneLoginDefaultString?: string
}

export const PhoneStep = ({
  phone,
  setPhone,
  onSuccess,
  phoneLoginDefaultString
}: PhoneStepProps) => {
  const { t } = useTranslation()
  const [error, setError] = useState('')
  const [loading, setLoading] = useState(false)
  const [captchaToken, setCaptchaToken] = useState('')

  const onApproveCaptcha = useCallback(captchaToken => {
    setCaptchaToken(captchaToken)
  }, [])

  const requestCode = async () => {
    if (captchaToken) {
      setError('')

      if (!phone) {
        return setError(t('Authentication.missingPhone'))
      }

      if (!isPossiblePhoneNumber(phone.toString())) {
        return setError(t('Authentication.invalidPhoneNumber'))
      }

      setLoading(true)

      try {
        await generateCode(phone.toString(), captchaToken)
        setLoading(false)
        onSuccess()
      } catch (e) {
        // REMOVE: TEMP AS BACK IS NOT WORKING
        setLoading(false)
        console.log('generateCode ERROR', e)

        setError('Something went wrong, please try again later')
      }
    }
  }

  const submitOnEnter = (
    e: React.KeyboardEvent<HTMLInputElement>,
    submit: any
  ) => {
    if (e.code === 'Enter') {
      submit(e)
    }
  }

  useEffect(() => {
    // set phone later, otherwise the country flag on the input doesn't
    // show up. defaultCountry property on PhoneInput doesn't seem to work
    phoneLoginDefaultString && setPhone(phoneLoginDefaultString)
  }, [phoneLoginDefaultString, setPhone])

  // TODO: I don't think is possible to have PhoneInput and react-hook-form coexisting together
  return (
    <AuthContent>
      <Text variant="heading" sx={{ textAlign: 'center' }}>
        {t('Authentication.auth')}
      </Text>
      <Box variant="fakeInputWrapper">
        <PhoneInput
          autoFocus
          placeholder={t('Authentication.phonePlaceholder')}
          value={phone}
          onChange={setPhone}
          autoComplete="off"
          onKeyPress={(e: React.KeyboardEvent<HTMLInputElement>) =>
            submitOnEnter(e, requestCode)
          }
        />
      </Box>
      {error && <Text variant="errorText">{error}</Text>}
      <Box>
        <Recaptcha onSuccess={onApproveCaptcha} />
      </Box>

      <Button
        disabled={!phone || !captchaToken || loading}
        onClick={requestCode}
      >
        {loading ? t('Authentication.sending') : t('Authentication.next')}
      </Button>
    </AuthContent>
  )
}
