import React, { useContext } from 'react'
// @ts-ignore
import { Progress } from 'react-sweet-progress'
import 'react-sweet-progress/lib/style.css'
import { Button, Flex, Heading, Text, Box } from 'rebass'
import { trackHandler, UneeqContext, useTimeout } from 'uneeq-react-core'
import styles from './styles'
import { useTheme } from 'emotion-theming'
import { useTranslation } from 'react-i18next'
import { MayaOverlay } from '../MayaOverlay'
import QuestionMark from '../MayaIcons/QuestionMark'

const MayaTimeout = ({ endSession }: any) => {
  const theme: any = useTheme()
  const {
    config: { timeoutWarning }
  } = useContext(UneeqContext)
  const { timeoutOpen, timeLeft, resetTimeout } = useTimeout()
  const { t } = useTranslation()

  if (!timeoutOpen) return null

  const seconds = Math.max(Math.ceil(timeLeft / 1000), 0)
  const progress = 100 - (timeLeft / timeoutWarning) * 100

  return (
    <MayaOverlay>
      <Flex variant="modal">
        <Box variant="modalIconWrapper">
          <QuestionMark />
        </Box>
        <Box>
          <Heading variant="modalHeader">{t('Timeout.heading')}</Heading>
          <Text variant="modalText">{t('Timeout.subtitle')}</Text>
        </Box>

        <Flex sx={styles.progress}>
          <Text variant="modalText" pr={3}>
            {seconds}s
          </Text>
          <Box variant="progressContainer">
            <Progress
              percent={progress}
              theme={{
                active: {
                  trailColor: theme.colors.timeoutTrailColor,
                  color: theme.colors.timeoutColor
                }
              }}
            />
          </Box>
        </Flex>
        <Flex variant="modalButtons">
          <Button
            id="leave-chat"
            variant="secondary"
            onClick={trackHandler(endSession, 'timeout-finished-btn')}
          >
            {t('Timeout.leaveChat')}
          </Button>
          <Button
            id="back-chat"
            autoFocus
            variant="primary"
            onClick={trackHandler(resetTimeout, 'timeout-continue-session-btn')}
          >
            {t('Timeout.backToChat')}
          </Button>
        </Flex>
      </Flex>
    </MayaOverlay>
  )
}

export default MayaTimeout
