import { useUneeqState } from 'uneeq-react-core'
import { useUneeqContext } from '../../app/hooks/useUneeqContext'

const resetLinesToSpeak = (linesToSpeak: any) =>
  linesToSpeak.map((line: any, index: number) => ({
    ...line,
    currentlySpeaking: index === 0 ? true : false
  }))

export const useRepeatLinesToSpeak = () => {
  const { sessionPaused, linesToSpeak, stringToSpeak } = useUneeqState()
  const { dispatch, sendText } = useUneeqContext()

  if (!linesToSpeak) return null

  const linesToSpeakArray = resetLinesToSpeak(linesToSpeak)

  return () => {
    // make Maya speak the first line
    !sessionPaused &&
      linesToSpeakArray &&
      linesToSpeakArray[0] &&
      sendText(linesToSpeakArray[0].speak)

    setTimeout(
      () =>
        dispatch({
          type: 'linesToSpeakSet',
          payload: { linesToSpeak: linesToSpeakArray, stringToSpeak }
        }),
      100
    )
  }
}
