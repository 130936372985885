const questionResponseMiddleware = (config?: any) => async (
  state: any,
  action: any,
  uneeq: any
) => {
  if (
    (action?.type === 'mayaMessage' && action?.payload?.type === 'response') ||
    (action?.type === 'mayaMessage' && action?.payload?.type === 'exit') ||
    action?.type === 'updateQuestionRepeatCount'
  ) {
    const { dischargeQuestionData } = state

    const { inferences = [] } = action.payload

    const body = { ...dischargeQuestionData, inferences }

    if (dischargeQuestionData) {
      try {
        const res = await fetch(
          `${process.env.REACT_APP_BACKEND_URL}/updateCarePlan`,
          {
            method: 'POST',
            body: JSON.stringify(body),
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${process.env.REACT_APP_BACKEND_MAYARED_TOKEN}`
            }
          }
        )
        const bodyRes = await res.json()
        if (bodyRes.error) {
          throw new Error(
            bodyRes.error.response.error.message || res.statusText
          )
        }
        console.log(
          '/updateCarePlan API body response questionResponse',
          bodyRes
        )
        return bodyRes
      } catch (e) {
        throw e
      }
    }
  }
}

export default questionResponseMiddleware
