const styles = {
  content: {
    flexDirection: 'column',
    maxHeight: '95%',
    overflow: 'auto',
    position: 'relative'
  },
  column: {
    flexDirection: 'column'
  },
  divider: {
    background: '#C4C4C4',
    opacity: 0.2,
    width: '100%',
    height: '1px',
    mb: 8,
    mt: 3
  },
  actions: {
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  sessionActions: {
    '& > *:not(:last-child)': {
      mr: 2
    }
  },

  arrowDownContainer: {
    justifyContent: 'center',
    display: 'flex',
    mt: 4
  },
  arrowDown: {
    animation:
      'shake-vertical 5s cubic-bezier(0.455, 0.030, 0.515, 0.955) infinite both',
    '-webkit-animation':
      'shake-vertical 5s cubic-bezier(0.455, 0.030, 0.515, 0.955) infinite both',
    border: 'none',
    color: 'black',
    background: 'transparent',
    '& svg': {
      height: '24px',
      width: '24px',
      fill: 'text'
    }
  },
  hr: {
    borderTop: '1px solid white',
    width: '100%'
  },
  heading: {
    fontSize: [0, 0, 6, 6, 6, 6],
    lineHeight: '24px',
    fontWeight: 'bold',
    m: 0,
    p: 0,
    color: 'textTitle'
  },
  headingTwo: {
    fontSize: [0, 0, 5, 5, 5, 5],
    lineHeight: '24px',
    fontWeight: 'bold',
    m: 0,
    p: 0
  },
  headingThree: {
    fontSize: [0, 0, 4, 4, 4, 4],
    lineHeight: '24px',
    fontWeight: 'bold',
    m: 0,
    p: 0
  },
  headingFour: {
    fontSize: [0, 0, 3, 3, 3, 3],
    lineHeight: '24px',
    fontWeight: 'bold',
    m: 0,
    p: 0
  },
  headingFive: {
    fontSize: [0, 0, 2, 2, 2, 2],
    lineHeight: '24px',
    fontWeight: 'bold',
    m: 0,
    p: 0
  },
  headingSix: {
    fontSize: [0, 0, 2, 2, 2, 2],
    lineHeight: '24px',
    fontWeight: 'bold',
    m: 0,
    p: 0
  },
  paragraph: {
    fontSize: [0, 0, 2, 2, 2, 2],
    lineHeight: ['20px', '20px', '24px', '24px', '24px', '24px'],
    mt: 2
  },
  mayaPageLink: {
    cursor: 'pointer',
    textDecoration: 'underline',
    color: 'blue',
    '&:focus': {
      outline: '1px solid white'
    }
  },
  anchor: {
    fontSize: ['12px', '12px', '16px', '16px', '16px', '16px'],
    lineHeight: ['16px', '16px', '24px', '24px', '24px', '24px'],
    letterSpacing: '-0.01em',
    opacity: 0.6,
    textDecoration: 'underline',
    color: 'text',
    cursor: 'pointer',
    ':link': {
      fontSize: ['12px', '12px', '16px', '16px', '16px', '16px'],
      lineHeight: ['16px', '16px', '24px', '24px', '24px', '24px'],
      letterSpacing: '-0.01em',
      opacity: 0.6,
      textDecoration: 'underline',
      color: 'link',
      cursor: 'pointer'
    },
    ':visited': {
      fontSize: ['12px', '12px', '16px', '16px', '16px', '16px'],
      lineHeight: ['16px', '16px', '24px', '24px', '24px', '24px'],
      letterSpacing: '-0.01em',
      opacity: 0.6,
      textDecoration: 'underline',
      color: 'text',
      cursor: 'pointer'
    }
  },
  loading: {
    barContainer: {
      width: ['90%', '90%', '80%', '80%', '60%', '60%'],
      justifyContent: 'flex-start',
      border: '1px solid transparent',
      borderColor: 'primary',
      borderRadius: '4px'
    },
    barInnerContainer: {
      width: '100%',
      borderRadius: '3px',
      backgroundColor: 'white',
      p: '1px'
    },
    bar: {
      height: [5, 5, 14, 14, 7, 7],
      backgroundColor: 'primary',
      transition: 'width .6s ease',
      backgroundSize: '1rem 1rem',
      borderRadius: '4px'
    }
  },
  showMoreButton: {
    fontFamily: 'inherit',
    borderRadius: '4px',
    fontWeight: 'normal',
    fontSize: ['11px', '11px', '14px', '14px', '14px', '14px'],
    lineHeight: ['13px', '13px', '17px', '17px', '17px', '17px'],
    letterSpacing: '-0.01em',
    p: '8px',
    width: 'fit-content',
    transition: 'all 0.4s',
    cursor: 'pointer',
    bg: 'backgroundSecondary',
    border: '1px solid transparent',
    whiteSpace: 'nowrap',
    '& svg, & img': {
      fill: 'currentColor'
    },
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: 'text',
    '& img, & svg': {
      mr: 2
    },
    '@media (hover: hover)': {
      '&:hover': {
        bg: 'backgroundSecondaryHover'
      }
    },
    ':focus': {
      outline: 'none',
      bg: 'backgroundSecondary',
      '--box-shadow-color': 'primary',
      boxShadow: '0 0 3px var(--box-shadow-color)'
    },
    '&:disabled': {
      opacity: 0.2
    }
  }
}

export default styles
