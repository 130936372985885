import React from 'react'
import { Box, Button, Flex, Heading, Text } from 'rebass'

import { useTranslation } from 'react-i18next'
import { trackHandler } from 'uneeq-react-core'
import { ReactComponent as ExclamationIcon } from '../../app/assets/icons/error.svg'
import { MayaOverlay } from '../MayaOverlay'

interface FatalErrorProps {
  errorTitle?: string
  errorMessage?: string
  clearError?: () => void
  buttonText?: string
  children?: React.ReactNode
}
const FatalError: React.FC<FatalErrorProps> = ({
  errorTitle = 'Error',
  errorMessage,
  clearError,
  buttonText,
  children
}) => {
  const { t } = useTranslation()

  return (
    <MayaOverlay>
      <Flex variant="modal">
        <Box variant="modalIconWrapper">
          <ExclamationIcon />
        </Box>
        <Box>
          <Heading variant="modalHeader">{errorTitle}</Heading>
          {!children && (
            <Text variant="modalText">
              {errorMessage || t('Error.serverError')}
            </Text>
          )}
          {children !== undefined && <Flex>{children}</Flex>}
        </Box>

        {clearError && (
          <Flex variant="modalButtons">
            <Button
              variant="primary"
              onClick={trackHandler(clearError, 'close-error-btn')}
            >
              {buttonText || t('Error.backToHomepage')}
            </Button>
          </Flex>
        )}
      </Flex>
    </MayaOverlay>
  )
}

export default FatalError
