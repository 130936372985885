export const styles = {
  list: {
    label: 'PatientSelector-list',
    flexDirection: 'column',
    overflowY: 'scroll',
    maxHeight: '30vh',
    mb: 12,
    p: 0,
    '& > *:not(:last-child)': {
      mb: 3
    }
  },
  option: {
    label: 'PatientSelector-option',
    cursor: 'pointer',
    p: 6,
    border: 'default'
  },
  actions: {
    label: 'PatientSelector-actions',
    justifyContent: 'space-between',
    flexDirection: ['column', 'column', 'column', 'column', 'row', 'row'],
    '& > *:not(:last-child)': {
      mr: [0, 0, 0, 0, 2, 2],
      mb: [2, 2, 2, 2, 0, 0]
    }
  },
  button: {
    label: 'PatientSelector-button',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    display: 'inline-block',
    lineHeight: 1
  }
}
