import React, { useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { Box, Button, Flex, Heading } from 'rebass'
import { ReactComponent as CloseIcon } from '../../app/assets/icons/close.svg'
import { MayaOverlay } from '../MayaOverlay'
import styles from './mayaTermsOfConditionsStyles'

interface MayaTermsOfConditionsProps {
  onClose: () => any
}

export const MayaTermsOfConditions = ({
  onClose
}: MayaTermsOfConditionsProps) => {
  const { t } = useTranslation()

  const scrollableContentRef = useRef<HTMLDivElement>(null)

  const handleKeyDownButton = (e: any) => {
    if (e.key === 'ArrowDown') {
      e.stopPropagation()
      scrollableContentRef.current?.focus()
    }
  }

  const handleKeyDownScrollableContent = (e: any) => {
    if (e.key === 'Enter') {
      e.preventDefault()
      e.stopPropagation()
      onClose()
    }
  }

  return (
    <MayaOverlay>
      <Flex sx={styles.container}>
        <Flex sx={styles.header}>
          <Heading variant="title" sx={styles.heading}>
            {t('TermsAndConditions.title')}
          </Heading>
          <Button
            autoFocus
            onClick={onClose}
            onKeyDown={handleKeyDownButton}
            variant="secondary"
            sx={styles.icon}
          >
            <CloseIcon />
          </Button>
        </Flex>
        <Box
          sx={styles.text}
          onKeyDown={handleKeyDownScrollableContent}
          ref={scrollableContentRef}
          tabIndex={0}
        >
          {/* TODO Missing styles - how to consume it from the back? */}
          <div>
            <p>
              By using this site, you signify your assent to these Terms and
              Conditions. If you do not agree to all of these Terms and
              Conditions, do not use this site or the MayaMD Apps.
            </p>
            <p>
              MayaMD Inc. (“MayaMD”) may revise and update these Terms and
              Conditions at any time. Your continued usage of the MayaMD website
              or Apps (“MayaMD Site or MayaMD Apps,” or the “Site” or “Apps”)
              will mean you accept those changes.
            </p>
            <h6>THE MAYAMD APPS AND SITE DOES NOT PROVIDE MEDICAL ADVICE</h6>
            <p>
              The contents of the MayaMD Site and Apps, such as text, graphics,
              images, and other materials created by MayaMD or obtained from
              MayaMD’s licensor’s and other materials contained on the MayaMD
              Site and Apps, (collectively, “Content”) are for informational
              purposes only. The Content is not intended to be a substitute for
              professional medical advice, diagnosis, or treatment. Always seek
              the advice of your physician or other qualified health provider
              with any questions you may have regarding a medical condition.
              Never disregard professional medical advice or delay in seeking it
              because of something you have read on the MayaMD Site and Apps.
            </p>
            <p>
              If you think you may have a medical emergency, call your doctor or
              911 immediately. MayaMD does not recommend or endorse any specific
              tests, physicians, products, procedures, opinions, or other
              information that may be mentioned on the MayaMD Site and Apps.
              Reliance on any information provided by MayaMD, MayaMD employees,
              others appearing on the Site at the invitation of MayaMD, or other
              visitors to the Site is solely at your own risk.
            </p>
            <h6>CHILDREN’S PRIVACY</h6>
            <p>
              We are committed to protecting the privacy of children. You should
              be aware that this MayaMD Site and Apps are not intended or
              designed to be used persons under the age of 18. We do not collect
              personally identifiable information from any person we actually
              know is a child under the age of 18.
            </p>
            <h6>USE OF THE CONTENT</h6>
            <p>
              The Content posted on this MayaMD Site and Apps is protected by
              the copyright laws in the United States and in foreign countries.
              MayaMD authorizes you to view or download a single copy of the
              Content solely for your personal, noncommercial use if you include
              the copyright notice located at the end of the material, for
              example: “©2019, MayaMD, Inc.” All rights reserved” and other
              copyright and proprietary rights notices that are contained in the
              Content. Any special rules for the use of certain software and
              other items accessible on the MayaMD Site and Apps may be included
              elsewhere within the Site and are incorporated into these Terms
              and Conditions by reference.
            </p>
            <p>
              Title to the Content remains with MayaMD or its licensors. Any use
              of the Content not expressly permitted by these Terms and
              Conditions is a breach of these Terms and Conditions and may
              violate copyright, trademark, and other laws. Content and other
              features are subject to change or termination without notice in
              the editorial discretion of MayaMD. All rights not expressly
              granted herein are reserved to MayaMD and its licensors.
            </p>
            <p>
              If you violate any of these Terms and Conditions, your permission
              to use the Content automatically terminates and you must
              immediately destroy any copies you have made of any portion of the
              Content.
            </p>
            <h6>LIABILITY OF MAYAMD AND ITS LICENSORS</h6>
            <p>
              The use of the MayaMD Site and Apps and the Content is at your own
              risk.
            </p>
            <p>
              When using the MayaMD Site, information will be transmitted over a
              medium that may be beyond the control and jurisdiction of MayaMD
              and its suppliers. Accordingly, MayaMD assumes no liability for or
              relating to the delay, failure, interruption, or corruption of any
              data or other information transmitted in connection with use of
              the MayaMD Site.
            </p>
            <p>
              The MayaMD Site and Apps and the Content are provided on an “as
              is” basis.{' '}
              <strong>
                MAYAMD, ITS LICENSORS, AND ITS SUPPLIERS, TO THE FULLEST EXTENT
                PERMITTED BY LAW, DISCLAIM ALL WARRANTIES, EITHER EXPRESS OR
                IMPLIED, STATUTORY OR OTHERWISE, INCLUDING BUT NOT LIMITED TO
                THE IMPLIED WARRANTIES OF MERCHANTABILITY, NON-INFRINGEMENT OF
                THIRD PARTIES’ RIGHTS, AND FITNESS FOR PARTICULAR PURPOSE.
              </strong>
            </p>
            <p>
              Without limiting the foregoing, MayaMD, its licensors, and its
              suppliers make no representations or warranties about the
              following:
            </p>
            <ul role="list">
              <li>
                The accuracy, reliability, completeness, currentness, or
                timeliness of the Content, software, links, or communications
                provided on or through the use of the MayaMD Site and Apps.
              </li>
              <li>
                The satisfaction of any government regulations requiring
                disclosure of information on prescription drug products or the
                approval or compliance of any software tools with regard to the
                Content contained on the MayaMD Site and Apps.
              </li>
              <li>
                In no event shall MayaMD, its licensors, its suppliers, or any
                third parties mentioned on the MayaMD Site and Apps be liable
                for any damages (including, without limitation, incidental and
                consequential damages, personal injury/wrongful death, lost
                profits, or damages resulting from lost data or business
                interruption) resulting from the use of or inability to use the
                MayaMD Site and Apps or the Content, whether based on warranty,
                contract, tort, or any other legal theory, and whether or not
                MayaMD, its licensors, its suppliers, or any third parties
                mentioned on the MayaMD Site and Apps are advised of the
                possibility of such damages. MayaMD, its licensors, its
                suppliers, or any third parties mentioned on the MayaMD Site and
                Apps shall be liable only to the extent of actual damages
                incurred by you, not to exceed U.S. $1000. MayaMD, its
                licensors, its suppliers, or any third parties mentioned on the
                MayaMD Site and Apps are not liable for any personal injury,
                including death, caused by your use or misuse of the Site,
                Content, or Public Areas (as defined below). Any claims arising
                in connection with your use of the Site, any Content, or the
                Public Areas must be brought within one (1) year of the date of
                the event giving rise to such action occurred. Remedies under
                these Terms and Conditions are exclusive and are limited to
                those expressly provided for in these Terms and Conditions.
              </li>
            </ul>
            <h6>USER CONTENT AND SUBMISSIONS</h6>
            <p>
              <strong>
                The personal information you submit to MayaMD is governed by the
                MayaMD Privacy Notice.
              </strong>{' '}
              To the extent there is an inconsistency between this Agreement and
              the MayaMD Privacy Notice, the Privacy Notice shall govern.
            </p>
            <p>
              The Site contains functionality and other interactive areas,
              including blogs, forums, message boards, Ask Our Expert postings,
              user reviews of drug information, user reviews on the Physician
              Directory, etc. (collectively “Public Areas”) that allow users to
              post or upload content and other information, including comments,
              images, questions, reviews, and other materials (the “User
              Content”). Users may also upload User Content via our official
              brand presence on social media platforms and branded hashtags,
              including, without limitation Facebook, Twitter, Google Plus,
              YouTube, Instagram, and Pinterest, (collectively “Social Media
              Platforms”). You agree that you will not post, upload, or transmit
              any communications or User Content of any type to the Public Areas
              or Social Media Platforms that infringe or violate any rights of
              any party. By submitting communications or User Content to the
              Public Areas or Social Media Platforms, you agree to comply with
              these Terms and Conditions and other applicable polices, such as
              our Reviews Guidelines. MayaMD reserves the right to remove User
              Content for any reason, including User Content that we believe
              violates these Terms and Conditions or our other policies, such as
              our Reviews Guidelines. By submitting any communications or User
              Content to the Public Areas or Social Media Platforms, you further
              agree that such submission is non confidential for all purposes.
              It is important to note that MayaMD is not responsible for the
              operation, terms of use or policies of any Social Media Platform.
              Before using any Social Media Platform you should review its terms
              of use and policies, including its Privacy Notice.
            </p>
            <p>
              If you make any submission or post User Content to a Public Area
              or a Social Media Platform, you agree that you will not send or
              transmit to MayaMD by email, (including through the email
              addresses listed on the “Contact Us” page) any communication or
              content that infringes or violates any rights of any party. If you
              submit any business information, idea, concept or invention to
              MayaMD by email, you agree such submission is non-confidential for
              all purposes. If you make any submission or post User Content to a
              Public Area or a Social Media Platform or if you submit any
              business information, idea, concept or invention to MayaMD by
              email, you automatically grant-or warrant that the owner of such
              content or intellectual property has expressly granted-MayaMD a
              royalty-free, perpetual, irrevocable, worldwide, non-exclusive,
              fully sublicensable right and license to use, reproduce, create
              derivative works from, modify, adapt, publish, edit, translate,
              distribute, perform, and publicly display such submission or User
              Content in any media or medium, or any form, format, or forum now
              known or hereafter developed. MayaMD may sublicense its rights
              through multiple tiers of sublicenses. If you wish to keep any
              business information, ideas, concepts or inventions private or
              proprietary, do not submit them to the Public Areas or Social
              Media Platforms or to MayaMD by email. We try to answer every
              email in a timely manner but are not always able to do so.
            </p>
            <h6>USER SUBMISSIONS – IMAGE, VIDEO, AUDIO FILES</h6>
            <p>
              You agree to only post or upload media (like photos, videos, or
              audio) on the MayaMD Site or a Social Media Platform that you have
              taken yourself or that you have all rights to transmit and license
              and which do not violate trademark, copyright, privacy, or any
              other rights of any other person. Photos or videos of celebrities
              and cartoon or comic images are usually copyrighted by the owner.
            </p>
            <p>
              To protect your privacy, you agree that you will not submit any
              media that contains Personally Identifiable Information (like
              name, phone number, email address or web site URL) of you or of
              anyone else. Uploading media like images or video of other people
              without their permission is strictly prohibited.
            </p>
            <p>
              By uploading any media on the MayaMD Site or a Social Media
              Platform, you warrant that you have permission from all persons
              appearing in your media for you to make this contribution and
              grant rights described herein. Never post a picture or video of or
              with someone else unless you have their explicit permission.
            </p>
            <p>
              It is strictly prohibited to upload media of any kind that contain
              expressions of hate, abuse, offensive images or conduct,
              obscenity, pornography, sexually explicit or any material that
              could give rise to any civil or criminal liability under
              applicable law or regulations or that otherwise may be in conflict
              with these Terms and Conditions, the MayaMD Advertising Policy or
              the MayaMD Privacy Notice.
            </p>
            <p>
              You agree that you will not upload any material that contains
              software viruses or any other computer code, files or programs
              designed to interrupt, destroy or limit the functionality of any
              computer software or this Web site.
            </p>
            <p>
              By uploading any media on the MayaMD Site or a Social Media
              Platform like a photo or video: (a) you grant to MayaMD a
              perpetual, irrevocable, non-exclusive, worldwide, royalty-free,
              fully sublicensable right and license to use, copy, print,
              publicly display, reproduce, modify, publish, post, transmit,
              create derivative works from, and distribute the media and any
              material included in the media; (b) you certify that any person
              pictured in the submitted media (or, if a minor, his/her
              parent/legal guardian) authorizes MayaMD to use, copy, print,
              publicly display, reproduce, modify, publish, post, transmit,
              create derivative works from, and distribute the media and any
              material included in such media; and (c) you agree to indemnify
              MayaMD and its affiliates, directors, officers and employees and
              hold them harmless from any and all claims and expenses, including
              attorneys’ fees, arising from the media and/or your failure to
              comply with these Terms and Conditions.
            </p>
            <p>
              MayaMD reserves the right to review all media prior to submission
              to the site and to remove any media for any reason, at any time,
              without prior notice, at our sole discretion.
            </p>
            <h6>PASSWORDS</h6>
            <p>
              MayaMD has several tools that allow you to record and store
              information. You are responsible for taking all reasonable steps
              to ensure that no unauthorized person shall have access to your
              MayaMD passwords or accounts. It is your sole responsibility to:
              (1) control the dissemination and use of sign-in name, screen name
              and passwords; (2) authorize, monitor, and control access to and
              use of your MayaMD account and password; (3) promptly inform
              MayaMD if you believe your account or password has been
              compromised or if there is any other reason you need to deactivate
              a password. To send us an email at{' '}
              <a href="mailto:dataprivacy@mayamd.ai">dataprivacy@mayamd.ai</a>{' '}
              or use the “Contact Us” links in the menu. You grant MayaMD and
              all other persons or entities involved in the operation of the
              MayaMD Site and Apps the right to transmit, monitor, retrieve,
              store, and use your information in connection with the operation
              of the MayaMD Site and Apps. MayaMD cannot and does not assume any
              responsibility or liability for any information you submit, or
              your or third parties’ use or misuse of information transmitted or
              received using MayaMD tools and services.
            </p>
            <h6>USER CONTENT GUIDELINES</h6>
            <p>
              If you use, or post User Content on, a Public Area, such as
              message boards, blogs, forum, or post on any Social Media
              Platform, you are solely responsible for your own communications,
              the consequences of posting those communications, and your
              reliance on any communications found in the Public Areas or on the
              Social Media Platforms. MayaMD and its licensors are not
              responsible for the consequences of any communications in the
              Public Areas or on Social Media Platforms. In cases where you feel
              threatened or believe someone else is in danger, you should
              contact your local law enforcement agency immediately. If you
              think you may have a medical emergency, call your doctor or 911
              immediately.
            </p>
            <p>
              In consideration of being allowed to use the Public Areas and
              Social Media Platforms, you agree that the following actions shall
              constitute a material breach of these Terms and Conditions:
            </p>
            <ul role="list">
              <li>
                Using a Public Area or Social Media Platform for any purpose in
                violation of local, state, national, or international laws;
              </li>
              <li>
                Posting material that infringes on the intellectual property
                rights of others or on the privacy or publicity rights of
                others;
              </li>
              <li>
                Posting material that is unlawful, obscene, defamatory,
                threatening, harassing, abusive, slanderous, hateful, or
                embarrassing to any other person or entity as determined by
                MayaMD in its sole discretion;
              </li>
              <li>Posting advertisements or solicitations of business;</li>
              <li>
                After receiving a warning, continuing to disrupt the normal flow
                of dialogue, or posting comments that are not related to the
                topic being discussed (unless it is clear the discussion is
                free-form);
              </li>
              <li>Posting chain letters or pyramid schemes;</li>
              <li>Impersonating another person;</li>
              <li>Distributing viruses or other harmful computer code;</li>
              <li>
                Harvesting, scraping or otherwise collecting information about
                others, including email addresses, without their identification
                for posting or viewing comments;
              </li>
              <li>
                Allowing any other person or entity to use your identification
                for posting or viewing comments
              </li>
              <li>Posting the same note more than once or “spamming”; or</li>
              <li>
                Engaging in any other conduct that restricts or inhibits any
                other person from using or enjoying the Public Area, Social
                Media Platform or the Site, or which, in the judgment of MayaMD,
                exposes MayaMD or any of its customers or suppliers to any
                liability or detriment of any type.
              </li>
              <li>
                Reviews posted on the Physician Directory are further subject to
                the MayaMD Reviews Guidelines.
              </li>
            </ul>
            <p>
              MayaMD Reserves the Right (but is Not Obligated) to Do Any or All
              of the Following: Record the dialogue in public chat rooms.
            </p>
            <ul role="list">
              <li>
                Investigate an allegation that a communication(s) do(es) not
                conform to the terms of this section and determine in its sole
                discretion to remove or request the removal of the
                communication(s).
              </li>
              <li>
                Remove communications which are abusive, illegal, or disruptive,
                or that otherwise fail to conform with these Terms and
                Conditions.
              </li>
              <li>
                Terminate a user’s access to any or all Public Areas and/or the
                MayaMD Site upon any breach of these Terms and Conditions.
              </li>
              <li>
                Monitor, edit, or disclose any communication in the Public Areas
                or on a Social Media Platform.
              </li>
              <li>
                Edit or delete any communication(s) posted on the MayaMD Site,
                regardless of whether such communication(s) violate these
                standards.
              </li>
            </ul>
            <p>
              MayaMD or its licensors have no liability or responsibility to
              users of the MayaMD Site or any other person or entity for
              performance or non performance of the aforementioned activities.
            </p>
            <h6>ADVERTISEMENTS, SEARCHES, AND LINKS TO OTHER SITES</h6>
            <p>
              MayaMD may provide links to third-party web sites. MayaMD also may
              select certain sites as priority responses to search terms you
              enter and MayaMD may agree to allow advertisers to respond to
              certain search terms with advertisements or sponsored content.
              MayaMD does not recommend and does not endorse the content on any
              third-party websites. MayaMD is not responsible for the content of
              linked third-party sites, sites framed within the MayaMD Site,
              third party sites provided as search results, or third-party
              advertisements, and does not make any representations regarding
              their content or accuracy. Your use of third-party websites is at
              your own risk and subject to the terms and conditions of use for
              such sites. MayaMD does not endorse any product, service, or
              treatment advertised on the MayaMD Site. For more information,
              read our Advertising Policy and our Sponsor Policy.
            </p>
            <h6>INDEMNITY</h6>
            <p>
              You agree to defend, indemnify, and hold MayaMD, its officers,
              directors, employees, agents, licensors, and suppliers, harmless
              from and against any claims, actions or demands, liabilities and
              settlements including without limitation, reasonable legal and
              accounting fees, resulting from, or alleged to result from: (a)
              your use of the Site, (b) any User Content you post or upload, (c)
              your use of or reliance on any User Content, or (d) your violation
              of these Terms and Conditions.
            </p>
            <h6>GENERAL</h6>
            <p>
              MayaMD is based in Las Vegas, NV. MayaMD makes no claims that
              MayaMD Site and Apps and the Content are appropriate or may be
              downloaded outside of the United States. Access to the Content may
              not be legal by certain persons or in certain countries. If you
              access the MayaMD Network from outside the United States, you do
              so at your own risk and are responsible for compliance with the
              laws of your jurisdiction.
            </p>
            <p>
              The following provisions survive the expiration or termination of
              this Agreement for any reason whatsoever: Liability, User
              Submissions, User Submissions – image, video, audio files,
              Indemnity, Jurisdiction, and Complete Agreement.
            </p>
            <h6>JURISDICTION</h6>
            <p>
              You expressly agree that exclusive jurisdiction for any dispute
              with MayaMD, or in any way relating to your use of the MayaMD
              Site, resides in the courts of the State of Nevada and you further
              agree and expressly consent to the exercise of personal
              jurisdiction in the courts of the State of Nevada in connection
              with any such dispute including any claim involving MayaMD or its
              affiliates, subsidiaries, employees, contractors, officers,
              directors, telecommunication providers, and content providers.
            </p>
            <p>
              These Terms and Conditions are governed by the internal
              substantive laws of the State of Nevada, without respect to its
              conflict of laws principles. If any provision of these Terms and
              Conditions is found to be invalid by any court having competent
              jurisdiction, the invalidity of such provision shall not affect
              the validity of the remaining provisions of these Terms and
              Conditions, which shall remain in full force and effect. No waiver
              of any of these Terms and Conditions shall be deemed a further or
              continuing waiver of such term or condition or any other term or
              condition.
            </p>
            <p>
              Notice and Take down Procedures; and Copyright Agent If you
              believe any materials accessible on or from the MayaMD Site and
              Apps infringe your copyright, you may request removal of those
              materials (or access thereto) from this web site by contacting
              MayaMD’s copyright agent (identified below) and providing the
              following information: Identification of the copyrighted work that
              you believe to be infringed. Please describe the work, and where
              possible include a copy or the location (e.g., URL) of an
              authorized version of the work.
            </p>
            <p>
              Identification of the material that you believe to be infringing
              and its location. Please describe the material and provide us with
              its URL or any other pertinent information that will allow us to
              locate the material.
            </p>
            <ul role="list">
              <li>
                Your name, address, telephone number and (if available) e-mail
                address.
              </li>
              <li>
                A statement that you have a good faith belief that the
                complained of use of the materials is not authorized by the
                copyright owner, its agent, or the law.
              </li>
              <li>
                A statement that the information that you have supplied is
                accurate, and indicating that “under penalty of perjury,” you
                are the copyright owner or are authorized to act on the
                copyright owner’s behalf.
              </li>
              <li>
                A signature or the electronic equivalent from the copyright
                holder or authorized representative.
              </li>
            </ul>
            <p>
              MayaMD’s agent for copyright issues relating to this web site is
              as follows:
            </p>
            <p>MayaMD, Inc.</p>
            <p>Attn: Office of Privacy</p>
            <p>10330 Apache Blue Ave</p>
            <p>Las Vegas, NV 89135</p>
            <p>
              <a href="mailto:dataprivacy@mayamd.ai">dataprivacy@mayamd.ai</a>
            </p>
            <p>
              Or Call: <a href="http://telto:207.266.8611">207.266.8611</a>
            </p>
            <p>
              In an effort to protect the rights of copyright owners, MayaMD
              maintains a policy for the termination, in appropriate
              circumstances, of subscribers and account holders of the Site who
              are repeat infringers.
            </p>
            <h6>COMPLETE AGREEMENT</h6>
            <p>
              Except as expressly provided in a particular “legal notice” on the
              MayaMD Site, these Terms and Conditions and the MayaMD Privacy
              Notice constitute the entire agreement between you and MayaMD with
              respect to the use of the MayaMD Site, and Content.
            </p>
            <h2>&zwj;</h2>
          </div>
        </Box>
      </Flex>
    </MayaOverlay>
  )
}
