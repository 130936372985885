import { format } from 'date-fns'

const getQuizMedication = medications => {
  return medications[Math.floor(Math.random() * medications.length)]
}

const getNextAppointment = allAppointments => {
  const now = format(
    new Date(new Date().toISOString().slice(0, -1)),
    'EEEE, MMMM d'
  )
  const appointments = [...allAppointments]
    .sort(
      (a, b) =>
        new Date(a.date_of_appointment) - new Date(b.date_of_appointment)
    )
    .filter(apt => apt.date_of_appointment >= now) // only appts in the future or today
  const nextAppointment = appointments?.length > 0 ? appointments[0] : null

  return nextAppointment
}

export const quizGenerator = (medications, appointments) => {
  // Quiz generate correct medication
  const randomCorrectQuizMedication = getQuizMedication(medications)

  // Quiz generate first incorrect medication
  const randomIncorrectQuizMedication1 = getQuizMedication(
    medications.filter(rnd =>
      rnd.dosage?.value
        ? rnd.dosage.value !== randomCorrectQuizMedication.dosage.value
        : false
    )
  )

  // Quiz generate second incorrect medication
  const randomIncorrectQuizMedication2 = getQuizMedication(
    medications.filter(rnd =>
      rnd.dosage?.value
        ? rnd.dosage.value !== randomCorrectQuizMedication.dosage.value &&
          rnd.dosage.value !== randomIncorrectQuizMedication1.dosage.value
        : false
    )
  )

  const quizFollowUp = getNextAppointment(appointments)

  return {
    nextAppointment: quizFollowUp,
    nextAppointmentDateString: quizFollowUp
      ? `${quizFollowUp?.date_of_appointment} at ${quizFollowUp?.time_of_appointment}`
      : ``,
    randomCorrectMedication: randomCorrectQuizMedication,
    randomIncorrectMedication1: randomIncorrectQuizMedication1,
    randomIncorrectMedication2: randomIncorrectQuizMedication2
  }
}
