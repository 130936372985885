import { Select } from '@rebass/forms'
import dateFnsFormat from 'date-fns/format'
import dateFnsParse from 'date-fns/parse'
import React from 'react'
import { DateUtils, LocaleUtils } from 'react-day-picker'
import DayPickerInput from 'react-day-picker/DayPickerInput'
import 'react-day-picker/lib/style.css'
import { useTranslation } from 'react-i18next'
import { Box, Flex, Text } from 'rebass'
import styles from './styles'

const fromMonth = new Date(1910, 0)
const toMonth = new Date()

const DatePickerNavbar = ({ onPreviousClick, onNextClick }: any) => {
  return (
    <Box className="DayPicker-NavBar">
      <Box
        onClick={() => onPreviousClick()}
        as="span"
        sx={{
          left: '1rem',
          // 16px margin + aprox height of select year form
          top: '37px',
          right: 'unset',
          transform: 'translateY(-50%)'
        }}
        className="DayPicker-NavButton DayPicker-NavButton--prev"
      ></Box>
      <Box
        onClick={() => onNextClick()}
        sx={{
          right: '1rem',
          // 16px margin + aprox height of select year form
          top: '37px',
          left: 'unset',
          transform: 'translateY(-50%)'
        }}
        as="span"
        className="DayPicker-NavButton DayPicker-NavButton--next"
      ></Box>
    </Box>
  )
}

interface YearMonthFormProps {
  date: Date
  localeUtils: any
  onChange: Function
}
const YearMonthForm = ({ date, localeUtils, onChange }: YearMonthFormProps) => {
  const months = localeUtils.getMonths('es')

  const years = []
  for (let i = fromMonth.getFullYear(); i <= toMonth.getFullYear(); i += 1) {
    years.push(i)
  }

  const handleChange = (e: any) => {
    const { year, month } = e.target.form
    onChange(new Date(year.value, month.value))
  }

  return (
    <Box className="DayPicker-Caption">
      <form>
        <Flex sx={styles.datePickerForm}>
          <Select
            sx={styles.datePickerSelect}
            name="month"
            onChange={handleChange}
            value={date.getMonth()}
          >
            {months.map((month: string, i: number) => (
              <Text
                as="option"
                sx={{
                  bg: 'backgroundPrimary',
                  text: 'color',
                  fontFamily: 'inherit'
                }}
                key={month}
                value={i}
              >
                {month}
              </Text>
            ))}
          </Select>
          <Select
            sx={styles.datePickerSelect}
            name="year"
            onChange={handleChange}
            value={date.getFullYear()}
          >
            {years.map(year => (
              <Text
                as="option"
                sx={{
                  bg: 'backgroundPrimary',
                  text: 'color',
                  fontFamily: 'inherit'
                }}
                key={year}
                value={year}
              >
                {year}
              </Text>
            ))}
          </Select>
        </Flex>
      </form>
    </Box>
  )
}

type DatepickerProps = {
  date: string | Date
  onChange: () => void
  error: boolean
}

export const Datepicker = ({ date, onChange, error }: DatepickerProps) => {
  const { t } = useTranslation()

  const defaultMonths = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December'
  ]
  const defaultWeekDaysShort = ['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa']
  const defaultWeekDays = [
    'Sunday',
    'Monday',
    'Tuesday',
    'Wednesday',
    'Thursday',
    'Friday',
    'Saturday'
  ]

  const parseDate = (str: string, format: string) => {
    const parsed = dateFnsParse(str, format, new Date())
    if (DateUtils.isDate(parsed)) {
      return parsed
    }
    return undefined
  }

  const FORMAT = 'MM/dd/yyyy'

  const formatDate = (date: Date, format: string) => {
    return dateFnsFormat(date, format)
  }

  const DatePickerOverlay = ({
    classNames,
    selectedDay,
    children,
    ...props
  }: any) => {
    return (
      <Box className={classNames.overlayWrapper} {...props}>
        <Box
          sx={{
            bg: 'datePickerOverlayBg',
            color: 'text'
          }}
          className={classNames.overlay}
        >
          {children}
        </Box>
      </Box>
    )
  }

  return (
    <DayPickerInput
      keepFocus={false}
      value={date}
      formatDate={formatDate}
      format={FORMAT}
      parseDate={parseDate}
      placeholder="dd/mm/yyyy"
      style={styles.dateInputContainer}
      inputProps={{
        style: {
          ...styles.dateInput,
          border: error ? '1px solid #E1E1E1' : '1px solid #EF1919'
        },
        readOnly: true
      }}
      overlayComponent={DatePickerOverlay}
      dayPickerProps={{
        localeUtils: {
          ...LocaleUtils,
          getMonths: () =>
            t('Dates.months', {
              returnObjects: true,
              defaultValue: defaultMonths
            })
        },
        weekdaysShort: t('Dates.weekDaysShort', {
          returnObjects: true,
          defaultValue: defaultWeekDaysShort
        }),
        weekdaysLong: t('Dates.weekDays', {
          returnObjects: true,
          defaultValue: defaultWeekDays
        }),
        modifiers: {
          selectedDay: new Date(date)
        },
        modifiersStyles: {
          selectedDay: {
            backgroundColor: 'white',
            color: 'black',
            fontWeight: 'normal',
            borderRadius: '4px'
          }
        },
        onDayClick: onChange,
        fixedWeeks: true,
        month: date as Date,
        toMonth: new Date(),
        navbarElement: props => <DatePickerNavbar {...props} />,
        captionElement: ({ date, localeUtils }) => (
          <YearMonthForm
            date={date}
            localeUtils={localeUtils}
            onChange={onChange}
          />
        )
      }}
    />
  )
}
