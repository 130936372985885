import React from 'react'
import { useTranslation } from 'react-i18next'
import { Button } from 'rebass'
import { useApplicationState } from '../../hooks/useApplicationState'

interface MayaRequestAppointmentButtonProps {
  preAuthToken: string
  patientSelectedId: string
  variant?: string
}

export const MayaRequestAppointmentButton: React.FC<MayaRequestAppointmentButtonProps> = ({
  preAuthToken,
  patientSelectedId,
  variant = 'option'
}) => {
  const { currentLanguage } = useApplicationState({})
  const { t } = useTranslation()

  const requestAnAppointmentLink = `${process.env.REACT_APP_FRONTEND_URL}/patient-request-appointment?token=${preAuthToken}&id=${patientSelectedId}&language=${currentLanguage}`

  return (
    <Button
      variant={variant}
      onClick={() => window.open(requestAnAppointmentLink, '_blank')}
    >
      {t('Home.requestAppointment')}
    </Button>
  )
}
