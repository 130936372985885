import { E164Number } from 'libphonenumber-js/types'
import React, { useState } from 'react'
import 'react-phone-number-input/style.css'
import { Box, Flex } from 'rebass'
import { useSupportedBrowser } from 'uneeq-react-core'
import { LanguageOption } from '../../customPlugins/LanguageSelector/LanguageOption'
import LanguageSelector from '../../customPlugins/LanguageSelector/LanguageSelector'
import LoadingSpinner from '../../customPlugins/LoadingSpinner/LoadingSpinner'
import MayaUnsupportedBrowser from '../../customPlugins/MayaUnsupportedBrowser'
import { Steps } from '../App'
import { useAuthentication } from '../hooks/useAuthentications'
import { CodeStep, PatientStart, PhoneStep } from './Steps'
import AuthLayout from './UI/AuthLayout'

type AuthenticationProps = {
  onRegisteredUserLogin: () => void
  onUnregisteredUserLogin: () => void
  languageSelected: string
  supportedLanguages: LanguageOption[]
  setUserPhone: any
  setUserData: any
  phoneLoginDefaultString?: string
  startSession: (speak: boolean) => void
  appStep: Steps
  authLogo?: string
  domainConfigLoading: boolean
}

export const Authentication = ({
  authLogo,
  setAuthLogo,
  appStep,
  onRegisteredUserLogin,
  onUnregisteredUserLogin,
  languageSelected,
  supportedLanguages,
  setUserPhone,
  setUserData,
  phoneLoginDefaultString,
  startSession,
  domainConfigLoading
}: AuthenticationProps) => {
  const queryParams = new URLSearchParams(window.location.search)

  // Go straight to code step if id is provided
  const id = queryParams.get('id') ? String(queryParams.get('id')) : null
  const [phone, setPhone] = useState<E164Number | undefined>(undefined)
  const [step, setStep] = useState<string>('phone')

  const { isBrowserSupported } = useSupportedBrowser()
  const [showUnsupportedAlert, setShowUnsupportedAlert] = useState(false)

  const { email, error } = useAuthentication(
    id,
    domainConfigLoading,
    onRegisteredUserLogin,
    setAuthLogo
  )

  const onPhoneStepSuccess = () => {
    setStep('code')
  }

  // Show the LoadingSpinner if we're waiting for the initialLogin information to be completed
  if (appStep !== 'patientStart' && !email && !error) {
    return (
      <Box variant="questionsLoadingOverlay">
        <LoadingSpinner />
      </Box>
    )
  }

  if (error) {
    return (
      <AuthLayout authLogo={phone && authLogo}>
        <Flex variant="card">{error}</Flex>
      </AuthLayout>
    )
  }

  const handleClick = (speak: boolean) =>
    speak && !isBrowserSupported
      ? () => setShowUnsupportedAlert(true)
      : () => startSession(speak)

  return (
    <AuthLayout authLogo={phone && authLogo}>
      {showUnsupportedAlert && (
        <MayaUnsupportedBrowser
          close={() => setShowUnsupportedAlert(false)}
          continueChatMode={() => {
            setShowUnsupportedAlert(false)
            startSession(false)
          }}
        />
      )}
      <LanguageSelector
        languageSelected={languageSelected}
        supportedLanguages={supportedLanguages}
      />
      {appStep === 'patientStart' && (
        <PatientStart onContinue={handleClick(true)} authLogo={authLogo} />
      )}
      {!id && step === 'phone' && (
        <PhoneStep
          phone={phone}
          setPhone={setPhone}
          onSuccess={onPhoneStepSuccess}
          phoneLoginDefaultString={phoneLoginDefaultString}
        />
      )}
      {((phone && step === 'code') || (id && appStep !== 'patientStart')) && (
        <CodeStep
          authLogo={authLogo}
          setUserData={setUserData}
          setUserPhone={setUserPhone}
          phone={phone}
          email={email}
          id={id}
          back={() => setStep('phone')}
          onRegisteredUserLogin={onRegisteredUserLogin}
          onUnregisteredUserLogin={onUnregisteredUserLogin}
        />
      )}
    </AuthLayout>
  )
}
