import { differenceInYears, format } from 'date-fns'
import { SingleUserData, UserData } from '../../hooks/useApplicationState'

const BASE_URL = process.env.REACT_APP_BACKEND_URL
const TOKEN = process.env.REACT_APP_BACKEND_TOKEN

const getHeaders = () => ({
  'Content-Type': 'application/json',
  Authorization: `Bearer ${TOKEN}`
})

export const generateCode = async (
  phoneNumber: string,
  recaptchaToken: string
) => {
  try {
    const res = await fetch(`${BASE_URL}/generateCode`, {
      method: 'POST',
      body: JSON.stringify({
        mobile: phoneNumber,
        reCaptchaToken: recaptchaToken
      }),
      headers: getHeaders()
    })
    if (!res.ok) {
      throw new Error(res.statusText)
    }
    return res.json()
  } catch (e) {
    throw e
  }
}

export const verifyCode = async (phone: string, passcode: string) => {
  try {
    const res = await fetch(`${BASE_URL}/verifyCode`, {
      method: 'POST',
      body: JSON.stringify({
        mobile: phone,
        password: passcode
      }),
      headers: getHeaders()
    })

    if (!res.ok) {
      throw new Error(res.statusText)
    }
    return res.json()
  } catch (e) {
    throw e
  }
}

export const userDataToSnakeCase = (userData: SingleUserData) => {
  return {
    _id: userData._id,
    mobile: userData.phoneNumber,
    birthDate: userData.dateOfBirth, // TODO: Check if it's a Date, and convert to ISOString()
    first_name: userData.firstName,
    last_name: userData.lastName,
    name: `${userData.firstName} ${userData.lastName}`,
    email: userData.email,
    gender: userData.sex
  }
}

export const userDataFromSnakeCase = (userData: any) => {
  return {
    _id: userData._id,
    phoneNumber: userData.mobile || '',
    dateOfBirth: userData.birthDate || '',
    firstName: userData.first_name || '',
    lastName: userData.last_name || '',
    email: userData.email || '',
    sex: userData.gender || '',
    preAuthToken: userData.preAuthToken
  }
}

export const addPatient = async (patientData: UserData) => {
  try {
    if (patientData === null) {
      throw new Error(`Patient can't be empty`)
    }
    const { _id, ...patientProps } = patientData[0]
    const dependents = patientData.slice(1)
    const res = await fetch(`${BASE_URL}/patient`, {
      method: 'POST',
      body: JSON.stringify({
        patient: userDataToSnakeCase({ ...patientProps }),
        dependents: dependents.map(({ _id, ...otherDependentProps }) =>
          userDataToSnakeCase({ ...otherDependentProps })
        )
      }),
      headers: getHeaders()
    })
    const response = await res.json()

    if (!res.ok) {
      throw new Error(
        response?.error?.response?.error?.message ||
          response?.error?.msg ||
          res.statusText
      )
    }
    const { patient, dependents: addedDependents } = response
    return [
      userDataFromSnakeCase(patient),
      ...addedDependents.map((depSnake: any) => userDataFromSnakeCase(depSnake))
    ]
  } catch (e) {
    throw e
  }
}

export const deleteDependents = async (
  dependentsArray: SingleUserData[],
  mainPatient: SingleUserData
) => {
  try {
    if (dependentsArray === null) {
      return { success: 'ok' }
    }

    const res = await fetch(`${BASE_URL}/dependent`, {
      method: 'DELETE',
      body: JSON.stringify({
        data: {
          mainPatientId: mainPatient._id,
          dependentId: dependentsArray.map(dep => dep._id)
        }
      }),
      headers: getHeaders()
    })

    if (!res.ok) {
      throw new Error(res.statusText)
    }
    return res.json()
  } catch (e) {
    throw e
  }
}

export const addDependents = async (
  dependents: SingleUserData[],
  mainPatient: SingleUserData
) => {
  try {
    if (dependents.length === 0) {
      return { success: 'ok' }
    }

    const res = await fetch(`${BASE_URL}/patient`, {
      method: 'POST',
      body: JSON.stringify({
        patient: {
          _id: mainPatient._id
        },
        dependents: dependents.map(({ _id, ...otherProps }) =>
          userDataToSnakeCase({
            ...otherProps
          })
        )
      }),
      headers: getHeaders()
    })

    if (!res.ok) {
      const body = await res.json()
      throw new Error(body?.error?.msg || res.statusText)
    }
    return res.json()
  } catch (e) {
    throw e
  }
}

export const updateMainPatient = async (
  mainPatient: SingleUserData,
  dependents: SingleUserData[]
) => {
  try {
    const { email, phoneNumber, ...otherProps } = mainPatient

    const res = await fetch(`${BASE_URL}/patient`, {
      method: 'POST',
      body: JSON.stringify({
        patient: userDataToSnakeCase({ ...otherProps }),
        dependents: dependents.map(dep => userDataToSnakeCase(dep))
      }),
      headers: getHeaders()
    })

    if (!res.ok) {
      const body = await res.json()
      throw new Error(
        body?.error?.response?.error?.message ||
          body?.error?.msg ||
          res.statusText
      )
    }
    const body = res.json()
    console.log('BODY', body)
    return body
  } catch (e) {
    console.log('updateMainPatient CATCH', e)
    throw e
  }
}

export const editPatient = async (
  patientData: UserData,
  initialUserData: UserData
) => {
  try {
    if (patientData === null) {
      return initialUserData
    }

    // Create an array of deleted dependents - comparing initial user data vs new data
    const deletedDependents =
      (initialUserData &&
        initialUserData.filter(
          initialDep =>
            initialDep._id &&
            !patientData.some(dep => dep._id === initialDep._id)
        )) ||
      []

    // Grab main patient - cannot be deleted
    const patient = patientData[0]

    // Grab rest of patients - dependents from main patient
    const dependents = patientData.slice(1)

    // Delete dependents - only run if deletedDependents is not empty
    const deletePromises =
      deletedDependents.length && deleteDependents(deletedDependents, patient)

    // Update main patient and the non deleted dependents - TODO should only be run if necessary/with the necessary data
    const updateMainPatientPromise = updateMainPatient(patient, dependents)

    const promises = await Promise.all([
      deletePromises,
      updateMainPatientPromise
    ])

    // Grab main patient edited from promise
    const updatedMainPatient = userDataFromSnakeCase(promises[1]?.patient)

    // Grab dependents edited from promise
    const updatedDependents = promises[1].dependents
      .filter(
        (dep: SingleUserData) =>
          !deletedDependents.some(delDep => delDep._id === dep._id)
      )
      .map((dep: SingleUserData) => userDataFromSnakeCase(dep))

    // return the updated data
    const updatedUserData = [updatedMainPatient, ...updatedDependents]

    return updatedUserData
  } catch (e) {
    console.log('CATCH22', e)
    throw e
  }
}

export const getSessionIDFromUserData = async (memberData: SingleUserData) => {
  try {
    if (memberData === null) {
      throw new Error(`Patient can't be empty`)
    }
    const data = {
      dependents: [],
      apiKey: process.env.REACT_APP_MAYA_API_KEY,
      apiSecret: process.env.REACT_APP_MAYA_API_SECRET,
      id: memberData._id,
      name: `${memberData.firstName} ${memberData.lastName}`,
      sex: memberData.sex,
      contact: memberData?.phoneNumber,
      email: memberData?.email,
      dob:
        memberData.dateOfBirth &&
        format(new Date(memberData?.dateOfBirth), 'MM/dd/yyyy'),
      birthDate: memberData.dateOfBirth,
      age: differenceInYears(new Date(), new Date(memberData.dateOfBirth!)),
      layperson: true,
      algorithm: false,
      language: 'en',
      dataUrl: `${process.env.REACT_APP_BACKEND_URL}/link_mayanote`,
      dataUrlHeaders: {
        Authorization: `Bearer ${process.env.REACT_APP_BACKEND_TOKEN}`
      }
    }
    const res = await fetch(
      `${process.env.REACT_APP_CHATBOT_HTTPS}/initSession`,
      {
        method: 'POST',
        body: JSON.stringify(data),
        headers: {
          'Content-Type': 'application/json'
        }
      }
    )

    if (!res.ok) {
      throw new Error(res.statusText)
    }
    const result = await res.json()
    return result.sessionId
  } catch (e) {
    throw e
  }
}
