const sessionIDReducer = (session?: string) => (state: any, action: any) => {
  if (action?.type === 'mayaMessage') {
    if (session || action?.payload?.session) {
      return {
        ...state,
        session: session || action.payload.session
      }
    }
  }

  return state
}

export default sessionIDReducer
