import React from 'react'
import { Box, Flex, Text } from 'rebass'
import { MedicationRow, MedicationType } from './MedicationRow'

interface MedicationsTableProps {
  medicationList: MedicationType[]
}

type MedCellProps = {
  activeSpokenContent?: boolean
} & React.ComponentProps<typeof Flex>

export const MedCell = ({ activeSpokenContent, ...props }: MedCellProps) => (
  <Flex variant="medicationsTableCell" {...props}>
    <Box variant={activeSpokenContent ? 'activeSpokenContentSmall' : ''}>
      {props.children}
    </Box>
  </Flex>
)

export const MedicationsTable = ({ medicationList }: MedicationsTableProps) => (
  <Flex variant="medicationsTable">
    <Flex variant="medicationsTableHead">
      <MedCell sx={{ flexBasis: '15%' }}>
        <Text variant="label">Name</Text>
      </MedCell>
      <MedCell>
        <Text variant="label">Reason</Text>
      </MedCell>
      <MedCell>
        <Text variant="label">Route</Text>
      </MedCell>
      <MedCell>
        <Text variant="label">Timing</Text>
      </MedCell>
      <MedCell>
        <Text variant="label">Duration</Text>
      </MedCell>
    </Flex>

    {medicationList.map((medication, index) => (
      <MedicationRow medication={medication} index={index} />
    ))}
  </Flex>
)
