import { useUneeqState } from 'uneeq-react-core'

export const useIsMayaSpeakingOrLoadingNewLines = () => {
  const { linesToSpeak, questionLoading } = useUneeqState()

  // We take it as if Maya is 'speaking' while its calculating the new lines
  // So we prevent the buttons being render while Maya calculates this
  return (
    questionLoading ||
    linesToSpeak?.length === 0 ||
    linesToSpeak?.some(line => line.currentlySpeaking === true)
  )
}
