import React from 'react'
import { Box, Flex, Heading } from 'rebass'

type LayoutProps = {
  title?: string
  blurTitle?: string
  wrap?: 'card' | 'cardSecondary'
  blur?: boolean
  children?: React.ReactNode
}

const BlurOverlay = () => (
  <Flex
    sx={{
      label: 'BlurFlex',
      height: '100%',
      width: '100%',
      backdropFilter: 'blur(7px)',
      zIndex: 10,
      position: 'absolute',
      borderRadius: 'card',
      overflow: 'hidden'
    }}
  />
)

const HeroLayout = ({
  title,
  blurTitle,
  wrap,
  blur,
  children
}: LayoutProps) => {
  return (
    <Flex variant="redContentLayout">
      {title && (
        <Box p={6}>
          <Heading variant="pageTitle">{blur ? blurTitle : title}</Heading>
        </Box>
      )}

      <Flex sx={{ height: '100%', overflow: 'hidden' }}>
        {blur && <BlurOverlay />}

        {wrap === 'card' && (
          <Flex
            variant="redContent"
            bg="white"
            sx={{
              overflow: blur ? 'hidden' : 'visible'
            }}
          >
            {children}
          </Flex>
        )}
        {wrap === 'cardSecondary' && (
          <Flex
            variant="redContentSecondary"
            sx={{
              overflow: blur ? 'hidden' : 'visible'
            }}
          >
            {children}
          </Flex>
        )}
        {!wrap && (
          <Flex
            variant="redContentUnwrapped"
            sx={{
              height: '100%',
              overflow: blur ? 'hidden' : 'auto'
            }}
          >
            {children}
          </Flex>
        )}
      </Flex>
    </Flex>
  )
}

export default HeroLayout
