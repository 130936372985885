export const styles = {
  progress: {
    mt: 4,
    alignItems: 'center',
    '& .react-sweet-progress-symbol': { display: 'none' },
    '& .react-sweet-progress': {
      width: '10rem',
      ml: 1
    },
    '& .react-sweet-progress-line-inner': {
      minHeight: '5px'
    },
    '& .react-sweet-progress-line-inner-status-active:before': {
      animation: 'none'
    }
  }
}

export default styles
