import { THEMES } from '../theme'

export const segurosBolivarColors = {
  text: '#000000',
  textLight: '#393939',
  textAlternate: '#FFFFFF',
  textTranscript: '#FFF',
  textTitle: '#016D38',

  primary: '#FFDC5D',
  primaryHover: '#88FFE1',
  primaryText: '#016D38',

  secondary: '#FFFFFF',
  secondaryHover: '#4bd6c4',
  secondaryText: '#016D38',

  greyDark: '#50575D',
  grey: '#BDC0C7',
  greyLight: '#F6F6F6',

  informationDark: 'rgba(51,51,51,0.95)',
  informationLight: 'rgba(255, 255, 255, 0.2)',

  background: 'rgba(255, 255, 255, 0.95)',
  backgroundPrimary: 'rgba(255, 255, 255, 0.95)',
  backgroundPrimarySolid: 'rgba(255, 255, 255)',
  backgroundSecondary: '#016D38',
  backgroundSecondaryHover: 'rgba(51, 51, 51, 0.3)',
  backgroundOverlay: 'rgb(51, 51, 51, 0.5)',
  error: '#BF2828',
  warning: '#FBBD1D',

  inputBackground: '#FFFFFF',
  inputBackgroundHover: '#FFFFFF',
  inputBorder: '#CCCCCC',

  btnPrimaryBg: '#FFDC5D',
  btnPrimaryBgHover: '#FFD333',

  btnOption: '#016D38',
  btnOptionHover: '#005833',

  btnSecondaryBg: '#F0F6F3',
  btnSecondaryBgHover: '#E6F0EB',

  optionSelectedBorder: '#016D38',
  optionBorder: '#CCCCCC',
  optionText: '#999999',
  optionSelectedBg: '#F0F6F3',
  optionSelectedText: '#333333',

  datePickerDayBg: '#FFDC5D',
  datePickerDayColor: '#000',
  datePickerOverlayBg: '#FFF',

  checkboxChecked: '#016D38',

  link: '#016D38',

  // TODO create our own progress bar with rebass so we can use variant="timoutBar"
  timeoutColor: '#FFDC5D',
  timeoutTrailColor: '#FBFBFB',

  overlayBackground: 'rgba(0,0,0,0.7)'
}

export const segurosBolivarTheme = (type: THEMES, embeddedMode: boolean) => ({
  fonts: {
    body: "'Roboto Condensed', sans-serif",
    heading: 'inherit'
  },
  buttons: {
    primary: {
      borderRadius: '999px',
      fontWeight: 'bold',
      bg: 'btnPrimaryBg',
      '&:hover': {
        bg: 'btnPrimaryBgHover',
        borderColor: 'transparent'
      },
      '&:active': {
        boxShadow: 'inset 2px 2px 3px rgba(0, 0, 0, 0.16)',
        borderColor: 'transparent'
      },
      '&:focus': {
        borderColor: '#DDCC85'
      },
      '&:disabled': {
        bg: '#EEEEEE',
        color: '#999999'
      }
    },
    option: {
      fontWeight: 'bold',
      bg: 'white',
      borderRadius: '100px',
      border: '1px solid',
      color: 'btnOption',
      borderColor: 'btnOption',
      '&:hover': {
        color: 'btnOptionHover',
        borderColor: 'btnOptionHover',
        bg: 'white'
      },
      '&:active': {
        boxShadow: 'inset 2px 2px 3px rgba(11, 87, 30, 0.25)'
      },
      '&:focus': {
        bg: 'white'
      },
      '&:disabled': {
        bg: '#EEEEEE',
        color: '#999999'
      }
    },
    secondary: {
      bg: 'btnSecondaryBg',
      color: 'primaryText',
      border: '1px solid',
      borderColor: 'transparent',
      '&:hover': {
        bg: 'btnSecondaryBgHover'
      },
      '&:active': {
        boxShadow: 'inset 2px 2px 3px rgba(11, 87, 30, 0.25)'
      },
      '&:focus': {
        bg: 'btnSecondaryBg',
        borderColor: '#ADE4C9'
      },
      '&:disabled': {
        borderColor: '#999999',
        color: '#999999'
      }
    }
  },
  variants: {
    questionContainer: {
      boxShadow: '0px 1px 3px rgba(0, 0, 0, 0.15)'
    },
    homeTerms: {
      display: ['none', 'none', 'none', 'none', 'none']
    },
    transcriptContainer: {
      boxShadow: '0px 1px 3px rgba(0, 0, 0, 0.15)'
    },
    inputSelect: {
      border: '1px solid #CCCCCC',
      pl: 6,
      pr: 10,
      py: 3,
      borderRadius: '4px'
    },
    homeLogo: {
      backgroundColor: [
        'transparent',
        'transparent',
        'transparent',
        'transparent',
        'transparent',
        'transparent'
      ],
      maxWidth: ['unset', 'unset', 'unset', 'unset', '20vw', '20vw'],
      width: ['60%', '60%', '60%', '60%', 'auto', 'auto'],
      zIndex: '4',
      position: 'absolute',
      top: 0,
      left: ['16px', '16px', '16px', '16px', '24px', '24px'],
      display: 'flex',
      justifyContent: [
        'flex-start',
        'flex-start',
        'flex-start',
        'flex-start',
        'flex-start',
        'flex-start'
      ],
      alignItems: [
        'flex-start',
        'flex-start',
        'flex-start',
        'flex-start',
        'flex-start',
        'flex-start'
      ],
      '& img': {
        mt: ['0px', '0px', '0px', '0px', '0px', '0px'],
        display: 'block',
        width: ['70%', '70%', '50%', '50%', 'auto', 'auto'],
        p: 2
      }
    },
    progressContainer: {
      '& div': { height: '14px' },
      '& > div': {
        border: '1px solid #CCCCCC',
        borderRadius: '100px',
        overflow: 'hidden'
      }
    },
    footerDisclaimer: {
      height: '48px',
      position: 'relative',
      display: ['block', 'block', 'block', 'block', 'none', 'none'],
      '& > img': {
        position: 'absolute',
        mixBlendMode: 'difference',
        transform: 'rotate(90deg) translate(50%, -50%)',
        transformOrigin: 'top',
        top: '24px',
        left: '50%',
        height: '200px',
        opacity: '0.5'
      }
    },
    informationButtonsContainer: {
      justifyContent: 'center'
    },
    homeContainer: {
      boxShadow: '0px 1px 3px rgba(0, 0, 0, 0.15)',
      mb: ['0', '0', '0', '0', '112px', '112px']
    }
  },
  text: {}
})
