type generateOTPEmailCodeProps = {
  id: string
}

export const generateOTPEmailCode = async (id): generateOTPEmailCodeProps => {
  try {
    if (!id) {
      throw new Error('Patient ID can`t be null')
    }

    const body = { id }

    const res = await fetch(
      `${process.env.REACT_APP_BACKEND_URL}/generateCarePlanCode`,
      {
        method: 'POST',
        body: JSON.stringify(body),
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${process.env.REACT_APP_BACKEND_MAYARED_TOKEN}`
        }
      }
    )
    const bodyRes = await res.json()

    if (bodyRes.error) {
      throw new Error(bodyRes.error.response.error.message || res.statusText)
    }

    return bodyRes
  } catch (e) {
    throw e
  }
}
