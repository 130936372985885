import React, { useContext, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { stripSsml, UneeqContext, useUneeqState } from 'uneeq-react-core'
import { useSendSocketMessage } from '../../../app/hooks/useSendSocketMessage'
import { Button, Flex, Text } from 'rebass'
import styles from '../styles'
import useWidgetContext from '../../../app/hooks/useWidgetContext'
import { markdownQuestion } from '../Question'
import { fnOnEnter } from '../../../utils'
import { useShouldShowButtons } from '../../../app/hooks/useShouldShowButtons'

const SelectQuestion = () => {
  const { dispatch } = useContext(UneeqContext)
  const { t } = useTranslation()
  const sendMessage = useSendSocketMessage()
  const { mayaQuestion, hideQuestionTitle } = useUneeqState()
  const { widgetMode, fullscreen } = useWidgetContext()

  // For style only
  const [selectedAnswer, setSelectedAnswer] = useState(-1)

  useEffect(() => {
    // Backend tell us which option should be pre-selected
    if (mayaQuestion.value) {
      setSelectedAnswer(mayaQuestion.value)
    }
  }, [mayaQuestion])

  const questionText = useMemo(
    () => markdownQuestion(stripSsml(mayaQuestion.question)),
    [mayaQuestion]
  )
  const submitOption = (opt: any) => {
    setSelectedAnswer(opt.id)
    const info = {
      type: 'response',
      questionId: mayaQuestion.id,
      questionText: mayaQuestion.question,
      response: opt.id ? opt.id.toString() : '',
      label: opt.label ? opt.label : t('Transcript.skippedQuestion')
    }
    dispatch({ type: 'mayaMessage', payload: info })
    sendMessage(info)
  }

  const shouldShowButtons = useShouldShowButtons()

  const isMayaRed = true // TODO: make dynamic

  return (
    <Flex sx={styles.selectQuestionContainer}>
      {!isMayaRed && (
        <Flex sx={styles.topContainer}>
          {!hideQuestionTitle && (
            <Text sx={styles.question}>{questionText}</Text>
          )}
          <Text sx={styles.instructions}>{mayaQuestion.instruction}</Text>
        </Flex>
      )}
      {isMayaRed && !hideQuestionTitle && (
        <Flex>
          <Text sx={styles.question}>{questionText}</Text>
        </Flex>
      )}

      {shouldShowButtons && (
        <Flex
          sx={{
            ...(widgetMode && !fullscreen
              ? styles.widgetMultiSelectOptionsContainer
              : {})
          }}
        >
          <Flex variant="questionsList">
            {mayaQuestion.options.map((option: any) => (
              <Button
                variant={
                  selectedAnswer === option.id ? 'optionSelected' : 'option'
                }
                key={option.id}
                onMouseDown={() => submitOption(option)}
                onKeyPress={fnOnEnter(() => submitOption(option))}
              >
                {option.label}
              </Button>
            ))}
          </Flex>
        </Flex>
      )}

      {shouldShowButtons && mayaQuestion.optional && (
        <Button type="submit" onClick={() => submitOption('')}>
          {t('Question.skip')}
        </Button>
      )}
    </Flex>
  )
}

export default SelectQuestion
