import React, { useContext } from 'react'
// @ts-ignore
import { useTranslation } from 'react-i18next'
import { Box, Button, Flex, Heading, Text } from 'rebass'
import {
  trackHandler,
  UneeqContext,
  useDisableBodyOverflow,
  useUneeqState
} from 'uneeq-react-core'

import { MayaOverlay } from '../MayaOverlay'
import QuestionMark from '../MayaIcons/QuestionMark'
interface MayaConfirmLeaveProps {
  onConfirm: () => any
}

const MayaConfirmLeave = React.memo(({ onConfirm }: MayaConfirmLeaveProps) => {
  const { confirmLeaveOpen } = useUneeqState()
  const { dispatch } = useContext(UneeqContext)
  const { t } = useTranslation()

  useDisableBodyOverflow(confirmLeaveOpen)

  const closeConfirmLeave = () => dispatch({ type: 'mayaCloseConfirmLeave' })
  const handleConfirm = () => {
    onConfirm()
    closeConfirmLeave()
  }

  if (!confirmLeaveOpen) return null

  return (
    <MayaOverlay>
      <Flex variant="modal">
        <Box variant="modalIconWrapper">
          <QuestionMark />
        </Box>
        <Box>
          <Heading variant="modalHeader">{t('LeaveSession.title')}</Heading>
          <Text variant="modalText">{t('LeaveSession.subtitle')}</Text>
        </Box>

        <Flex variant="modalButtons">
          <Button
            autoFocus
            id="confirm"
            variant="secondary"
            onClick={trackHandler(handleConfirm, 'exit-session-btn')}
          >
            {t('LeaveSession.confirm')}
          </Button>
          <Button id="cancel" variant="primary" onClick={closeConfirmLeave}>
            {t('LeaveSession.dismiss')}
          </Button>
        </Flex>
      </Flex>
    </MayaOverlay>
  )
})

export default MayaConfirmLeave
