import React from 'react'
import { Box } from 'rebass'

const SymptomsList = ({ symptomsList }) => {
  // Theres more data there that looks like it should be looped through,
  // but this grabs just what's shown in the ticket and displays it...

  if (!symptomsList) return null

  return (
    <Box
      variant="contentList"
      dangerouslySetInnerHTML={{ __html: symptomsList.description }}
    />
  )
}

export default SymptomsList
