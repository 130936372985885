import React from 'react'
import { Flex } from 'rebass'

const Divider = ({ ...props }) => {
  return (
    <Flex
      sx={{
        borderTop: '1px solid rgba(17, 17, 17, 0.1)'
      }}
      {...props}
    />
  )
}

export default Divider
