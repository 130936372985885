import React from 'react'
import styles from './styles'
import { Box, BoxProps } from 'rebass'

type SpinnerProps = Omit<BoxProps, 'css' | 'sx'>

const LoadingSpinner = ({ ...props }: SpinnerProps) => {
  return (
    <Box sx={styles.lds} {...props}>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </Box>
  )
}

export default LoadingSpinner
