import React, { useContext, useEffect, useState, useMemo, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { stripSsml, UneeqContext, useUneeqState } from 'uneeq-react-core'
import { useSendSocketMessage } from '../../../app/hooks/useSendSocketMessage'

import { Button, Flex, Text, Box } from 'rebass'
import styles from '../styles'
import { ReactComponent as ChevronDown } from '../down-chevron.svg'
import { ReactComponent as Check } from '../check.svg'
import useWidgetContext from '../../../app/hooks/useWidgetContext'
import { markdownQuestion } from '../Question'
import { useShouldShowButtons } from '../../../app/hooks/useShouldShowButtons'

const MultiSelectQuestion = () => {
  const { dispatch } = useContext(UneeqContext)
  const { t } = useTranslation()
  const sendMessage = useSendSocketMessage()
  const { mayaQuestion, hideQuestionTitle } = useUneeqState()
  const { widgetMode, fullscreen } = useWidgetContext()
  const scrollableContainerRef = useRef<HTMLElement>(null)

  const [selected, setSelected] = useState<any>({})

  const shouldShowButtons = useShouldShowButtons()

  const questionText = useMemo(
    () => markdownQuestion(stripSsml(mayaQuestion.question)),
    [mayaQuestion]
  )
  const [showArrow, setShowArrow] = useState(false)

  useEffect(() => {
    if (scrollableContainerRef.current) {
      const { scrollHeight, clientHeight } = scrollableContainerRef.current

      if (scrollHeight > clientHeight) {
        scrollableContainerRef.current.scrollTop = 0
        setShowArrow(true)
      }
      const containerRef = scrollableContainerRef.current
      const hideArrowFn = () => {
        setShowArrow(false)
      }
      containerRef.addEventListener('scroll', hideArrowFn)

      return () => containerRef.removeEventListener('scroll', hideArrowFn)
    }
  }, [setShowArrow, mayaQuestion])

  const submitOption = (selectedAnswers: any) => {
    const label = mayaQuestion.options
      .filter((opt: any) => selectedAnswers[opt.id])
      .map((opt: any) => opt.label)
      .join(', ')
    const response = {
      type: 'response',
      questionId: mayaQuestion.id,
      questionText: mayaQuestion.question,
      responses: Object.entries(selectedAnswers)
        .filter(([key, value]: any) => value === true)
        .map(([key, value]: any) => key.toString()),
      label: label || t('Transcript.skippedQuestion')
    }
    dispatch({ type: 'mayaMessage', payload: response })
    sendMessage(response)
  }
  const toggleCheckbox = (response: any) => {
    if (mayaQuestion.options.some((resp: any) => resp.exclusive)) {
      if (response.exclusive) {
        // set all others to false, except the exclusive one we selected
        const selectedAnswers = {
          ...Object.fromEntries(Object.keys(selected).map(key => [key, false])),
          [response.id]: !selected[response.id]
        }
        setSelected(selectedAnswers)
        submitOption(selectedAnswers)
      } else {
        // toggle selected, and also unselect exclusive
        setSelected({
          ...Object.fromEntries(
            Object.keys(selected).map(key => {
              const isExclusive = mayaQuestion.options.find(
                (rsp: any) => rsp.id === key.toString()
              ).exclusive
              return [key, isExclusive ? false : selected[key]]
            })
          ),
          [response.id]: !selected[response.id]
        })
      }
    } else {
      setSelected({
        ...selected,
        [response.id]: !selected[response.id]
      })
    }
  }

  useEffect(() => {
    if (mayaQuestion.options) {
      const preselectedOptions = mayaQuestion.value || []
      const newSelected = mayaQuestion.options.reduce(
        (options: Record<string, boolean>, response: any) => {
          options[response.id] = preselectedOptions.includes(response.id)
          return options
        },
        {}
      )
      setSelected(newSelected)
    }
  }, [mayaQuestion])

  const noValueSelected = Object.values(selected).every(val => val === false)

  const submitButton = () => {
    if ((shouldShowButtons && !mayaQuestion.optional) || !noValueSelected) {
      return (
        <Button
          id="submit"
          type="submit"
          disabled={noValueSelected}
          onClick={() => submitOption(selected)}
          variant="multiSelectSubmitButton"
        >
          {t('Question.submit')}
        </Button>
      )
    }

    return (
      <Button
        type="submit"
        onClick={() => submitOption([])}
        variant="multiSelectSubmitButton"
      >
        {t('Question.skip')}
      </Button>
    )
  }

  return (
    <Flex variant="questionContainer">
      <Flex sx={styles.topContainer}>
        {!hideQuestionTitle && <Text sx={styles.question}>{questionText}</Text>}
        <Text sx={styles.instructions}>{mayaQuestion.instruction}</Text>
      </Flex>
      {shouldShowButtons && (
        <Box
          id="options-container"
          ref={scrollableContainerRef}
          variant="questionsNavWrapper"
          sx={{
            ...(widgetMode && !fullscreen
              ? styles.widgetMultiSelectOptionsContainer
              : {})
          }}
        >
          <Flex variant="questionsList">
            {mayaQuestion.options &&
              mayaQuestion.options.map((option: any) => {
                return (
                  <Button
                    variant={selected[option.id] ? 'optionSelected' : 'option'}
                    onClick={() => toggleCheckbox(option)}
                    key={option.id}
                  >
                    {selected[option.id] && <Check />}
                    {option.label}
                  </Button>
                )
              })}
          </Flex>
        </Box>
      )}

      {showArrow && (
        <Box sx={styles.arrowDownContainer}>
          <Text variant="unstyled" sx={styles.arrowDown}>
            <ChevronDown />
          </Text>
        </Box>
      )}
      {submitButton()}
    </Flex>
  )
}

export default MultiSelectQuestion
