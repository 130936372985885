import React, { useReducer } from 'react'
import { dischargeCarePlanType } from '../types/dischargeCarePlan'
import { StorageKeys } from '../types/StorageKeys'

type SessionContextState = {
  dischargeCarePlan: dischargeCarePlanType | null
}

type SetDischargeCarePlanAction = {
  type: 'SET_DISCHARGE_CARE_PLAN'
  payload: { dischargeCarePlan: dischargeCarePlanType }
}

export type SessionContextActions = SetDischargeCarePlanAction

export const SessionContext = React.createContext<{
  state: SessionContextState
  dispatch: React.Dispatch<SessionContextActions>
}>({ state: {}, dispatch: () => {} } as any)

const reducer = (
  state: SessionContextState,
  action: SessionContextActions
): SessionContextState => {
  switch (action.type) {
    case 'SET_DISCHARGE_CARE_PLAN': {
      return {
        ...state,
        dischargeCarePlan: action.payload.dischargeCarePlan
      }
    }
    default:
      return state
  }
}

export const SessionProvider: React.FC = ({ children }) => {
  const dischargeCarePlanFromLS = localStorage.getItem(
    StorageKeys.API_DISCHARGE_CARE_PLAN_DATA
  )
  const [state, dispatch] = useReducer(reducer, {
    dischargeCarePlan: dischargeCarePlanFromLS
      ? JSON.parse(dischargeCarePlanFromLS).value
      : null
  })

  return (
    <SessionContext.Provider value={{ state, dispatch }}>
      {children}
    </SessionContext.Provider>
  )
}
