import { Label } from '@rebass/forms'
import React, { useEffect, useState } from 'react'
import { useFieldArray, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import 'react-phone-number-input/style.css'
import { Box, Button, Flex, Text } from 'rebass'
import {
  SingleUserData,
  UserData,
  UserPhone
} from '../../hooks/useApplicationState'
import { addPatient, editPatient } from '../api/authentication'
import { ReactComponent as CheckSvg } from '../assets/icons/check.svg'
import { ReactComponent as ExclamationIcon } from '../assets/icons/exclamation.svg'
import { ReactComponent as PersonSvg } from '../assets/icons/person.svg'
import { ReactComponent as RoundedDotsSvg } from '../assets/icons/rounded-dots.svg'
import { ReactComponent as TrashSvg } from '../assets/icons/trash.svg'
import { registerUserDataSession } from '../Authentication/registerSession'
import { styles } from './accountSetupStyles'
import { FamilyMemberForm } from './FamilyMemberForm'
import spinner from './spinner.gif'

type AccountSetupProps = {
  onSuccess: () => void
  userData: UserData
  userPhone: UserPhone
  onBackButton?: () => void
  setUserData: (userData: UserData) => void
}

export const AccountSetup = ({
  onSuccess,
  userPhone,
  userData,
  onBackButton,
  setUserData
}: AccountSetupProps) => {
  console.log('ACCOUNTSETUP userData', userData)
  const { t } = useTranslation()

  const defaultData: SingleUserData = {
    _id: '',
    firstName: '',
    lastName: '',
    dateOfBirth: '',
    sex: 'female',
    email: ''
  }
  const {
    handleSubmit,
    control,
    register,
    trigger,
    setError,
    clearErrors,
    formState: { isValid, errors, isSubmitting }
  } = useForm({
    mode: 'onTouched',
    defaultValues: {
      family: userData
        ? userData
        : [
            {
              ...defaultData,
              phoneNumber: userPhone
            }
          ]
    }
  })

  const [initialUserData, setInitialUserData] = useState<UserData | null>(null)

  const { fields, append, update, remove } = useFieldArray({
    control,
    name: 'family'
  })

  const isEditingPatient = onBackButton

  useEffect(() => {
    if (isEditingPatient && initialUserData === null) {
      setInitialUserData(userData)
    }
  }, [initialUserData, isEditingPatient, userData])

  const submitFamily = async (data: any) => {
    // SUBMIT FAMILY DATA
    console.log('SUBMIT data', data)
    try {
      clearErrors()
      const currentPreAuthToken = userData && userData[0]?.preAuthToken
      const updatedUserData = isEditingPatient
        ? await editPatient(data.family, initialUserData)
        : await addPatient(data.family)

      // since we don't get the preAuthToken in the update API
      // response, we add it from the previously saved one
      if (currentPreAuthToken && updatedUserData) {
        updatedUserData[0] = {
          ...updatedUserData[0],
          preAuthToken: currentPreAuthToken
        }
      }
      registerUserDataSession(updatedUserData)
      setUserData(updatedUserData)
      setInitialUserData(userData)
      onSuccess()
    } catch (e) {
      console.log('ERROR ADDING PATIENT', e)
      if (e instanceof Error) {
        console.log('setting manual error. e:', e)
        setError('family', { type: 'custom', message: e.message })
      }
    }
  }

  console.log('ERRORS!', errors)

  return (
    <Box sx={styles.container}>
      <Text sx={styles.title}>{t('AccountSetup.title')}</Text>
      <Flex sx={styles.menu}>
        <Flex sx={styles.menuItem}>
          <PersonSvg />
          {t('AccountSetup.title')}
        </Flex>
        {fields.map((field, index) => (
          <Flex
            sx={{
              ...styles.menuItem,
              color: errors.family?.[index]
                ? 'error'
                : initialUserData?.some(
                    initUserData => initUserData._id === fields[index]._id
                  ) || fields.length - 1 > index
                ? 'success'
                : 'primary'
            }}
          >
            <Flex sx={styles.menuItemIcons}>
              <Flex sx={styles.divider} />
              {field.firstName ? (
                errors.family?.[index] ? (
                  <ExclamationIcon />
                ) : (
                  <CheckSvg />
                )
              ) : (
                <RoundedDotsSvg />
              )}
            </Flex>

            {field.firstName
              ? `${field.firstName} ${field.lastName}`
              : fields.length > 2
              ? t('AccountSetup.familyMember', { index })
              : t('AccountSetup.subtitle')}
          </Flex>
        ))}
      </Flex>
      <Flex sx={styles.account} as="form" onSubmit={handleSubmit(submitFamily)}>
        <Flex sx={styles.memberDataContainer}>
          <input type="hidden" name="customError" id="customError" />
          {fields.map((field, index) => (
            <Flex sx={styles.formContainer} fieldset key={field.id}>
              <Text sx={styles.formDescription}>
                {index > 0
                  ? t('AccountSetup.familyMember', { index })
                  : t('AccountSetup.subtitle')}
                {index > 0 && (
                  <Box
                    sx={styles.trashIcon}
                    onClick={() => {
                      // fields[index].id = undefined
                      remove(index)
                    }}
                  >
                    <TrashSvg />
                  </Box>
                )}
              </Text>

              <FamilyMemberForm
                update={update}
                index={index}
                value={field}
                register={register}
                control={control}
                errors={errors}
                isEditingPatient={isEditingPatient}
              />
            </Flex>
          ))}
          <Button
            sx={styles.addNewFamilyMemberBtn}
            variant="secondary"
            type="button"
            onClick={() => {
              if (isValid) {
                append({
                  ...defaultData,
                  phoneNumber: ''
                })
              } else {
                trigger()
              }
            }}
          >
            {t('AccountSetup.addNewFamilyMember')}
          </Button>
          {errors?.family?.type === 'custom' && (
            <Flex
              sx={{
                ...styles.errorsContainer,
                m: 4,
                mb: 10,
                border: 'error',
                borderRadius: '8px',
                width: '100%'
              }}
            >
              <Label sx={styles.errorLabel}>
                <ExclamationIcon />
                {t('AccountSetup.error', {
                  count: 1
                })}
              </Label>

              <Label sx={styles.error}>{errors.family?.message}</Label>
            </Flex>
          )}

          {isSubmitting && (
            <img
              src={spinner}
              style={{ marginBottom: '15px' }}
              alt="Loading..."
            />
          )}

          <Button
            sx={styles.confirmSetupBtn}
            disabled={!isValid || isSubmitting}
            variant="primary"
            type="submit"
          >
            {t('AccountSetup.confirmSetup')}
          </Button>
        </Flex>
        {onBackButton && (
          <Button onClick={onBackButton}>{t('BackButton.back')}</Button>
        )}
      </Flex>
    </Box>
  )
}
