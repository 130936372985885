import React from 'react'
import { Box, Flex, Heading, Text } from 'rebass'

import { useHighlightedSection } from '../../../app/hooks/useHighlightedSection'
import { AppointmentType } from '../../../app/types/dischargeCarePlan'
import { Divider } from '../RedUI'

interface UnscheduledAppointmentProps {
  appointment: AppointmentType
  index: number
}

export const UnscheduledAppointment = ({
  appointment,
  index
}: UnscheduledAppointmentProps) => {
  const { doctor, speciality } = appointment
  const { isHighlightedSection, cellToHighlight, row } = useHighlightedSection(
    index
  )

  // If the name of speciality is empty, we need to check over speciality_other
  // if it is there
  const appointmentReason = speciality
    ? speciality.name
    : appointment.speciality_other || null

  return (
    <Flex variant={!isHighlightedSection ? 'fadedCard' : 'card'}>
      {!!appointment.contact_number && (
        <>
          <Box>
            <Text variant="label">Call for an appointment to:</Text>
            <Heading>{appointment.contact_number}</Heading>
          </Box>
          <Divider />
        </>
      )}

      <Box variant="simpleGrid">
        <Box
          variant={
            cellToHighlight === `${row}_column_2` ? 'activeSpokenContent' : ''
          }
        >
          <Text variant="label">Doctor:</Text>
          <Text variant="content">
            {doctor.Fname} {doctor.Lname}
          </Text>
        </Box>
        <Box
          variant={
            cellToHighlight === `${row}_column_3` ? 'activeSpokenContent' : ''
          }
        >
          <Text variant="label">Where:</Text>
          <Text variant="content">{appointment.address}</Text>
        </Box>
        <Box
          variant={
            cellToHighlight === `${row}_column_1` ? 'activeSpokenContent' : ''
          }
        >
          <Text variant="label">Reason:</Text>
          <Text variant="content">{appointmentReason}</Text>
        </Box>
        <Box
          variant={
            cellToHighlight === `${row}_column_4` ||
            cellToHighlight === `${row}_column_5`
              ? 'activeSpokenContent'
              : ''
          }
        >
          {(!!appointment.email || !!appointment.contact_number) && (
            <Text variant="label">Contact the office:</Text>
          )}
          {!!appointment.email && (
            <Text variant="content">Email {appointment.email}</Text>
          )}
          {!!appointment.contact_number && (
            <Text variant="content">Call {appointment.contact_number}</Text>
          )}
        </Box>
      </Box>
    </Flex>
  )
}
