import React, { useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { Box, Button, Flex, Heading } from 'rebass'
import { ReactComponent as CloseIcon } from '../../app/assets/icons/close.svg'
import { MayaOverlay } from '../MayaOverlay'
import styles from './mayaPrivacyPolicyStyles'

interface MayaPrivacyPolicyProps {
  onClose: () => any
}

export const MayaPrivacyPolicy = ({ onClose }: MayaPrivacyPolicyProps) => {
  const { t } = useTranslation()

  const scrollableContentRef = useRef<HTMLDivElement>(null)

  const handleKeyDownButton = (e: any) => {
    if (e.key === 'ArrowDown') {
      e.stopPropagation()
      scrollableContentRef.current?.focus()
    }
  }

  const handleKeyDownScrollableContent = (e: any) => {
    if (e.key === 'Enter') {
      e.preventDefault()
      e.stopPropagation()
      onClose()
    }
  }

  return (
    <MayaOverlay>
      <Flex sx={styles.container}>
        <Flex sx={styles.header}>
          <Heading variant="title" sx={styles.heading}>
            {t('PrivacyPolicy.title')}
          </Heading>
          <Button
            autoFocus
            onClick={onClose}
            onKeyDown={handleKeyDownButton}
            variant="secondary"
            sx={styles.icon}
          >
            <CloseIcon />
          </Button>
        </Flex>
        <Box
          sx={styles.text}
          tabIndex={0}
          ref={scrollableContentRef}
          onKeyDown={handleKeyDownScrollableContent}
        >
          {/* TODO Missing styles - how to consume it from the back? */}
          <div>
            <h6>DATA COLLECTED ON THE BASIS OF CONSENT</h6>
            <p>
              Upon your request and expression of consent, we collect the
              following data for the purpose of providing services to you. Your
              data is not used for any other purposes other than set forth
              below. Your data is removed upon your withdrawal of consent or
              your request to terminate theses services.
            </p>
            <h6>INTRODUCTION</h6>
            <p>
              This Privacy Notice tells you how we protect and use information
              that we gather through MayaMD, Inc. Mobile Applications (the
              “Application” or “App”). This Privacy Notice is intended for a
              global audience.
            </p>
            <p>
              For users in Europe or other geographies, please refer to the
              privacy Notices specific to your geography that are referenced in
              your App.
            </p>
            <p>
              This Privacy Notice was last revised on January 21, 2020. We may
              change the Privacy Notice at any time and for any reason.
            </p>
            <p>
              Please read the End User Licensing Agreement (EULA) for your
              Application to understand the general rules about your use of the
              Application. Except as written in any other disclaimers, policies,
              terms of use, or other notices in an Application, this Privacy
              Notice and the EULA are the complete agreement between you and
              MayaMD with respect to your use of the Application. By using
              MayaMD Mobile Applications (the “Application” or “App”), you agree
              to the terms of the most recent version of this Privacy Notice.
              You may be subject to additional terms that may apply when you
              access particular services or materials on certain areas in this
              Application, or by following a link from this Application.
            </p>
            <p>
              The Applications are owned and operated by MayaMD. MayaMD is the
              name we use to refer to our whole business and any of the
              companies that it controls, such as its subsidiaries and
              affiliates. When we use the words “we” or “our,” we mean MayaMD.
              The information we receive, and how we use it, depends on what you
              do when using the Application.
            </p>
            <p>
              To the extent that we hold personal data relating to users located
              in the European Economic Area (“EEA”) and the United Kingdom
              (“UK”) we are bound by and must act in compliance with the General
              Data Protection Regulation (EU) 2016/679 (“GDPR”). This Privacy
              Notice sets out our practices and how we will process your
              personal data in accordance with the GDPR.
            </p>
            <p>
              This Privacy Notice does not cover personal data that has been
              made anonymous such that you can no longer be identified from it.
              We may use anonymous data for any purpose we choose and without
              further reference to you.
            </p>
            <p>
              We collect and use both personal information (information that is
              identifiable to you personally) and non personal information about
              you through the application. Please see below for a definition of
              personal and non-personal information, and how MayaMD may use
              them.
            </p>
            <h6>WHAT IS PERSONAL INFORMATION?</h6>
            <p>
              As referred to in this Privacy Notice, “personal information”
              means any information relating to an identified or identifiable
              natural person. An identifiable natural person is one who can be
              identified, directly or indirectly, in particular by reference to
              an identifier such as a name, an identification number, location
              data, an online identifier or to one or more factors specific to
              the physical, physiological, genetic, mental, economic, cultural
              or social identity of that natural person. This Privacy Notice
              also refers to “special categories of personal data”. This is
              personal data which is to be treated with particular sensitivity,
              and includes information revealing racial or ethnic origin,
              political opinions, religious or philosophical beliefs, or trade
              union membership, genetic data, biometric data, data concerning
              health or data concerning a natural person’s sex life or sexual
              orientation.
            </p>
            <h6>TYPES OF PERSONAL DATA WE PROCESS</h6>
            <p>
              We may collect and process: (a) Information you give to us. This
              is information you provide to us via the MayaMD App. Information
              we collect about you from third parties. We may collect
              information about you from third parties, for example from third
              parties whose details you have given us such as your physician in
              order that we can contact them.
            </p>
            <p>
              Personal information is information that we can use to
              specifically identify you, such as your:
            </p>
            <ul role="list">
              <li>Name</li>
              <li>Address</li>
              <li>Telephone number</li>
              <li>Email</li>
              <li>Date of birth</li>
              <li>Account name</li>
              <li>Gender</li>
              <li>Race or ethnicity</li>
              <li>
                For U.S. healthcare providers, a National Provider Identifier#
                (NPI)
              </li>
              <li>State license number</li>
              <li>Social security number or National ID Number</li>
              <li>
                Mobile device identifiers, such as model number, manufacturer,
                serial number, or IMEI/MEID
              </li>
              <li>Geolocation information</li>
              <li>Health condition</li>
              <li>
                Physical attributes (e.g., hair color, eye color, height and
                weight);
              </li>
              <li>Hospital/clinic name, address and phone number</li>
              <li>
                IP (Internet Protocol) address when such address is associated
                to a specific user,
              </li>
              <li>
                Information about how you use the App, such as links or
                functions you may access within the App
              </li>
            </ul>
            <p>
              In some places on the App you have the opportunity to send us
              personal information about yourself, to elect to receive
              particular information, or to participate in a program. For
              example, you may fill out a registration form, a survey, or a
              personal health record (PHR) and you may elect to receive
              educational material about products and therapies.
            </p>
            <h6>HOW DO WE KEEP AND USE PERSONAL INFORMATION?</h6>
            <p>
              We may collect and use personal information from you through this
              App to provide you with access to the App. You may choose not to
              provide us with this information, but then you may not be able to
              access and utilize the App. We will only use your personal data to
              the extent that the applicable law allows us to do so. To the
              extent that we hold personal data relating to your and located in
              the EEA and/or the UK, we rely on the following lawful bases for
              processing under the GDPR:
            </p>
            <ul role="list">
              <li>
                Where the processing is necessary for the purposes of our
                legitimate interests (or those of a third party) and your
                interests or fundamental rights and freedoms do not override
                those interests.
              </li>
              <li>
                Where you have given us your consent. Sometimes we may ask you
                for your consent in order to process your personal data. You may
                withdraw your consent at any time.
              </li>
              <li>
                Where the processing is necessary in order to comply with a
                legal obligation to which we are subject.
              </li>
            </ul>
            <p>We may keep and use your personal information:</p>
            <ul role="list">
              <li>
                If you are a Patient, to transmit data to your Health Care
                Provider, but only with your approval as you provide through the
                APP
              </li>
              <li>To provide services to you through the App</li>
              <li>To send you notifications</li>
              <li>
                To notify you of any changes to the terms on which we provide
                our services
              </li>
              <li>To respond to your requests</li>
              <li>
                To develop records, including records of your personal
                information
              </li>
              <li>
                To contact you with information that might be of interest to
                you, including information about products and services of ours
                and of others
              </li>
              <li>
                To analyze how people use our App and to research, develop, and
                improve programs, products, services, and content
              </li>
              <li>
                For U.S. healthcare providers, to link your name, National
                Provider Identifier (NPI), state license number, and/or your IP
                address to web pages you visit, for compliance, marketing, and
                sales activities
              </li>
              <li>
                To create a set of data that has only non-personal or
                de-identified information. In this case, we would remove your
                personal identifiers (such as your name, email address, etc.)
                and we may treat it like other non-personal or de-identified
                information.
              </li>
              <li>
                To enforce this Privacy Notice and other rules about your use of
                this App
              </li>
              <li>To protect our rights or property</li>
              <li>To protect someone’s health, safety or welfare</li>
              <li>
                To comply with a law or regulation, court order or other legal
                process
              </li>
            </ul>
            <p>
              Once we have received your information, we will use commercially
              reasonable technical and organizational measures intended to
              protect your personal data against unauthorized or unlawful
              processing and against accidental loss, destruction or damage.
            </p>
            <h6>DOES WE EVER SHARE PERSONAL INFORMATION WITH THIRD PARTIES?</h6>
            <p>
              MayaMD will not share your personal information collected from the
              App with an unrelated third-party without your permission, except
              as otherwise provided in this Privacy Notice. MayaMD may share
              personal information with any member of our corporate group,
              including parent companies, subsidiaries, and affiliates for
              purposes that are consistent with those identified in this Privacy
              Notice.
            </p>
            <p>
              In the ordinary course of business, we will share some personal
              information with companies that we hire to perform services or
              functions on our behalf. In all cases in which we share your
              personal information with a third-party, we will not authorize
              them to keep, disclose or use your information with others except
              for the purpose of providing the services we asked them to
              provide.
            </p>
            <p>
              We may be legally compelled to release your personal information
              in response to a court order, subpoena, search warrant, law or
              regulation. We may cooperate with law enforcement authorities in
              investigating and prosecuting Application users who violate our
              rules or engage in behavior which is harmful to other visitors (or
              illegal). In addition, we may keep, disclose, and use your
              personal information in order to comply with U.S. FDA and other
              governmental guidance, directions, regulations, and laws.
            </p>
            <p>
              MayaMD uses reasonable efforts to ensure that its third-party
              service providers are capable of (1) protecting the privacy of
              your personal data consistent with this Privacy Notice, and (2)
              not using or disclosing your personal data for any purpose other
              than providing us with the products or services for which we
              contracted or as required by law.
            </p>
            <p>We may also disclose your personal data to a third party:</p>
            <ul role="list">
              <li>
                In the event of a sale, merger or other transfer of all or
                substantially all of the assets of MayaMD or that portion of
                MayaMD to which our services relate, or in the event that we
                discontinue our business or file a petition or have filed
                against us a petition in bankruptcy, reorganization or similar
                proceeding, provided that the third party agrees to adhere to
                the terms of this Privacy Notice;
              </li>
              <li>
                That is one of our Affiliates. For purposes of this Privacy
                Notice: “Affiliate” means any person or entity which directly or
                indirectly controls, is controlled by or is under common control
                with MayaMD, whether by ownership or otherwise; and “control”
                means possessing, directly or indirectly, the power to direct or
                cause the direction of the management, policies or operations of
                an entity, whether through ownership of fifty percent (50%) or
                more of the voting securities, by contract or otherwise. Any
                information relating to you that we provide to our Affiliates
                will be treated by those Affiliates in accordance with the terms
                of this Privacy Notice;
              </li>
              <li>
                If we believe in good faith that we are under a duty to disclose
                or share your personal data in order to comply with any legal
                obligation, including an applicable statute, regulation, rule or
                law, a subpoena, a search warrant, a court or regulatory order,
                lawful requests by public authorities, including to meet
                national security or law enforcement requirements, or other
                valid legal process, or in order to enforce or apply our terms
                of business and other agreements, or to protect the rights,
                property or safety of MayaMD, our customers, or others. This
                includes exchanging information with other companies and
                organizations for the purposes of fraud protection and credit
                risk reduction or assistance with a delinquent account.
              </li>
            </ul>
            <h6>TRANSFERS BETWEEN COUNTRIES</h6>
            <p>
              <strong>For EEA or UK users:</strong> To the extent that we
              process personal data if you are located in the EEA or the UK: (a)
              Where the transfer of your personal data to the United States is
              necessary for the performance of our service, you, without
              limitation of the foregoing, hereby expressly grant consent MayaMD
              to: (a) process and disclose such information (including special
              categories of personal data) in accordance with this Privacy
              Notice; (b) transfer such information (including special
              categories of personal data) throughout the world, including to
              the United States of America or other countries that do not ensure
              adequate protection for personally identifiable information (as
              determined by the European Commission); and (c) disclose such
              information (including special categories of personal data) to
              comply with lawful requests by public authorities, including to
              meet national security or law enforcement requirements.
            </p>
            <p>
              <strong>For China users:</strong> As you use the Internet when
              accessing the App, please be aware that your personal information
              may be transferred to, stored, and processed in China or any other
              country in which MayaMD (including its Affiliated Companies),
              contractors or agents maintain facilities offshore. The countries
              to which we are likely to transfer personal information may
              include the United States, Singapore, Switzerland, the
              Philippines, and the Netherlands. By using the App, you consent to
              any transfer, processing, or storing of information outside China.
              MayaMD ensures that the use and disclosure of the personal
              information transferred is dealt with in accordance with this
              policy and the safeguards under China’s privacy laws.
            </p>
            <p>
              <strong>For Japan Users:</strong> MayaMD may engage and delegate
              all or part of business operations including handling personal
              information to other MayaMD parties located outside of Japan to
              the extent it is necessary for achieving purposes of use described
              at Japan Privacy Policy. MayaMD may also share personal
              information described at Japan Privacy Policy among other MayaMD
              parties located outside of Japan for purposes of use described at
              Japan Privacy Policy. In that case, MayaMD ensures that the use
              and disclosure of the personal information transferred or shared
              is dealt with in accordance with this policy and the safeguards
              under Japan Personal Information Protection law.
            </p>
            <p>
              <strong>For Australian and New Zealand users:</strong> As part of
              a global company, please be aware that your personal information
              may be transferred offshore. The countries to which we are likely
              to disclose personal information may include the United States,
              Singapore, Switzerland, the Philippines, and the Netherlands.
              MayaMD ensures that the use and disclosure of the personal
              information transferred is dealt with in accordance with this
              policy and the safeguards under ANZ privacy laws.
            </p>
            <p>
              <strong>For Canada Users:</strong> MayaMD is located outside of
              Canada and may also use third-party service providers that are
              located outside of Canada. Accordingly, your personal information
              may be transmitted to, processed in, and stored within the United
              States and other foreign jurisdictions, and may be accessible to
              law enforcement or other authorities within those jurisdictions.
            </p>
            <p>
              <strong>
                For Latin America, Russia, Central Asian Users, and Middle East
                and North Africa Users:
              </strong>{' '}
              By accepting this, you are giving us authorization to receive,
              process, and use your personal information, including sensitive
              data, and also transfer the data out of your country to our
              affiliates and subsidiaries internationally, or third parties
              hired by MayaMD to manage the App.
            </p>
            <h6>YOUR RIGHTS</h6>
            <p>
              In certain circumstances, in accordance with the GDPR you have the
              right to:
            </p>
            <ul role="list">
              <li>
                <strong>Request access to your personal data.</strong> This
                enables you to receive a copy of the personal data we hold about
                you.
              </li>
              <li>
                <strong>Request correction of the personal data</strong> that we
                hold about you. This enables you to have any incomplete or
                inaccurate information we hold about you corrected.
              </li>
              <li>
                <strong>Request erasure of your personal data.</strong> This
                enables you to ask us to delete or remove personal data where
                there is no good reason for us continuing to process it. You
                also have the right to ask us to delete or remove your personal
                data in certain circumstances.
              </li>
              <li>
                <strong>Object to processing of your personal data</strong>{' '}
                where we are relying on a legitimate interest (or those of a
                third party) and there is something about your particular
                situation which makes you want to object to processing on this
                ground.
              </li>
              <li>
                <strong>
                  Request the restriction of processing of your personal data.
                </strong>{' '}
                This enables you to ask us to suspend the processing of personal
                data about you, for example, if you want us to establish its
                accuracy or the reason for processing it.
              </li>
              <li>
                <strong>Request the transfer</strong> of your personal data to
                another party.
              </li>
              <li>
                <strong>Lodge a complaint</strong> with the relevant supervisory
                authority (as defined in the GDPR). If you have any complaints
                about the way we process your personal data, please do contact
                us. Alternatively, you may lodge a complaint with the
                supervisory authority which is established in your country.
              </li>
              <li>
                <strong>Withdraw consent.</strong> If we are relying on your
                consent to process your personal data, you have the right to
                withdraw your consent at any time. Please note that, if you do
                withdraw your consent, you may not be able to benefit from our
                services.
              </li>
            </ul>
            <p>
              If you wish to exercise any of these rights, please contact us
              using the contact details below.
            </p>
            <h6>WHAT DOES MAYAMD DO WITH NON-PERSONAL INFORMATION?</h6>
            <p>
              Non-personal information is information that cannot identify you.
              We are always looking for ways to better serve you and improve
              this App. We will use non-personal information from you to help us
              make this App more useful to visitors. We also will use
              non-personal information for other business purposes. For example,
              we may use non-personal information or aggregate non-personal
              information to: a) create reports for internal use to develop
              programs, products, services or content, share it with or sell it
              to third parties, or provide aggregated information on how
              visitors use our site, such as ‘traffic statistics’ and ‘response
              rates,’ to third parties.
            </p>
            <h6>RETENTION OF DATA</h6>
            <p>
              MayaMD will retain personal data only for so long as is necessary
              to fulfill the purposes for which we collected it. To determine
              the appropriate retention period, we consider the amount, nature
              and sensitivity of that personal data, the purposes for which we
              process it and whether we can achieve those purposes through other
              means, and any applicable legal requirements. Notwithstanding the
              foregoing, and for the avoidance of doubt, we reserve the right to
              destroy any personal data within a reasonable period after the
              last action contemplated to be taken in connection with the
              purposes for which the personal data was provided.
            </p>
            <h6>WHAT ABOUT PRIVACY ON OTHER APPLICATIONS?</h6>
            <p>
              This web site may contain links to other apps or websites. Some of
              those web sites or apps may be operated by MayaMD, and some may be
              operated by third parties. We provide the links for your
              convenience, but we do not review, control, or monitor the privacy
              practices of web sites operated by others. This Privacy Policy
              does not apply to any other web site or app, even if operated by
              MayaMD. We are not responsible for the performance of applications
              or web sites operated by third parties or for your business
              dealings with them. Therefore, whenever you leave this
              Application, we recommend that you review each application or web
              site’s privacy practices and make your own conclusions regarding
              the adequacy of these practices.
            </p>
            <h6>
              DOES MAYAMD EVER COMMUNICATE DIRECTLY WITH VISITORS TO THIS
              APPLICATION?
            </h6>
            <p>
              We may contact you periodically by e-mail, mail or telephone to
              provide information regarding programs, products, services and
              content that may be of interest to you. In addition, some of the
              features on this App allow you to communicate with us using an
              online form. If your communication requests a response from us, we
              will send you a response via e-mail. The e-mail response or
              confirmation may include your personal information, including
              personal information about your health, your name, address, etc.
              We cannot guarantee that our e-mails to you will be secure from
              unauthorized interception.
            </p>
            <h6>COOKIES</h6>
            <p>
              Apps that link to, or connect with, websites may include cookies,
              pixel tags/web beacons, and similar technologies. This helps us to
              provide you with a good experience when you use the App or browse
              the websites, and also helps us to improve the App and our
              website. For further detailed information about cookies and the
              purposes for which we use them, please see the section of our
              website privacy Notice that discusses cookies.
            </p>
            <h6>ARE THERE SPECIAL RULES ABOUT CHILDREN’S PRIVACY?</h6>
            <p>
              We care about protecting the online privacy of children. We will
              not intentionally collect any personal information (such as a
              child’s name or e-mail address) from children under the age of 18.
              If you think that we have collected personal information from a
              child under the age of 18, please contact us. For Middle East and
              North Africa users, please notify us regarding children under the
              age of 18.
            </p>
            <h6>WHAT ABOUT APPLICATION SECURITY?</h6>
            <p>
              Security is very important to us. We also understand that security
              is important to you. We take reasonable steps to protect your
              personal information from loss, misuse, and unauthorized access,
              disclosure, alteration, or destruction. You should keep in mind
              that no Internet transmission is ever 100% secure or error-free.
              You acknowledge that the App may not be password protected, and
              that you are responsible for maintaining the confidentiality and
              security of any of your data available via the App on your mobile
              device, by using an ID and password credentials for your mobile
              device.
            </p>
            <h6>HOW TO CONTACT MAYAMD</h6>
            <p>
              If you have questions or comments about this Privacy Notice,
              please contact us at:{' '}
              <a href="mailto:dataprivacy@mayamd.ai">dataprivacy@mayamd.ai.</a>
            </p>
            <p>
              You may use the Contact Us form on our Application to exercise
              your rights to access, rectify, update, and/or eliminate your
              personal information or ask for non-disclosure. Elimination may
              not be possible if it can cause damage to third parties or if
              preserving your personal information is required by any law or
              regulation.
            </p>
            <p>
              <strong>Disclaimer:</strong> This page may include information
              about products that may not be available in your region or
              country. Please consult the approved indications for use. Content
              on specific MayaMD products is not intended for users in markets
              that do not have authorization for use.
            </p>
            <h6>STATE-SPECIFIC PRIVACY NOTICES</h6>
            <h6>CALIFORNIA</h6>
            <h6>SUPPLEMENTAL PRIVACY NOTICE FOR CALIFORNIA RESIDENTS</h6>
            <p>
              This notice for California residents supplements the information
              contained in our Privacy Notice and applies solely to residents of
              the State of California. We adopt this Supplemental Notice to
              comply with the California Consumer Privacy Act of 2018 (CCPA) and
              any terms defined in the CCPA have the same meaning when used in
              this Supplemental Notice. Under the California Consumer Privacy
              Act (“CCPA”), you have certain rights in relation to some of your
              personal data, including the right to certain disclosures and
              explanations of rights. This section explains your rights under
              California law.
            </p>
            <h6>CALIFORNIA ‘DO NOT TRACK’ DISCLOSURES</h6>
            <p>
              California law (CalOPPA) requires MayaMD to let you know how we
              respond to web browser “Do Not Track (DNT) signals.” Because there
              currently is not an industry or legal standard for recognizing or
              honoring DNT signals, we do not honor Do Not Track requests at
              this time.
            </p>
            <h6>CALIFORNIA CONSUMER PRIVACY ACT OF 2018</h6>
            <p>Categories of Personal Information We Collect</p>
            <p>
              MayaMD collects information that identifies, relates to,
              describes, references, is reasonably capable of being associated
              with, or could reasonably be linked, directly or indirectly, with
              a particular California consumer or household (“personal
              information”). We may have collected the following categories of
              personal information from consumers through our websites, apps,
              services, devices, or other services within the twelve (12) months
              preceding the effective date of this Privacy Notice:
            </p>
            <p>Personal Information Category</p>
            <ul role="list">
              <li>Identifiers such as</li>
              <li>Name</li>
              <li>Address</li>
              <li>
                Unique personal identifier (e.g., device ID, online identifier)
              </li>
              <li>Internet Protocol address</li>
              <li>Email address</li>
              <li>Account name</li>
              <li>Social security number</li>
              <li>Driver’s license number, or</li>
              <li>Other similar identifiers</li>
              <li>
                Characteristics of protected classifications under
                California/federal law (age, race, sex, medical condition, etc.)
              </li>
              <li>Medical information</li>
              <li>Health insurance information</li>
              <li>Financial information, including credit card numbers</li>
              <li>
                Biometric information (e.g., imagery of the iris, retina,
                fingerprint, face, or other data that contain identifying
                information)
              </li>
              <li>Commercial information (e.g., purchase history)</li>
              <li>
                Internet or other electronic network activity information (e.g.,
                browsing history)
              </li>
              <li>Geolocation data</li>
              <li>
                Audio, electronic, visual, thermal, olfactory, or similar
                information (e.g., call recordings)
              </li>
              <li>
                Professional, employment-related, or other similar information
              </li>
            </ul>
            <p>
              “Personal information” under the California Consumer Privacy Act
              does not include information that is:
            </p>
            <ul role="list">
              <li>Publicly available from government records,</li>
              <li>De-identified or aggregated consumer information,</li>
              <li>
                Health or medical information covered by the Health Insurance
                Portability and Accountability Act of 1996 (HIPAA) and the
                California Confidentiality of Medical Information Act (CMIA) or
                clinical trial data, or
              </li>
              <li>
                Certain personal or financial information covered under certain
                sector-specific privacy laws.
              </li>
            </ul>
            <p>
              For additional information, please see How Does MayaMD Keep and
              Use Personal Information.
            </p>
            <p>
              <strong>Categories of Sources of Personal Information</strong>
            </p>
            <p>
              We obtain the categories of personal information listed above from
              the following categories of sources:
            </p>
            <ul role="list">
              <li>
                Directly from consumers’ interactions with us, including with
                our devices, applications, websites, services, and
                representatives
              </li>
              <li>Publicly available sources</li>
              <li>
                Customers, including health care providers and insurance
                providers
              </li>
              <li>
                Organizations with whom we partner to provide services to
                consumers
              </li>
            </ul>
            <p>
              <strong>Use or Disclosure of Personal Information</strong>
            </p>
            <p>
              In the past 12 months, we have used or disclosed the personal
              information we collect for our operational purposes and for one or
              more of the following business purposes:
            </p>
            <p>To provide products and services to consumers</p>
            <ul role="list">
              <li>To respond to consumer requests</li>
              <li>
                To improve and personalize consumer access to and experience on
                our website, for example, by telling consumers about new
                features, products, or services that may be of interest to them
              </li>
              <li>
                To develop records, including records of consumers’ personal
                information
              </li>
              <li>
                To contact consumers with information that might be of interest
                to them, including information about clinical trials and about
                products and services of ours and of others
              </li>
              <li>
                For analytical purposes and to research, develop, and improve
                programs, products, services and content
              </li>
              <li>
                For activities to monitor and maintain the quality or safety of
                our products and services
              </li>
              <li>
                For activities described to consumers when collecting their
                personal information or as otherwise permitted under CCPA
              </li>
              <li>
                For U.S. healthcare providers, to link name, National Provider
                Identifier (NPI), state license number, and/or IP address to web
                pages they visit, for compliance, marketing, and sales
                activities
              </li>
              <li>
                To conduct audits and perform troubleshooting activities of our
                websites, products, and services
              </li>
              <li>
                To detect and protect against security incidents and deceptive,
                malicious, or fraudulent activity
              </li>
              <li>
                To ensure our website, products, apps, and services function as
                intended, including debugging and repairing
              </li>
              <li>
                To comply with a law or regulation, court order or other legal
                process
              </li>
            </ul>
            <p>
              Please see How Does MayaMD Keep and Use Personal Information in
              our Privacy Notice for more information.
            </p>
            <h6>HOW WE SHARE PERSONAL INFORMATION</h6>
            <p>
              MayaMD will not share consumers’ personal information with an
              unrelated third party without permission, except as described
              below. MayaMD may share personal information with any member of
              our corporate group, including parent companies, subsidiaries, and
              affiliates, and other companies in which we have an ownership or
              economic interest for purposes that are consistent with those
              identified in our Privacy Notice and this Notice.
            </p>
            <p>
              In the ordinary course of business, we will share some personal
              information with companies that we hire to perform services or
              functions on our behalf. In all cases in which we share consumers’
              personal information with a third party, we will only allow them
              to keep, disclose, or use consumers’ information to provide the
              services we asked them to provide.
            </p>
            <p>
              We may be required to release consumers’ personal information in
              response to a court order, subpoena, search warrant, law, or
              regulation. We may cooperate with law enforcement authorities in
              investigating and prosecuting users who violate our rules or
              engage in behavior which is harmful to other users or illegal. In
              addition, we may keep, disclose, and use consumers’ personal
              information in order to comply with U.S. FDA and other
              governmental guidance, directions, regulations, and laws.
            </p>
            <h6>CATEGORIES OF PERSONAL INFORMATION WE SELL</h6>
            <p>We do not sell personal information of California consumers.</p>
            <p>
              This does not include disclosures that are not a “sale” under the
              CCPA, including when:
            </p>
            <ul role="list">
              <li>
                Consumers instruct us to disclose their personal information,
              </li>
              <li>
                A consumer instructs us to interact with a third party that does
                not sell that information,
              </li>
              <li>
                We use or share consumer’s personal information pursuant to a
                written contract with a service provider that is necessary to
                perform a business purpose, where our contract prevents the
                provider from using, keeping, or disclosing consumers’ personal
                information for any purpose other than the reason supplied in
                the contract, or
              </li>
              <li>
                Consumer’s personal information is transferred as part of a
                transaction in which the third party assumes control of all or
                part of our business.
              </li>
            </ul>
            <p>Personal Information on Minors</p>
            <ul role="list">
              <li>
                We do not sell personal information of California consumers,
                including minors.
              </li>
            </ul>
            <h6>RIGHTS UNDER CALIFORNIA LAW</h6>
            <p>
              Right to Access. If you are a California consumer, you have the
              right to ask us to send you the following information up to two
              times in a twelve-month period:
            </p>
            <ul role="list">
              <li>
                The categories of personal data we have collected about you.
              </li>
              <li>
                The categories of sources from which we collected the personal
                data.
              </li>
              <li>
                Our business or commercial purpose for collecting personal data.
              </li>
              <li>
                The categories of third parties with whom we share personal
                data.
              </li>
              <li>
                What categories of personal data we disclose about you for
                business purposes.
              </li>
              <li>
                What categories of personal data we sell or exchange for
                consideration about you.
              </li>
              <li>
                The specific pieces of personal information we have collected
                about you.
              </li>
            </ul>
            <p>
              <strong>Right to Delete:</strong>If you are a California consumer,
              you have the right to ask us to delete the personal data about you
              we have collected. We may deny the request if the information is
              necessary to:
            </p>
            <ul role="list">
              <li>
                Complete a transaction, including providing a requested or
                reasonably anticipated good or service, or fulfill a contract
                between the consumer and MayaMD;
              </li>
              <li>
                Detect and protect against security incidents, malicious,
                deceptive, fraudulent, or illegal activity, or take against
                those responsible for such activity;
              </li>
              <li>
                Debug to identify and repair errors impairing intended
                functionality;
              </li>
              <li>
                Exercise free speech or another right provided for by law;
              </li>
              <li>
                Comply with the California Electronic Communications Privacy
                Act;
              </li>
              <li>
                Engage in research in the public interest adhering to applicable
                ethics and privacy laws where the consumer has provided informed
                consent;
              </li>
              <li>
                Enable solely internal uses reasonably aligned with the
                consumer’s expectations based on the consumer’s relationship
                with MayaMD;
              </li>
              <li>Comply with a legal obligation; or</li>
              <li>
                Otherwise use the information internally in a lawful manner
                compatible with the context in which the consumer provided the
                information.
              </li>
            </ul>
            <p>
              <strong>Right to Opt-out.</strong> If a business sells personal
              information to third parties, California consumers have the right,
              at any time, to opt out of the sale or disclosure of their
              personal information to third parties. MayaMD does not sell
              personal information to third parties.
            </p>
            <p>
              <strong>Right to non-discrimination.</strong> The CCPA grants
              California consumers the right not to be discriminated against for
              exercising your privacy rights. If you exercise your privacy
              rights, we will not discriminate against you, for example, by
              denying you access to our online services or charging you
              different rates or prices for the same online services, unless
              that difference is reasonably related to the value provided by
              your data.
            </p>
            <p>
              <strong>Submitting a Request or Inquiry</strong> If you are a
              California resident and you want to submit a request or inquiry to
              us regarding your California rights, you or your authorized agent
              can contact us at{' '}
              <a href="mailto:dataprivacy@mayamd.ai">dataprivacy@mayamd.ai</a>{' '}
              starting January 1, 2020. You do not have to create an account
              with us to submit a request.
            </p>
            <p>
              Your request will be confirmed within ten days of receipt and we
              will respond within 45 days. If we need more than 45 days, we will
              notify you that your request is being delayed.
            </p>
            <p>
              We can only respond to your request if it is verifiable. This
              means we are obligated to take reasonable steps to verify your
              identity or your authorized agent’s authority and your right to
              access the information you request. We may ask for additional
              information that will help us do so. We will only use that
              additional information in the verification process, and not for
              any other purpose.
            </p>
            <p>
              We do not charge a fee to process or respond to your verifiable
              consumer request unless it is excessive, repetitive, or manifestly
              unfounded. If we determine that the request warrants a fee, we
              will tell you why we made that decision and provide you with a
              cost estimate before completing your request.
            </p>
            <h1>&zwj;</h1>
          </div>
        </Box>
      </Flex>
    </MayaOverlay>
  )
}
